import {
  ContentLayout,
  Header,
  SideNavigationProps,
} from "@cloudscape-design/components";
import React from "react";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import JWStreamingChartsPage from "./jw-streaming-charts";

function JWStreamingChartsIndexPage() {
  const title = "JustWatch Charts";
  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Viewer's Pulse", href: "/" },
    { text: title },
  ];

  return (
    <Layout
      title={title}
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      content={
        <ContentLayout
          header={
            <>
              <Header
                description="The streaming charts are calculated by user activity on JustWatch from the last 7 days. This includes clicking on a streaming offer, adding a title to a watchlist, and marking a title as ‘seen’ in JustWatch."
                variant="h2"
              >
                JustWatch Charts
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <JWStreamingChartsPage />
        </ContentLayout>
      }
    />
  );
}

export default JWStreamingChartsIndexPage;
