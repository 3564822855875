import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import { Autosuggest } from "@cloudscape-design/components";
import { useGetTitleSearchData } from "./api/hooks/useGetTitleSearchData";
import { useGetTitleSearchByIdData } from "./api/hooks/useGetTitleSearchByIdData";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const verticalTypeLabels = {
  "Movies": "Movie",
  "Television": "Series",
  "Gaming": "Game",
  "Franchise": "Franchise",
};

const convertData = (data) => {
  const convertedData = data.map(item => {
    const imageUrl = getImageUrl(item.vertical, item.ip_id, item.image_url);
    const labelTags = [verticalTypeLabels[item.vertical]];
    if (item.release_date) {
      labelTags.push(new Date(item.release_date).getFullYear().toString());
    }
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: imageUrl,
      iconAlt: `${item.ip} cover`,
      tags: labelTags,
    };
  });

  return convertedData;
};

const TitleSelector = ({ onSelectedItemChanged, defaultSelectedTitleId=null, searchQuery, setSearchQuery }) => {

  const [ searchResults, setSearchResults ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ queryString, setQueryString ] = useState("");

  const {
    data: titleSearchData,
    isLoading,
  } = useGetTitleSearchData({ queryString: queryString });

  const {
    data: titleSearchByIdData,
    searchByIdIsLoading,
  } = useGetTitleSearchByIdData({ ipIds: defaultSelectedTitleId });

  useEffect(() => {
    if (titleSearchByIdData) {
      const convertedItems = convertData(titleSearchByIdData);
      setSelectedItem(convertedItems[0]);
      onSelectedItemChanged(convertedItems[0]);
    }
  }, [titleSearchByIdData]);

  useEffect(() => {
    if (titleSearchData) {
      setSearchResults(titleSearchData);
    }
  }, [titleSearchData]);

  return (
    <Autosuggest
      options={(searchResults && searchResults?.length > 0) ? convertData(searchResults) : []}
      value={searchQuery}
      onChange={({ detail }) => {
        setSearchQuery(detail.value);
      }}
      onLoadItems={({ detail }) => setQueryString(detail.filteringText)}
      onBlur={() => setSearchResults([])}
      onSelect={({ detail }) => {
        setSelectedItem(detail.selectedOption);
        onSelectedItemChanged(detail.selectedOption);
        setSearchQuery(detail.selectedOption.label);
      }}
      statusType={isLoading ? "loading" : "finished"}
      loadingText="Loading"
      placeholder="Search for a title"
      filteringType="manual"
    />
  );
};

export default TitleSelector;