import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Button, ButtonDropdown, Container, ContentLayout, ExpandableSection, Header, Icon, Multiselect, Select, SpaceBetween, Spinner, Tabs } from "@cloudscape-design/components";
import { SERVICES, TYPES, VERTICALS } from "../../../../config-global";
import { CHART_LABELS } from "../../../platforms/hooks/useGridConfig";
import { CATEGORIES, getCategories } from "../../../platforms/utils/categoryUtils";
import { useSearchParams } from "react-router-dom";
import { TabActions } from "../../../../components/TabActions";
import { Layout } from "../../Layout";
import { DataTable } from "../../../ranking/global-ranking/pantheon-ranking/components/DataTable/DataTable";
import { GRPantheonRanking } from "../../../ranking/global-ranking/pantheon-ranking/components/GRPantheonRanking";
import { BasicParameters } from "../../../ranking/global-ranking/pantheon-ranking/components/BasicParameters";
import { CompareContainer } from "../../components/CompareContainer";
import { DEFAULT_FILTER_OBJECTS, DEFAULT_WEIGHTS_OBJECTS } from "../../../ranking/constants";
import { weightsObjectToWeightageValues } from "../../../ranking/global-ranking/pantheon-ranking/utils";
import { getFilterConditionsFromFilters } from "../../../../utils/filters/filterUtils";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import { FiltersDrawer } from "../../../ranking/components/FiltersDrawer";
import { VerticalIcon } from "../../../../components/VerticalIcon";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { MessageBox } from "../../../../components/MessageBox";
import { useAuthContext } from "../../../../auth/useAuthContext";
import AttributesEditModalV2 from "../../modals/AttributesEditModalV2";
import { AddTitlesToFranchiseModal } from "../../modals/AddTitlesToFranchiseModal";
import { FranchiseEditModal } from "../../modals/FranchiseEditModal";
import { MergeFranchisesModal } from "../../modals/MergeFranchisesModal";
import { RemoveTitlesFromFranchiseModal } from "../../modals/RemoveTitlesFromFranchiseModal";
import TagsAndIDsComponent from "../../components/TagsAndIDsComponent";
import ReadMore from "../../../../components/ReadMore";

const additionalColumns = {
  [VERTICALS.MOVIES]: Object.values(CHART_LABELS).filter(label => !label.toLowerCase().includes("trend") && getCategories(label)?.includes(CATEGORIES.MOVIES)),
  [VERTICALS.SERIES]: Object.values(CHART_LABELS).filter(label => !label.toLowerCase().includes("trend") && getCategories(label)?.includes(CATEGORIES.SERIES)),
  [VERTICALS.GAMES]: Object.values(CHART_LABELS).filter(label => !label.toLowerCase().includes("trend") && getCategories(label)?.includes(CATEGORIES.GAMING)),
};

const verticalOptions = [
  { label: "Movies", value: VERTICALS.MOVIES },
  { label: "Series", value: VERTICALS.SERIES },
  { label: "Games", value: VERTICALS.GAMES },
];

const sortOptions = [
  { label: "Top", value: "score" },
  { label: "Rising", value: "score_change" },
];

const ItemFranchise = ({
  itemData,
  attributeProcessing,
  onTagUpdateSubmit,
  onDelete,
  onAddItems,
  onMergeItems,
  onFranchiseEditSubmit,
  onFranchiseDeleteSubmit,
  onFranchiseUpdateCoverImage,
}) => {
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.EXPLORE, module: "franchise" };

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams });
  const { user } = useAuthContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const filterDrawerRef = useRef(null);
  const messageBoxRef = useRef(null);

  const titleCounts = useMemo(() => (
    {
      [VERTICALS.MOVIES]: itemData.data.items.filter(item => item.vertical === VERTICALS.MOVIES).length,
      [VERTICALS.SERIES]: itemData.data.items.filter(item => item.vertical === VERTICALS.SERIES).length,
      [VERTICALS.GAMES]: itemData.data.items.filter(item => item.vertical === VERTICALS.GAMES).length,
    }
  ), [itemData]);

  const [selectedVertical, setSelectedVertical] = useState(verticalOptions.find(option => option.value === Object.keys(titleCounts).reduce((a, b) => titleCounts[a] > titleCounts[b] ? a : b)));
  const [activeTabId, setActiveTabId] = useState(searchParams.get("tab") ?? "tile");
  const [verticalSelectedIpIds, setVerticalSelectedIpIds] = useState({});
  const [initialVerticalSelectedIpIds, setInitialVerticalSelectedIpIds] = useState({});
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [dateRange, setDateRange] = useState({
    type: "absolute",
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [filterObject, setFilterObject] = useState(DEFAULT_FILTER_OBJECTS[selectedVertical.value]);
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState([]);
  const [overrideTitleCounts, setOverrideTitleCounts] = useState({});
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  const [attrModalVisible, setAttrModalVisible] = useState(false);
  const [addTitlesModalVisible, setAddTitlesModalVisible] = useState(false);
  const [removeTitlesModalVisible, setRemoveTitlesModalVisible] = useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);


  const weightsObject = useMemo(() => DEFAULT_WEIGHTS_OBJECTS[selectedVertical.value], [selectedVertical]);
  const weightageValues = useMemo(() => weightsObject ? weightsObjectToWeightageValues(weightsObject) : {}, [weightsObject]);
  const title = useMemo(() => itemData?.data?.name ?? "Franchise", [itemData]);
  const breadcrumbs = useMemo(() => (
    [
      { text: "Explore", href: "/explore" },
      { text: "Franchises", href: "/explore/franchise" },
      { text: itemData.data.name, href: "/" },
    ]
  ), [itemData]);

  const filterQuery = useMemo(() => {
    const mustConditions = [];

    // Quick title search
    if (filterObject.titleSearchQuery && filterObject.titleSearchQuery.length > 0) {
      mustConditions.push({
        filterType: "text",
        colId: "ip",
        type: "contains",
        filter: filterObject.titleSearchQuery,
      });
    }

    // Table advanced filters
    if (filterObject.advancedFilter) {
      mustConditions.push(filterObject.advancedFilter);
    }

    // Filters drawer filters
    const filterConditions = getFilterConditionsFromFilters(filterObject.filters);
    if (filterConditions.length > 0) {
      filterConditions.forEach((condition) => mustConditions.push(condition));
    }

    mustConditions.push({
      filterType: "object",
      colId: "franchise",
      type: "contains",
      filter: itemData.data.name.replaceAll(",", "\\,"),
    });

    if (mustConditions.length === 0) {
      return {};
    } else if (mustConditions.length === 1) {
      setOverrideTitleCounts({});
      return mustConditions[0];
    } else {
      return {
        filterType: "join",
        type: "AND",
        conditions: mustConditions,
      };
    }
  }, [filterObject, itemData]);

  const additionalColumnsOptions = useMemo(() => {
    return additionalColumns[selectedVertical.value].map((column) => ({
      label: column,
      value: column,
    }));
  }, [selectedVertical]);

  const handleSetVerticalSelectedIpIds = (vertical, ids) => {
    setVerticalSelectedIpIds(prev => ({ ...prev, [vertical]: ids }));
  };

  const rankingTiles = useMemo(() => {
    const newDataTables = {};
    Object.values(VERTICALS).forEach(vertical => {
      newDataTables[vertical] = (
        <GRPantheonRanking
          vertical={vertical}
          selectedIpIds={initialVerticalSelectedIpIds[vertical] ?? []}
          setSelectedIpIds={(ids) => handleSetVerticalSelectedIpIds(vertical, ids)}
          setSplitPanelStatus={setSplitPanelStatus}
          filterQuery={filterQuery}
          weightageValues={weightsObjectToWeightageValues(DEFAULT_WEIGHTS_OBJECTS[vertical])}
          dateRange={dateRange}
          useInfiniteScroll={false}
          onItemCountSet={(count) => setOverrideTitleCounts(prev => ({ ...prev, [vertical]: count }))}
          sortField={selectedSortOption.value}
        />
      );
    });
    return newDataTables;
  }, [initialVerticalSelectedIpIds, filterQuery, dateRange, selectedSortOption]);

  // Memoized just so the onRowCountSet prop doesn't trigger a re-render
  const rankingTables = useMemo(() => {
    const newDataTables = {};
    Object.values(VERTICALS).forEach(vertical => {
      newDataTables[vertical] = (
        <DataTable
          vertical={vertical}
          selectedIpIds={initialVerticalSelectedIpIds[vertical] ?? []}
          setSelectedIpIds={(ids) => handleSetVerticalSelectedIpIds(vertical, ids)}
          setSplitPanelStatus={setSplitPanelStatus}
          filterQuery={filterQuery}
          weightageValues={weightsObjectToWeightageValues(DEFAULT_WEIGHTS_OBJECTS[vertical])}
          dateRange={dateRange}
          advancedFilter={filterObject.advancedFilter}
          setAdvancedFilter={(value) => setFilterObject({ ...filterObject, advancedFilter: value })}
          additionalColumns={additionalColumns[vertical]}
          selectedAdditionalColumns={selectedAdditionalColumns}
          showLegend={false}
          autoHeight={true}
          showAdvancedFilter={false}
          usePagination={false}
          onRowCountSet={(count) => setOverrideTitleCounts(prev => ({ ...prev, [vertical]: count }))}
          sortField={selectedSortOption.value}
        />
      );
    });
    return newDataTables;
  }, [initialVerticalSelectedIpIds, filterQuery, dateRange, filterObject, selectedAdditionalColumns, selectedSortOption]);

  const compareContainer = useMemo(() => (
    <CompareContainer
      ipIds={Object.values(verticalSelectedIpIds).flat()}
      dateRange={dateRange}
    />
  ), [verticalSelectedIpIds, dateRange]);

  const getTab = ({ id, label }) => {
    return {
      label: label,
      id: id,
      action: (
        <TabActions
          tabId={id}
          preferencesApiParams={apiParams}
        />
      ),
    };
  };

  const filterDrawerConfig = {
    id: "filterDrawer",
    content: (
      <FiltersDrawer
        ref={filterDrawerRef}
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        vertical={selectedVertical.value}
      />
    ),
    trigger: {
      iconName: "filter",
      iconSvg: <Icon name="filter" />,
    },
    ariaLabels: {
      drawerName: "Filters",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
    badge: !_.isEqual(filterObject, DEFAULT_FILTER_OBJECTS[selectedVertical.value]),
  };

  const noImage = require("../../../../assets/images/icons/no-image_342.jpg");
  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;
    if (pref) {
      if (pref.primaryTab && !searchParams.get("tab")) {
        setActiveTabId(pref.primaryTab);
      }
    }
  }, [preferenceData]);

  useEffect(() => {
    setFilterObject(DEFAULT_FILTER_OBJECTS[selectedVertical.value]);
    filterDrawerRef.current?.setCurrentFilterObject(DEFAULT_FILTER_OBJECTS[selectedVertical.value]);
  }, [selectedVertical, filterDrawerRef]);

  return (
    <>
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        drawers={[/*filterDrawerConfig*/]}
        navItems={navItems}
        content={
          <ContentLayout
            disableOverlap
            disableContentPaddings
            header={
              <Container
                variant="borderless"
                disableContentPaddings
                disableHeaderPaddings
                header={
                  <Header
                    actions={
                      <ButtonDropdown
                        expandToViewport
                        items={[
                          { text: "Associate titles", id: "associate-titles" },
                          { text: "Disassociate titles", id: "disassociate-titles", },
                          { text: "Refresh cover image", id: "refresh-cover", disabled: itemData?.data?.total_ips <= 0, },
                          { text: "Edit tags", id: "edit-tags", disabled: !isAdmin },
                          { text: "Edit franchise", id: "edit-franchise" },
                          { text: "Merge franchise", id: "merge-franchise" },
                          { text: "Delete franchise", id: "delete-franchise" },
                        ]}
                        onItemClick={({ detail }) => {
                          switch (detail.id) {
                            case "associate-titles":
                              setAddTitlesModalVisible(true);
                              break;
                            case "disassociate-titles":
                              setRemoveTitlesModalVisible(true);
                              break;
                            case "refresh-cover":
                              onFranchiseUpdateCoverImage(itemData?.data?.ip_id);
                              break;
                            case "edit-tags":
                              setAttrModalVisible(true);
                              break;
                            case "edit-franchise":
                              setEditModalVisible(true);
                              break;
                            case "merge-franchise":
                              setMergeModalVisible(true);
                              break;
                            case "delete-franchise":
                              messageBoxRef.current.open({
                                headerText: "Delete franchise?",
                                messageBody: <div>Are you sure you want to delete the franchise <b>{itemData.data.name}</b>?</div>,
                                primaryButtonText: "Yes",
                                secondaryButtonText: "No",
                                onPrimaryButtonClick: () => {
                                  onFranchiseDeleteSubmit([itemData?.data?.ip_id]);
                                },
                              });
                              break;
                          }
                        }}
                      >
                        Actions
                      </ButtonDropdown>
                    }
                  >
                    <div className="text-xl font-bold">{title}</div>
                    <div className="text-sm text-slate-600 dark:text-slate-400 flex gap-1 font-normal">
                      {
                        [
                          "Franchise",
                          moment(itemData.data.first_date).format("YYYY") + " - " + moment(itemData.data.last_date).format("YYYY"),
                          ...(titleCounts[VERTICALS.MOVIES] > 0 ? [<VerticalIcon vertical={VERTICALS.MOVIES} titleCount={titleCounts[VERTICALS.MOVIES]} />] : []),
                          ...(titleCounts[VERTICALS.SERIES] > 0 ? [<VerticalIcon vertical={VERTICALS.SERIES} titleCount={titleCounts[VERTICALS.SERIES]} />] : []),
                          ...(titleCounts[VERTICALS.GAMES] > 0 ? [<VerticalIcon vertical={VERTICALS.GAMES} titleCount={titleCounts[VERTICALS.GAMES]} />] : []),
                        ].map((element, index) => [
                          index > 0 && " \u2022 ",
                          <span key={index}>{element}</span>,
                        ])
                      }
                    </div>
                  </Header>
                }
              >
                <div className="flex justify-between">
                  <div className="flex gap-4 py-1">
                    <img
                      className="h-28 rounded-md"
                      src={`https://images.searchpantheon.com/collections/${itemData.data.ip_id}_684.jpg`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noImage;
                      }}
                    />
                    <div className="flex flex-col">
                      <b>Overview</b>
                      <div className="text-sm font-normal">
                        <ReadMore text={itemData.data.summary} maxLength={500} />
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            }
          >

            <SpaceBetween direction="vertical" size="m">
              <Tabs
                disableContentPaddings
                onChange={({ detail }) => {
                  setActiveTabId(detail.activeTabId);
                  setSearchParams({ tab: detail.activeTabId });
                  setInitialVerticalSelectedIpIds(verticalSelectedIpIds);
                }}
                activeTabId={activeTabId}
                tabs={[
                  getTab({ id: "tile", label: "Titles - Tile View" }),
                  getTab({ id: "table", label: "Titles - Table View" }),
                  getTab({ id: "ids", label: "IDs" }),
                ]}
              />
              {(activeTabId === "tile" || activeTabId === "table") && (
                <BasicParameters
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  titleSearchQuery={filterObject.titleSearchQuery}
                  setTitleSearchQuery={(value) => setFilterObject({ ...filterObject, titleSearchQuery: value })}
                  showAdvancedFilter={false}
                  additionalFilters={
                    <>
                      {/*<Select
                        options={sortOptions}
                        selectedOption={selectedSortOption}
                        onChange={({ detail }) => setSelectedSortOption(detail.selectedOption)}
                      />*/}
                      {/*activeTabId === "table" ? (
                        <Multiselect
                          options={additionalColumnsOptions}
                          selectedOptions={selectedAdditionalColumns}
                          onChange={({ detail }) => setSelectedAdditionalColumns([...detail.selectedOptions])}
                          placeholder="Additional columns"
                          hideTokens
                        />
                      ) : null*/}
                      {/*<Select 
                        options={verticalOptions}
                        selectedOption={selectedVertical}
                        onChange={({ detail }) => setSelectedVertical(detail.selectedOption)}
                      />*/}
                    </>
                  }
                  loading={false}
                />
              )}
              {activeTabId === "tile" ? (
                <div className="flex flex-col gap-4">
                  {[
                    { vertical: VERTICALS.MOVIES, headerText: "Movies", },
                    { vertical: VERTICALS.SERIES, headerText: "Series", },
                    { vertical: VERTICALS.GAMES, headerText: "Video Games", },
                  ].sort((a, b) => titleCounts[b.vertical] - titleCounts[a.vertical]).filter(x => titleCounts[x.vertical] > 0 && overrideTitleCounts[x.vertical] !== 0).map(item => (
                    <ExpandableSection
                      key={item.vertical}
                      headerText={`${item.headerText} (${(overrideTitleCounts[item.vertical] != null &&
                        overrideTitleCounts[item.vertical] != titleCounts[item.vertical]) ?
                        ((overrideTitleCounts[item.vertical] === -1 ? "?" : overrideTitleCounts[item.vertical]) + " of " +
                          titleCounts[item.vertical]) : titleCounts[item.vertical]})`}
                      defaultExpanded
                    >
                      {rankingTiles[item.vertical]}
                    </ExpandableSection>
                  ))}
                </div>
              ) : activeTabId === "table" ? (
                <div className="flex flex-col gap-4">
                  {[
                    { vertical: VERTICALS.MOVIES, headerText: "Movies", },
                    { vertical: VERTICALS.SERIES, headerText: "Series", },
                    { vertical: VERTICALS.GAMES, headerText: "Video Games", },
                  ].sort((a, b) => titleCounts[b.vertical] - titleCounts[a.vertical]).filter(x => titleCounts[x.vertical] > 0 && overrideTitleCounts[x.vertical] !== 0).map(item => (
                    <div>
                      <ExpandableSection
                        key={item.vertical}
                        headerText={`${item.headerText} (${(overrideTitleCounts[item.vertical] != null &&
                          overrideTitleCounts[item.vertical] != titleCounts[item.vertical]) ?
                          ((overrideTitleCounts[item.vertical] === -1 ? "?" : overrideTitleCounts[item.vertical]) + " of " +
                            titleCounts[item.vertical]) : titleCounts[item.vertical]})`}
                        defaultExpanded
                      >
                        {rankingTables[item.vertical]}
                      </ExpandableSection>
                    </div>
                  ))}
                </div>
              ) : activeTabId === "ids" ? (
                <div className="flex flex-col gap-2">
                  <Header
                    actions={
                      <Button
                        iconName="edit"
                        onClick={() => setAttrModalVisible(true)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    IDs
                  </Header>
                  <TagsAndIDsComponent itemData={itemData.data} />
                </div>
              ) : null}
            </SpaceBetween>
          </ContentLayout>
        }
        contentType="table"
        splitPanel={
          compareContainer
        }
        splitPanelStatus={splitPanelStatus}
        setSplitPanelStatus={setSplitPanelStatus}
      />
      <MessageBox ref={messageBoxRef} />
      <AttributesEditModalV2
        itemData={itemData.data}
        visible={attrModalVisible}
        onSubmit={(data) => {
          onTagUpdateSubmit(data);
          setAttrModalVisible(false);
        }}
        onDismiss={() => setAttrModalVisible(false)}
        isLoading={attributeProcessing}
        editableAttributes={[
          "wikipedia",
          "trends",
          "fandom",
          "a03",
          "subreddit",
          "twitter",
          "youtubeChannel",
        ]}
      />
      <AddTitlesToFranchiseModal
        visible={addTitlesModalVisible}
        onDiscard={() => setAddTitlesModalVisible(false)}
        onSubmit={(items) => {
          onAddItems({
            id: itemData?.data?.ip_id,
            name: itemData?.data?.name,
            removed: [],
            added: items,
          });
          //setAddTitlesModalVisible(false);
        }}
        excludeIpIds={itemData?.data?.items ? itemData?.data?.items.map(item => item.ip_id) : []}
      />
      <RemoveTitlesFromFranchiseModal
        visible={removeTitlesModalVisible}
        onDiscard={() => setRemoveTitlesModalVisible(false)}
        onSubmit={(items) => {
          onDelete({
            id: itemData?.data?.ip_id,
            name: itemData?.data?.name,
            removed: items,
            added: [],
          });
          //setRemoveTitlesModalVisible(false);
        }}
        titles={itemData?.data?.items ? [...itemData?.data?.items].sort((a, b) => a.ip.toString().localeCompare(b.ip.toString())) : []}
      />
      <FranchiseEditModal
        visible={editModalVisible}
        onDiscard={() => setEditModalVisible(false)}
        onSubmit={(e) => {
          onFranchiseEditSubmit(e);
          setEditModalVisible(false);
        }}
        title={itemData?.data?.name || ""}
        description={itemData?.data?.summary || ""}
      />
      <MergeFranchisesModal
        visible={mergeModalVisible}
        onDiscard={() => setMergeModalVisible(false)}
        onSubmit={(items) => {
          if (items) {
            onMergeItems({
              source: {
                id: items?.name,
                name: items?.id,
              },
              destination: {
                id: itemData?.data?.ip_id,
                name: itemData?.data?.name,
              },
            });
          }
          setMergeModalVisible(false);
        }}
        ip={itemData?.data?.name}
        ip_id={itemData?.data?.ip_id}
      />
    </>
  );
};

export default ItemFranchise;