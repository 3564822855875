import {
  Box,
  ExpandableSection,
  Grid,
  SpaceBetween,
} from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import React, { FC } from "react";
import SocialTrends from "../../components/SocialTrends";
import { isDataAvailable } from "../../utils";
import { DateRange } from "./DateRange";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { PiracyTrends } from "./PiracyTrends/PiracyTrends";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";

interface PlatformTrendsProps {
  dateRange: any;
  setDateRange: (dateRange: { startDate: string; endDate: string }) => void;
  itemData: any;
  wikipediaTrendsData: any;
  wikipediaStats: (data: any) => void;
  youtubeTrendsData: any;
  youtubeStats: (data: any) => void;
  imdbTrendsData: any;
  imdbStats: (data: any) => void;
  piracyTrends: any;
  instagramHashtagsData: any;
  trackedRedditTrendChartOption: any;
}

export const PlatformTrends: FC<PlatformTrendsProps> = ({
  dateRange,
  setDateRange,
  itemData,
  wikipediaTrendsData,
  wikipediaStats,
  youtubeTrendsData,
  youtubeStats,
  imdbTrendsData,
  imdbStats,
  piracyTrends,
  instagramHashtagsData,
  trackedRedditTrendChartOption,
}) => {
  return (
    <SpaceBetween direction="vertical" size="l">
      <Box float="right" variant="p">
        <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      </Box>
      {itemData?.data?.tracked && itemData?.data?.instagram && (
        <ExpandableSection
          defaultExpanded
          headingTagOverride="h4"
          variant="container"
          headerText="Instagram Trends"
        >
          <SocialTrends
            itemData={itemData}
            instagramHashtagsData={instagramHashtagsData}
          />
        </ExpandableSection>
      )}
      <Grid
        gridDefinition={[
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
        ]}
      >
        {isDataAvailable(wikipediaTrendsData) && (
          <WikipediaTrends
            data={wikipediaTrendsData}
            onLatestStat={wikipediaStats}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
        {isDataAvailable(youtubeTrendsData) && (
          <YoutubeTrends
            data={youtubeTrendsData}
            onLatestStat={youtubeStats}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
        {isDataAvailable(imdbTrendsData) && (
          <ImdbTrends
            data={imdbTrendsData}
            onLatestStat={imdbStats}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
      </Grid>
      {itemData?.data?.hasOwnProperty("tracked") &&
        itemData?.data?.tracked &&
        itemData?.data?.subreddit && (
          <ExpandableSection
            variant="container"
            defaultExpanded
            headerText="Reddit Trends"
          >
            {trackedRedditTrendChartOption && (
              <div className="h-80">
                <AgCharts
                  options={trackedRedditTrendChartOption}
                  style={{ height: "100%" }}
                />
              </div>
            )}
          </ExpandableSection>
        )}
      <PiracyTrends piracyTrends={piracyTrends} />
    </SpaceBetween>
  );
};
