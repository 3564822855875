import { Box, Grid, SpaceBetween } from "@cloudscape-design/components";
import React, { FC } from "react";
import { DateRange } from "./DateRange";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { isDataAvailable } from "../../utils";

interface RankTrendsProps {
  dateRange: any;
  setDateRange: (range: [Date, Date]) => void;
  wikipediaTrendsData: any;
  wikipediaStats: any;
  youtubeTrendsData: any;
  youtubeStats: any;
  imdbTrendsData: any;
  imdbStats: any;
}

export const RankTrends: FC<RankTrendsProps> = ({
  dateRange,
  setDateRange,
  wikipediaTrendsData,
  wikipediaStats,
  youtubeTrendsData,
  youtubeStats,
  imdbTrendsData,
  imdbStats,
}) => {
  return (
    <SpaceBetween direction="vertical" size="l">
      <Box float="right" variant="p">
        <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      </Box>
      <Grid
        gridDefinition={[
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
        ]}
      >
        {isDataAvailable(wikipediaTrendsData) && (
          <WikipediaTrends
            data={wikipediaTrendsData}
            onLatestStat={wikipediaStats}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(youtubeTrendsData) && (
          <YoutubeTrends
            data={youtubeTrendsData}
            onLatestStat={youtubeStats}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(imdbTrendsData) && (
          <ImdbTrends
            data={imdbTrendsData}
            onLatestStat={imdbStats}
            dateRange={dateRange}
            configType="basic"
          />
        )}
      </Grid>
    </SpaceBetween>
  );
};
