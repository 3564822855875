import { SplitPanel } from "@cloudscape-design/components";
import DatapointComparison from "../../../components/datapoint-comparison/DatapointComparison";
import { METRIC_GROUPS } from "../../../components/datapoint-comparison/constants";
import { DATAPOINTS, PLATFORMS, VERTICALS } from "../../../config-global";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../ranking/constants";

const getDefaultRankingWeight = (vertical, platform) => {
  const weightsObject = DEFAULT_WEIGHTS_OBJECTS[vertical];
  const platformWeight = weightsObject.weights.find(weight => weight.platform.key === platform.key);
  return platformWeight?.weight ?? 0.0;
};

const metrics = [
  {
    name: "Weighted Score",
    key: "weighted_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    hideInSummary: true,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.WIKIPEDIA,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.WIKIPEDIA) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.WIKIPEDIA) },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.WIKIPEDIA) },
        ],
      },
      { 
        _platform: PLATFORMS.YOUTUBE,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.YOUTUBE) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.YOUTUBE) },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.YOUTUBE) },
        ],
      },
      { 
        _platform: PLATFORMS.IMDB,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.IMDB.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.IMDB) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.IMDB) },
          { idPrefix: "game", weight: 0 },
        ],
      },
      { 
        _platform: PLATFORMS.PIRACY,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.PIRACY.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.PIRACY) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.PIRACY) },
          { idPrefix: "game", weight: 0 },
        ],
      },
      { 
        _platform: PLATFORMS.ROTTEN_TOMATOES,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.ROTTEN_TOMATOES) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.ROTTEN_TOMATOES) },
          { idPrefix: "game", weight: 0 },
        ],
      },
      { 
        _platform: PLATFORMS.STEAM,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.STEAM.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: 0 },
          { idPrefix: "series", weight: 0 },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.STEAM) },
        ],
      },
      { 
        _platform: PLATFORMS.TWITCH,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.TWITCH.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: 0 },
          { idPrefix: "series", weight: 0 },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.TWITCH) },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.WIKIPEDIA,
    name: "Wikipedia Z-Score",
    key: "global_wiki_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.WIKIPEDIA,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.WIKIPEDIA) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.WIKIPEDIA) },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.WIKIPEDIA) },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.YOUTUBE,
    name: "YouTube Z-Score",
    key: "global_yt_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.YOUTUBE,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.YOUTUBE) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.YOUTUBE) },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.YOUTUBE) },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.IMDB,
    name: "IMDb Z-Score",
    key: "global_imdb_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.IMDB,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.IMDB.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.IMDB) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.IMDB) },
          { idPrefix: "game", weight: 0 },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.PIRACY,
    name: "Piracy Z-Score",
    key: "global_piracy_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.PIRACY,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.PIRACY.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.PIRACY) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.PIRACY) },
          { idPrefix: "game", weight: 0 },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.ROTTEN_TOMATOES,
    name: "Rotten Tomatoes Z-Score",
    key: "global_rt_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.ROTTEN_TOMATOES,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.ROTTEN_TOMATOES) },
          { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.ROTTEN_TOMATOES) },
          { idPrefix: "game", weight: 0 },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.STEAM,
    name: "Steam Z-Score",
    key: "global_steam_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.STEAM,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.STEAM.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: 0 },
          { idPrefix: "series", weight: 0 },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.STEAM) },
        ],
      },
    ],
  },
  {
    _platform: PLATFORMS.TWITCH,
    name: "Twitch Z-Score",
    key: "global_twitch_score",
    group: METRIC_GROUPS.ZSCORE,
    normalize: false,
    decimalDigits: 0,
    datapoints: [
      { 
        _platform: PLATFORMS.TWITCH,
        platform: PLATFORMS.PANTHEON_GLOBAL, 
        datapoint: DATAPOINTS.TWITCH.ZSCORE, 
        weight: [
          { idPrefix: "film", weight: 0 },
          { idPrefix: "series", weight: 0 },
          { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.TWITCH) },
        ],
      },
    ],
  },
];

export const CompareContainer = ({
  ipIds,
  dateRange,
}) => {

  return (
    <SplitPanel
      header="Compare Titles"
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription: "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <div className="mb-4">
      <DatapointComparison
        metrics={metrics}
        defaults={{
          metricKeys: metrics.filter(metric => metric.group === METRIC_GROUPS.ZSCORE).map(metric => metric.key),
          activeMetricKey: "summary",
          parameters: [
            {
              dateRange: {
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
              },
              titleIds: ipIds,
            },
          ],
        }}
        options={{
          container: {
            disablePadding: true,
            disableBorder: true,
          },
          parameters: {
            enabled: false,
          },
          chart: {
            height: 400,
          },
          summary: {
            enabled: true,
          },
        }}
      />
      </div>
    </SplitPanel>
  );
};