import { useMemo } from "react";
import { Link, Grid } from "@cloudscape-design/components";
import { numberShortFormatter } from "../../../utils";
import { DEFAULT_DARK_STROKES } from "../../../constants";

export const useGridConfig = ({
  titles,
  metrics,
  metricData,
  multiParamInfo,
}) => {

  const tableRowData = useMemo(() => {
    const titleMap = titles.map(title => ({ [title.value]: title })).reduce((acc, obj) => ({ ...acc, ...obj }), {});
    const titleObjects = Object.values(titleMap);

    let i = 0;
    const titleColorMap = multiParamInfo.map((paramInfo, paramIndex) => (
      titleObjects.filter(title => paramInfo.title_ids.includes(title.value)).map((title) => (
        { [`${title.value}_${paramIndex}`]: DEFAULT_DARK_STROKES[i++ % DEFAULT_DARK_STROKES.length] }
      )).reduce((acc, obj) => ({ ...acc, ...obj }), {})
    )).reduce((acc, obj) => ({ ...acc, ...obj }), {});

    return multiParamInfo.map((paramInfo, paramIndex) => {
      return [...new Set(titles.map(t => t.value))].filter(id => paramInfo.title_ids.includes(id)).map((ip_id) => {
        const title = titles.find(t => t.value === ip_id);
        return {
          title: title.label,
          ip_id: title.value,
          image_url: title.iconUrl,
          image_alt: title.iconAlt,
          color: titleColorMap[`${title.value}_${paramIndex}`],
          tags: title.tags,
          ...metrics.reduce((acc, metric) => {
            const metricDataForTitle = metricData.find(
              (d) => d.ip_id === title.value && d.param_index === paramIndex,
            );
            return {
              ...acc,
              [metric.key]: metricDataForTitle?.[metric.key] ?? null,
            };
          }, {}),
        };
      });
    }).flat();
  }, [titles, metrics, metricData, multiParamInfo]);

  const gridOptions = useMemo(() => {
    return {
      rowData: tableRowData,
      columnDefs: [
        {
          field: "title",
          headerName: "Title",
          headerCheckboxSelection: false,
          checkboxSelection: false,
          showDisabledCheckboxes: false,
          minWidth: 300,
          flex: 1,
          suppressHeaderMenuButton: true,
          cellRenderer: (params) => (
            <div className="flex space-x-2 items-center leading-none h-full">
              <div style={{ backgroundColor: params.data.color, width: "15px", height: "4px", minWidth: "15px", borderRadius: "2px" }}></div>
              <img
                src={params.data.image_url}
                alt={params.data.image_alt}
                className="w-8 h-8 min-w-8 object-cover rounded-md"
              />
              <div className="flex flex-col justify-between h-full py-0.5">
                <div className="p-0">
                  <Link href={`/item/${params.data.ip_id}`}>
                    {params.value}
                  </Link>
                </div>
                <div className="text-xs text-slate-400">
                  {params.data.tags.join(" \u2022 ")}
                </div>
              </div>
            </div>
          ),
        },
        ...metrics.map((metric) => ({
          field: metric.key,
          headerName: metric.name,
          width: 175,
          suppressHeaderMenuButton: true,
          sortable: false,
          cellRenderer: (params) => (
            <div className="w-full h-full leading-tight items-center justify-items-end">
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {!metric.normalize ? (
                  <div className="flex flex-col items-end">
                    <div className="text-xs text-slate-400">Total</div>
                    <div
                      className="text-s"
                      title={
                        params.value?.sum
                          ? params.value.sum.toLocaleString(undefined, {
                              maximumFractionDigits:
                                metric.decimalDigits ?? 2,
                            }) + (metric.normalize ? "" : "")
                          : "No value"
                      }
                    >
                      {params.value?.sum
                        ? numberShortFormatter(params.value.sum) +
                          (metric.normalize ? "" : "")
                        : "-"}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="flex flex-col items-end">
                  <div className="text-xs text-slate-400">Average</div>
                  <div
                    className="text-s"
                    title={
                      params.value?.average
                        ? params.value.average.toLocaleString(undefined, {
                            maximumFractionDigits:
                              metric.decimalDigits ?? 2,
                          }) + (metric.normalize ? "" : "")
                        : "No value"
                    }
                  >
                    {params.value?.average
                      ? (metric.normalize
                          ? params.value.average.toLocaleString(undefined, {
                              maximumFractionDigits:
                                metric.decimalDigits ?? 2,
                            })
                          : numberShortFormatter(params.value.average)) +
                        (metric.normalize ? "" : "")
                      : "-"}
                  </div>
                </div>
                {/*<div className="flex flex-col items-end">
                    <div className="text-xs text-slate-400">Peak</div>
                    <div className="text-s">{params.value?.max ? (params.value.max.toLocaleString(undefined, { maximumFractionDigits: metric.decimalDigits ?? 2 }) + (metric.normalize ? "%" : "")) : "-"}</div>
                  </div>*/}
              </Grid>
            </div>
          ),
        })),
        ...metrics
          .map((metric) => [
            {
              field: `${metric.key}_average`,
              headerName: `${metric.name} (Average)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.average,
            },
            {
              field: `${metric.key}_max`,
              headerName: `${metric.name} (Peak)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.max,
            },
            {
              field: `${metric.key}_sum`,
              headerName: `${metric.name} (Total)`,
              width: 150,
              hide: true,
              valueGetter: (params) => params.data[metric.key]?.sum,
            },
          ])
          .flat(),
      ],
      domLayout: tableRowData.length <= 10 ? "autoHeight" : "normal",
      suppressRowClickSelection: true,
    };
  }, [tableRowData, metrics]);

  return {
    gridOptions,
  };
};