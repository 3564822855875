import {
  Box,
  ColumnLayout,
  Container,
  Link,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import shortenInteger from "../../../../utils/shortenInteger";
import {
  getSentimentCategory,
  getSentimentColor,
} from "../../../youtube/channelPage/components/VideoList/utils";
import { TrailerResponseData } from "../../api/types";

interface TrailerProps {
  video: TrailerResponseData;
}

export const TrailerCard: FC<TrailerProps> = ({ video }) => {
  return (
    <Container
      disableHeaderPaddings
      disableContentPaddings
      fitHeight
      media={{
        content: (
          <iframe
            title={video.ip}
            src={`https://www.youtube.com/embed/${video.videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ),
        height: 350,
        position: "top",
      }}
      footer={
        <>
          <ColumnLayout minColumnWidth={100} columns={4} variant="text-grid">
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                {" "}
                Views{" "}
              </Box>
              <Box textAlign="center">{shortenInteger(video?.views)}</Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                {" "}
                Likes{" "}
              </Box>
              <Box textAlign="center">{shortenInteger(video?.likes)}</Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                {" "}
                Comments{" "}
              </Box>
              <Box textAlign="center"> {shortenInteger(video?.comments)} </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                {" "}
                Score{" "}
              </Box>
              <Box textAlign="center">
                {" "}
                {video?.sentimentScore?.toFixed(3)}{" "}
              </Box>
            </div>
          </ColumnLayout>
        </>
      }
    >
      <Box padding={{ horizontal: "s", top: "xs" }}>
        {video.sentimentScore !== null &&
          video.sentimentScore !== undefined && (
            <Box
              variant="small"
              color={getSentimentColor(video.sentimentScore)}
            >
              {" "}
              {getSentimentCategory(video.sentimentScore)} Sentiment{" "}
            </Box>
          )}
        <br />
        <Link
          fontSize="heading-m"
          href={`/mediacatalog/youtube/videos/${video.videoId}`}
          target="_blank"
        >
          {video.title}
        </Link>
        {video?.madeForKids && (
          <span className="ml-2 inline-flex items-center rounded-md bg-green-700 px-1 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-green-400">
            Kids
          </span>
        )}
        {video?.official && (
          <span className="ml-2 inline-flex items-center rounded-md bg-amber-600 px-1 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-amber-400">
            Official
          </span>
        )}
      </Box>
      <Box variant="small" padding={{ horizontal: "s" }}>
        YouTube Channel:
        <Link
          fontSize="heading-xs"
          href={`/mediacatalog/youtube/channels/${video.channelId}`}
        >
          {video.channel}
        </Link>{" "}
        | Published On:
        {new Date(video.publishedAt * 1000).toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </Box>
    </Container>
  );
};
