import {
  AppLayout,
  AppLayoutProps,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BreadcrumbsItem,
  NavItemWithId,
  NavItemsWithId,
  SERVICES_GROUP,
} from "../../layouts/common/menu/side-menu";

interface LayoutProps {
  title: string;
  breadcrumbs: Array<BreadcrumbsItem>;
  drawers?: Array<AppLayoutProps.Drawer>;
  navItems: NavItemsWithId;
  content: ReactNode;
  splitPanel?: ReactNode;
  contentType: AppLayoutProps.ContentType;
  headerSelector?: string;
  selectedIps?: Array<any>;
  setSelectedIps?: (selectedIps: Array<string>) => void;
  comparedIp?: any;
  setComparedIp?: any;
  splitPanelStatus?: boolean;
  setSplitPanelStatus?: (status: boolean) => void;
}

export const Layout: FC<LayoutProps> = ({
  title,
  breadcrumbs,
  drawers,
  navItems,
  content,
  splitPanel,
  contentType,
  headerSelector,
  splitPanelStatus,
  setSplitPanelStatus,
}) => {
  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item: NavItemWithId) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.EXPLORE,
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        drawers={drawers ? drawers : []}
        headerSelector={headerSelector}
        ariaLabels={{ navigationClose: "close" }}
        navigationWidth={200}
        navigation={
          <SideNavigation
            onFollow={onFollowHandler}
            activeHref={window.location.pathname}
            items={filteredNavItems}
          />
        }
        content={content}
        contentType={contentType}
        breadcrumbs={
          <BreadcrumbGroup
            onFollow={onFollowHandler}
            items={breadcrumbs as any}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        splitPanel={splitPanel}
        splitPanelOpen={splitPanelStatus}
        onSplitPanelToggle={({ detail: { open } }) => setSplitPanelStatus(open)}
      />
    </>
  );
};
