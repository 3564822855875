import { useCallback, useState } from "react";
import { CHART_TYPES, useGetChartTheme } from "../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../utils/shortenInteger";

const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a.dailyRank - b.dailyRank);
  const middle = Math.floor(sortedData.length / 2);

  if (sortedData.length % 2 === 0) {
    return (sortedData[middle - 1].dailyRank + sortedData[middle].dailyRank) / 2;
  } else {
    return sortedData[middle].dailyRank;
  }
};

const splitDataByMedian = (data, median) => {
  const aboveMedian = [];
  const belowMedian = [];

  data.forEach((point, index) => {
    if (point.dailyRank >= median) {
      if (index > 0 && data[index - 1].dailyRank < median) {
        const slope = (point.dailyRank - data[index - 1].dailyRank) / (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = { ...point, timestamp: medianTimestamp, dailyRank: median };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      aboveMedian.push(point);
    } else {
      if (index > 0 && data[index - 1].dailyRank >= median) {
        const slope = (point.dailyRank - data[index - 1].dailyRank) / (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = { ...point, timestamp: medianTimestamp, dailyRank: median };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      belowMedian.push(point);
    }
  });

  return { aboveMedian, belowMedian };
};

export const useBasicChartConfig = (data) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);
  const [previousRank, setPreviousRank] = useState(null);

  const getLineChartOptions = useCallback(
    (axisTypeValue) => {
      if (!data || data.length === 0) {
        return {}; // Return empty options if data is not available
      }

      const medianRank = calculateMedian(data);
      const { aboveMedian, belowMedian } = splitDataByMedian(data, medianRank);

      return {
        tooltip: { enabled: false },
        padding: { right: 0, bottom: 0, left: 0 },
        minWidth: 0,
        minHeight: 0,
        theme: theme,
        legend: { enabled: false },
        series: [
          {
            type: "line",
            xKey: "timestamp",
            yKey: "dailyRank",
            yName: "Daily Ranking (Above Median)",
            marker: { enabled: false },
            stroke: "red",
            data: aboveMedian,
          },
          {
            type: "line",
            xKey: "timestamp",
            yKey: "dailyRank",
            yName: "Daily Ranking (Below Median)",
            marker: { enabled: false },
            stroke: "green",
            data: belowMedian,
          },
        ],
        sync: { enabled: true, axes: "x", nodeInteraction: true },
        zoom: { enabled: true, enableSelecting: true },
        axes: [
          {
            type: "time",
            position: "bottom",
            label: { format: "%b %-d, %Y", fontSize: 10 },
            crosshair: { label: { format: "%b %-d, %Y" } },
          },
          {
            type: "log",
            reverse: true,
            keys: ["dailyRank"],
            crosshair: { label: { format: "d" } },
            gridLine: {
              enabled: true,
            },
            label: {
              formatter: function (params) {
                return shortenInteger(params.value);
              },
            },
            crossLines: [
              {
                type: 'line',
                value: medianRank,
                stroke: 'gray',
                strokeWidth: 2,
                label: {
                  text: `Median: ${medianRank}`,
                  position: 'right',
                  color: 'grey',
                  fontSize: 12,
                },
              },
            ],
          },
        ],
      };
    },
    [theme, previousRank, data]
  );

  return { getLineChartOptions };
};
