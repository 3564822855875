import { 
  AppLayout,
  ContentLayout,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Button,
} from "@cloudscape-design/components";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import PantheonFlashbar from "../../components/PantheonFlashbar";
import { navItems } from "../../layouts/common/menu/side-menu";
import DatapointComparison from "../../components/datapoint-comparison/DatapointComparison";
import { urlParamsFromParameters, parametersFromUrlParams } from "../../components/datapoint-comparison/utils";
import { useSearchParams } from "react-router-dom";
import { METRIC_GROUPS } from "../../components/datapoint-comparison/constants";
import { PLATFORMS, DATAPOINTS, VERTICALS } from "../../config-global";
import { DEFAULT_WEIGHTS_OBJECTS } from "../ranking/constants";
  
const TmpComparePage = () => {
    
  const flashbarRef = useRef(null);

  const [ breadcrumbs, setBreadcrumbs ] = useState([{ text: "Compare Titles", href: "/" }]);
  const [ searchParams ] = useSearchParams();

  const getDefaultRankingWeight = (vertical, platform) => {
    const weightsObject = DEFAULT_WEIGHTS_OBJECTS[vertical];
    const platformWeight = weightsObject.weights.find(weight => weight.platform.key === platform.key);
    return platformWeight?.weight ?? 0.0;
  };

  const defaultDefaults = {
    metricKeys: ["search", "social"],
    activeMetricKey: "search",
    parameters: [
      {
        titleIds: ["film-Q21512706"],
      },
    ],
  };

  const hardCodedMetrics = [
    {
      name: "Search",
      key: "search",
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.WIKIPEDIA,
          datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS,
          weight: 1.0,
        },
        { 
          platform: PLATFORMS.GOOGLE_TRENDS, 
          datapoint: DATAPOINTS.GOOGLE_TRENDS.WORLDWIDE, 
          weight: 0.02,
        },
      ],
    },
    {
      name: "Social",
      key: "social",
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.TWITTER,
          datapoint: DATAPOINTS.TWITTER.TWEET_COUNT,
          weight: 1.0 
        },
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.UPVOTES, 
          weight: 0.05 
        },
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.COMMENTS, 
          weight: 0.05 
        },
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.SUBREDDIT_MEMBERS, 
          weight: 2.0 
        },
      ],
    },
    {
      name: "Gaming",
      key: "gaming",
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.STEAM,
          datapoint: DATAPOINTS.STEAM.PLAYER_COUNT,
          weight: 0.5,
        },
        { 
          platform: PLATFORMS.TWITCH, 
          datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT, 
          weight: 0.5 
        },
        { 
          platform: PLATFORMS.TWITCH, 
          datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wikipedia Page Views",
      key: "wiki_page_views",
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WIKIPEDIA, 
          datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Google Trends WW",
      key: "gt_ww",
      normalize: true,
      decimalDigits: 2,
      datapoints: [
        { 
          platform: PLATFORMS.GOOGLE_TRENDS, 
          datapoint: DATAPOINTS.GOOGLE_TRENDS.WORLDWIDE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitter Volume",
      key: "tweets",
      unit: "tweets",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.TWITTER, 
          datapoint: DATAPOINTS.TWITTER.TWEET_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Reddit Upvotes",
      key: "reddit_upvotes",
      unit: "upvotes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.UPVOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Reddit Comments",
      key: "reddit_comments",
      unit: "comments",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT, 
          datapoint: DATAPOINTS.REDDIT.COMMENTS,
          weight: 1.0
        },
      ],
    },
    {
      name: "Reddit Members",
      key: "reddit_members",
      unit: "members",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.REDDIT,
          datapoint: DATAPOINTS.REDDIT.SUBREDDIT_MEMBERS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YT Views",
      key: "yt_views",
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.YOUTUBE, 
          datapoint: DATAPOINTS.YOUTUBE.VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YT Comments",
      key: "yt_comments",
      unit: "comments",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.YOUTUBE, 
          datapoint: DATAPOINTS.YOUTUBE.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YT Likes",
      key: "yt_likes",
      unit: "likes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.YOUTUBE, 
          datapoint: DATAPOINTS.YOUTUBE.LIKES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Views",
      key: "wp_views",
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Comments",
      key: "wp_comments",
      unit: "comments",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wattpad Stars",
      key: "wp_stars",
      unit: "stars",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.WATTPAD, 
          datapoint: DATAPOINTS.WATTPAD.STARS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Hits",
      key: "ao3_hits",
      unit: "hits",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.HITS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Comments",
      key: "ao3_comments",
      unit: "comments",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.COMMENTS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "AO3 Kudos",
      key: "ao3_kudos",
      unit: "kudos",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.AO3, 
          datapoint: DATAPOINTS.AO3.KUDOS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Torrent Downloads",
      key: "piracy_downloads",
      unit: "downloads",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PIRACY, 
          datapoint: DATAPOINTS.PIRACY.DOWNLOADS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Steam Players",
      key: "steam_ccu",
      unit: "players",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.STEAM, 
          datapoint: DATAPOINTS.STEAM.PLAYER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Viewers",
      key: "twitch_viewers",
      unit: "viewers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.TWITCH, 
          datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Streamers",
      key: "twitch_streamers",
      unit: "streamers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.TWITCH, 
          datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wikipedia Page Views (Global)",
      key: "global_wiki_page_views",
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_WIKIPEDIA, 
          datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Steam Players (Global)",
      key: "global_steam_ccu",
      unit: "players",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_STEAM, 
          datapoint: DATAPOINTS.STEAM.PLAYER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Viewers (Global)",
      key: "global_twitch_viewers",
      unit: "viewers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_TWITCH, 
          datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Streamers (Global)",
      key: "global_twitch_streamers",
      unit: "streamers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_TWITCH, 
          datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "IMDb Votes (Global)",
      key: "global_imdb_votes",
      unit: "votes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_IMDB, 
          datapoint: DATAPOINTS.IMDB.VOTES, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "IMDb Rating (Global)",
      key: "global_imdb_rating",
      unit: "rating",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.GLOBAL_IMDB, 
          datapoint: DATAPOINTS.IMDB.RATING, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Wikipedia Z-Score",
      key: "global_wiki_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "IMDb Z-Score",
      key: "global_imdb_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.IMDB.ZSCORE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "YouTube Z-Score",
      key: "global_yt_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Steam Z-Score",
      key: "global_steam_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.STEAM.ZSCORE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Twitch Z-Score",
      key: "global_twitch_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        { 
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.TWITCH.ZSCORE, 
          weight: 1.0 
        },
      ],
    },
    {
      name: "Weighted Score",
      key: "weighted_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      hideInSummary: true,
      decimalDigits: 0,
      datapoints: [
        { 
          _platform: PLATFORMS.WIKIPEDIA,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.WIKIPEDIA) },
            { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.WIKIPEDIA) },
            { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.WIKIPEDIA) },
          ],
        },
        { 
          _platform: PLATFORMS.YOUTUBE,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.YOUTUBE.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.YOUTUBE) },
            { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.YOUTUBE) },
            { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.YOUTUBE) },
          ],
        },
        { 
          _platform: PLATFORMS.IMDB,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.IMDB.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.IMDB) },
            { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.IMDB) },
            { idPrefix: "game", weight: 0 },
          ],
        },
        { 
          _platform: PLATFORMS.PIRACY,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.PIRACY.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.PIRACY) },
            { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.PIRACY) },
            { idPrefix: "game", weight: 0 },
          ],
        },
        { 
          _platform: PLATFORMS.ROTTEN_TOMATOES,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: getDefaultRankingWeight(VERTICALS.MOVIES, PLATFORMS.ROTTEN_TOMATOES) },
            { idPrefix: "series", weight: getDefaultRankingWeight(VERTICALS.SERIES, PLATFORMS.ROTTEN_TOMATOES) },
            { idPrefix: "game", weight: 0 },
          ],
        },
        { 
          _platform: PLATFORMS.STEAM,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.STEAM.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: 0 },
            { idPrefix: "series", weight: 0 },
            { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.STEAM) },
          ],
        },
        { 
          _platform: PLATFORMS.TWITCH,
          platform: PLATFORMS.PANTHEON_GLOBAL, 
          datapoint: DATAPOINTS.TWITCH.ZSCORE, 
          weight: [
            { idPrefix: "film", weight: 0 },
            { idPrefix: "series", weight: 0 },
            { idPrefix: "game", weight: getDefaultRankingWeight(VERTICALS.GAMES, PLATFORMS.TWITCH) },
          ],
        },
      ],
    },
  ];

  const [ defaults, setDefaults ] = useState(null);

  useEffect(() => {
    if (defaults) return;
    setDefaults(parametersFromUrlParams(searchParams) || defaultDefaults);
  }, [searchParams]);

  return (
    <>
      <Helmet><title>Compare Titles</title></Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        navigationHide={true}
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <>
            <ContentLayout
              header={
                <>
                  <PantheonFlashbar ref={flashbarRef} />
                  <Header>
                    Compare Titles
                  </Header>
                </>
              }
            >
              {defaults && (
                <DatapointComparison 
                  metrics={hardCodedMetrics}
                  defaults={defaults}
                  onParametersChanged={({ detail }) => {
                    const urlParams = urlParamsFromParameters(detail);
                    window.history.pushState({}, "", `?${urlParams}`);
                  }}
                  options={{
                    container: {
                      disablePadding: false,
                      disableBorder: false,
                    },
                    chart: {
                      chartNotes: {
                        enabled: true,
                      },
                    },
                    summary: {
                      enabled: true,
                    },
                  }}
                />
              )}
              <Button
                onClick={() => {
                  setDefaults(
                    {
                      metricKeys: ["search", "social"],
                      activeMetricKey: "search",
                      parameters: [
                        {
                          titleIds: ["film-Q21512706"],
                        },
                      ],
                    }
                  );
                }}
              >Defaults 1</Button>
              <Button
                onClick={() => {
                  setDefaults(
                    {
                      metricKeys: ["tweets", "social"],
                      activeMetricKey: "social",
                      parameters: [
                        {
                          titleIds: ["film-Q106329715"],
                        },
                      ],
                    }
                  );
                }}
              >Defaults 2</Button>
            </ContentLayout>
          </>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TmpComparePage;