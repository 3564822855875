import { useCallback, useEffect } from "react";

export const useFetchServerSideGridData = ({
  filter,
  gridRef,
  vertical,
  fetchMethod,
  disabled,
  startTs,
  endTs,
  weightageValues,
}) => {
  const createServer = useCallback(() => {
    return {
      getData: async (request) => {
        // const filterQuery = request?.filterModel?.filter;  // this line is causing issue where the filter being send to the server is just the field value and not the whole object
        const filterQuery = filter;
        const sortModel = request?.sortModel ?? [];
        const sort = sortModel[0]?.colId;
        const sortOrder = sortModel[0]?.sort;
        const requestedRows = await fetchMethod({
          filterQuery,
          vertical,
          sort,
          sortOrder,
          offset: request.startRow,
          paginationPageSize: request.endRow - request.startRow,
          startTs,
          endTs,
          weightageValues,
        });

        return {
          success: true,
          rows: requestedRows?.data?.results ?? [],
          total: requestedRows?.data?.total,
        };
      },
    };
  }, [fetchMethod, filter, vertical, startTs, endTs, weightageValues]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        const response = await server.getData(params.request);

        if (response.success) {
          params.success({
            rowData: response.rows,
            rowCount: response.total,
          });
        } else {
          params.fail();
        }
      },
    };
  };

  useEffect(() => {
    if (disabled) return;

    const server = createServer();
    const dataSource = createServerSideDatasource(server);
    gridRef?.current?.api?.setGridOption("serverSideDatasource", dataSource);
  }, [disabled, createServer, gridRef?.current]);
};
