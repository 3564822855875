import { ExpandableSection } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { FC, useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { getTopVideos } from "../../api/request";
import { columnDefs } from "./gridColumnDefs";
import { defaultColDef } from "./gridDefaultColDef";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";

interface TopVideosProps {
  channelId: string;
}

const TopVideos: FC<TopVideosProps> = ({ channelId }) => {
  const { theme } = useGetGridTheme();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        const response = await getTopVideos({
          channelId,
          sort: { views: "desc" },
        });
        setVideos(response.data.data);
      } catch (error) {
        console.error("Failed to fetch videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [channelId, loading]);

  return (
    videos.length > 0 && (
      <ExpandableSection
        defaultExpanded
        disableContentPaddings
        variant="container"
        headerText="Top Videos by Views from the Channel"
      >
        <hr className="border-slate-600 border-t-[1px]" />
        <div className={`${theme} compact`}>
          <AgGridReact
            rowData={videos}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            domLayout="autoHeight"
            loadingOverlayComponent={() => (
              <CustomLoadingOverlay compact={true} />
            )}
            noRowsOverlayComponent={() => (
              <CustomLoadingOverlay compact={true} />
            )}
            suppressDragLeaveHidesColumns={true}
            columnDefs={columnDefs}
            suppressContextMenu={true}
          />
        </div>
      </ExpandableSection>
    )
  );
};

export default TopVideos;
