import { Popover } from '@cloudscape-design/components';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const CrosslineNotes = ({ chartRef, crosslines }) => {
  const animRequestRef = useRef(null);
  const animStateRef = useRef(null);

  const [ hoveredInfo, setHoveredInfo ] = useState({});

  const chartUpdate = () => {
    animRequestRef.current = requestAnimationFrame(chartUpdate);
    if (!chartRef?.current?.chart) return;
    const chart = chartRef.current.chart;
    const hoverRect = chart.hoverRect;
    const newChartNotes = [];

    const axes = chart.series
      .filter(s => s.visible)
      .map(s => s.axes.x)
      .filter(axis => axis?._crossLines.length > 0)
      .filter((axis, index, self) => index === self.findIndex(a => a.id === axis.id));

    const crossLines = axes
      .map(axis => axis._crossLines.filter(crossline => crossline.data.length > 0))
      .flat()
      .sort((a, b) => a.data[0] - b.data[0]);
    // TODO: Account for disabled series crosslines
  
    crossLines.forEach(crossline => {
      const xPos = crossline.data[0] + hoverRect.x;
      const yPos = hoverRect.y - 24;
      const noteData = {
        noteCount: 1,
        x: xPos,
        y: yPos,
        _minX: xPos,
        width: 20,
        height: 20,
        value: [crossline.value],
        color: crossline.stroke,
      };

      if (newChartNotes.length > 0) {
        const lastNote = newChartNotes[newChartNotes.length - 1];
        if (Math.abs(noteData.x - lastNote.x) < 16) {
          lastNote.noteCount += 1;
          lastNote.x = (noteData.x + lastNote._minX) / 2;
          lastNote.value.push(crossline.value);
          noteData._minX = lastNote._minX;
        } else {
          newChartNotes.push(noteData);
        }
      } else {
        newChartNotes.push(noteData);
      }
    });

    animStateRef.current = {
      ...animStateRef.current,
      chartNotes: newChartNotes,
    };
    const canvas = chartRef.current.chart.canvasElement;
    const ctx = canvas.getContext("2d");
    ctx.lineWidth = 2;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "bold 15px Open Sans";
    let hovering = false;
    newChartNotes.forEach(note => {
      ctx.strokeStyle = note.color;
      ctx.fillStyle = note.color;
      ctx.beginPath();
      ctx.clearRect(note.x - note.width / 2 - 2, note.y - 2, note.width + 4, note.height + 4);
      ctx.roundRect(note.x - note.width / 2, note.y, note.width, note.height, 5);
      ctx.stroke();
      if (note.noteCount === 1) {
        ctx.beginPath();
        ctx.arc(note.x, note.y + note.height / 2, 5, 0, 2 * Math.PI);
        ctx.fill();
      } else {
        ctx.fillText(note.noteCount, note.x, note.y + note.height / 2 + 1);
      }
      if (animStateRef.current.mousePos && 
        animStateRef.current.mousePos.x > note.x - note.width / 2 && 
        animStateRef.current.mousePos.x < note.x + note.width / 2 && 
        animStateRef.current.mousePos.y > note.y && 
        animStateRef.current.mousePos.y < note.y + note.height
      ) {
        animStateRef.current.lastHoveredNote = { ...note };
        hovering = true;
      }
    });

    if (hovering) {
      if (JSON.stringify(animStateRef.current.lastHoveredNote) !== JSON.stringify(animStateRef.current.prevLastHoveredNote)) {
        console.log(animStateRef.current.crosslineData)
        animStateRef.current.prevLastHoveredNote = { ...animStateRef.current.lastHoveredNote };
        const noteCrosslines = animStateRef.current.crosslineData.filter(crossline => animStateRef.current.lastHoveredNote.value.includes(crossline.value));
        const notes = [];
        [...new Set(noteCrosslines.map(crossline => crossline.value))].forEach(value => {
          const crosslinesAtValue = noteCrosslines.filter(crossline => crossline.value === value);
          notes.push({
            value,
            labels: crosslinesAtValue.map(crossline => crossline.label.text),
          });
        });
        setHoveredInfo({
          crosslines: noteCrosslines,
          x: animStateRef.current.lastHoveredNote.x - animStateRef.current.lastHoveredNote.width / 2,
          y: animStateRef.current.lastHoveredNote.y,
          width: animStateRef.current.lastHoveredNote.width,
          height: animStateRef.current.lastHoveredNote.height,
          notes: notes,
        });
      }
    } else {
      animStateRef.current.lastHoveredNote = null;
      animStateRef.current.prevLastHoveredNote = null;
      setHoveredInfo(null);
    }
  };

  const handleMouseMove = (e) => {
    if (!chartRef?.current?.chart) return;
    const chart = chartRef.current.chart;
    const rect = chart.canvasElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    animStateRef.current = {
      ...animStateRef.current,
      mousePos: { x, y },
    };
  };

  useEffect(() => {
    animRequestRef.current = requestAnimationFrame(chartUpdate);
    chartRef.current.chart.canvasElement.addEventListener("mousemove", handleMouseMove);
    return () => {
      cancelAnimationFrame(animRequestRef.current);
      chartRef?.current?.chart?.canvasElement?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [chartRef?.current]);

  useEffect(() => {
    if (animStateRef.current) {
      animStateRef.current.crosslineData = crosslines;
    }
  }, [crosslines, animStateRef.current]);

  return (
    <div
      className="absolute top-0 left-0 w-full h-full pointer-events-none"
    >
      {hoveredInfo && (
        <div 
          style={{ 
            position: "absolute", 
            top: hoveredInfo.y, 
            left: hoveredInfo.x, 
            zIndex: 1000, 
            width: hoveredInfo.width, 
            height: hoveredInfo.height 
          }} 
          className="pointer-events-auto cursor-pointer"
        >
          <Popover
            dismissButton={false}
            position={hoveredInfo?.notes?.map(note => note.labels.length)?.reduce((a, b) => a + b, 0) > 5 ? "bottom" : "top"}
            size="large"
            triggerType="custom"
            content={
              <div className="flex flex-col space-y-4">
                {hoveredInfo?.notes?.map(note => (
                  <div key={note.value} className="">
                    <div><b>{moment.utc(note.value).format("LL")}</b></div>
                    <ul className="list-disc list-inside">
                      {note.labels.map(label => (
                        <li key={label}>{label}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            }
          >
            <div style={{ width: 20, height: 20 }} className="pointer-events-auto cursor-pointer"></div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default CrosslineNotes;