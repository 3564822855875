import React, { useState, useMemo, useEffect, createContext } from "react";
import moment from "moment";
import _ from "lodash";
import { 
  Container, 
  SpaceBetween, 
  Spinner,
  Tabs,
} from "@cloudscape-design/components";
import { useSelector } from "react-redux";
import { usePostGenericPreference } from "../../services/generic/hooks/usePostGenericPreference";
import { generateNestedObject, getNestedObjectValue } from "../../utils/helpers";
import { isSome } from "../../utils/sugarUtils";
import TimeseriesChart from "./components/TimeseriesChart/TimeseriesChart";
import MetricsTable from "./components/MetricsTable/MetricsTable";
import { getTimeseriesForMetrics } from "./utils";
import { DEFAULT_PREFERENCES, DEFAULT_OPTIONS, PREFERENCE_PATH, DEFAULT_DATE_RANGE, DEFAULT_RESPONSE_STATE } from "./constants";
import Parameters from "./components/Parameters/Parameters";
import RadialChart from "./components/RadialChart/RadialChart";
import { useGetComparisonData } from "./api/hooks/useGetComparisonData";

export const CompareContext = createContext();

const DatapointComparison = ({ 
  metrics, 
  defaults, 
  onParametersChanged,
  options={},
}) => {

  const [ selectedMetrics, setSelectedMetrics ] = useState(defaults?.metricKeys?.length > 0 ? metrics.filter(metric => defaults.metricKeys.includes(metric.key)) : []);
  const [ selectedTitles, setSelectedTitles ] = useState(defaults?.parameters?.length > 0 ? defaults?.parameters?.map(p => []) : [[]]);
  const [ dateRange, setDateRange ] = useState(defaults?.parameters?.length > 0 ? defaults.parameters?.map(p => (p.dateRange ? { type: "absolute", ...p.dateRange } : DEFAULT_DATE_RANGE)) : [DEFAULT_DATE_RANGE]);
  const [ metricsTimeseriesData, setMetricsTimeseriesData ] = useState([]);
  const [ metricsTableData, setMetricsTableData ] = useState([]);
  const [ crosslineData, setCrosslineData ] = useState([]);
  const [ activeMetricKey, setActiveMetricKey ] = useState(defaults?.activeMetricKey ?? null);
  const [ prevFullDateRange, setPrevFullDateRange ] = useState(DEFAULT_DATE_RANGE);
  const [ prevSelectedMetrics, setPrevSelectedMetrics ] = useState([]);
  const [ prevAllSelectedTitles, setPrevAllSelectedTitles ] = useState([]);
  const [ prevProps, setPrevProps ] = useState({});

  const globalOptions = useMemo(() => (_.merge({}, DEFAULT_OPTIONS, options)), [options]);
  const allSelectedTitles = useMemo(() => selectedTitles.flat(), [selectedTitles]);

  const [ preferences, setPreferences ] = useState(DEFAULT_PREFERENCES);
  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const handlePreferenceChange = ({ relativePath, value }) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      [PREFERENCE_PATH, relativePath].join("."),
      value,
    );

    if (!_.isEqual(globalPref, newGlobalPreferences)) {
      postGenericPreference(newGlobalPreferences);
    }
  };

  const fullDateRange = useMemo(() => {
    let startTs = moment.utc(dateRange[0].startDate).unix();
    let endTs = moment.utc(dateRange[0].endDate).unix();
    if (dateRange.length > 1) {
      for (let i = 1; i < dateRange.length; i++) {
        startTs = Math.min(startTs, moment.utc(dateRange[i].startDate).unix());
        endTs = Math.max(endTs, moment.utc(dateRange[i].endDate).unix());
      }
    }
    return {
      ...DEFAULT_DATE_RANGE,
      startDate: moment.utc(startTs * 1000).format("YYYY-MM-DD"),
      endDate: moment.utc(endTs * 1000).format("YYYY-MM-DD"),
    };
  }, [dateRange]);

  const multiParamInfo = useMemo(() => {
    if (dateRange.length !== selectedTitles.length) {
      return [];
    }
    let minStartTs = moment.utc(dateRange[0].startDate).unix();
    if (dateRange.length > 1) {
      for (let i = 1; i < dateRange.length; i++) {
        minStartTs = Math.min(minStartTs, moment.utc(dateRange[i].startDate).unix());
      }
    }

    let titleDateOffsets = {};
    for (let i = 0; i < dateRange.length; i++) {
      const range = `${moment.utc(dateRange[i].startDate).format("YYYY-MM-DD")} - ${moment.utc(dateRange[i].endDate).format("YYYY-MM-DD")}`;
      if (!titleDateOffsets[range]) {
        titleDateOffsets[range] = {
          offset: (moment.utc(dateRange[i].startDate).unix() - minStartTs) * 1000,
          title_ids: [],
          start_ts: moment.utc(dateRange[i].startDate).unix() * 1000,
          end_ts: moment.utc(dateRange[i].endDate).unix() * 1000,
        };
      }
      titleDateOffsets[range].title_ids = [...titleDateOffsets[range].title_ids, ...selectedTitles[i].map(title => title.value)];
    }
    return Object.values(titleDateOffsets);
  }, [dateRange, selectedTitles]);

  const { mutate: postGenericPreference } = usePostGenericPreference();
  const { data: comparisonData, isLoading: comparisonDataLoading, } = useGetComparisonData({
    ipIds: [...new Set(allSelectedTitles.map(title => title.value))],
    datapoints: [...new Set(selectedMetrics.map(metric => metric.datapoints.map(datapoint => datapoint.platform.key)).flat())],
    startTs: moment.utc(fullDateRange.startDate).startOf("day").unix(),
    endTs: moment.utc(fullDateRange.endDate).startOf("day").unix(),
  });

  const processComparisonResponse = ({ responseData, metricsToCompare }) => {
    const timeseriesData = getTimeseriesForMetrics(metricsToCompare, responseData.data);
    setMetricsTimeseriesData(timeseriesData);

    const titleMetadata = responseData.metadata;
    const crosslines = [
      ...titleMetadata.map(title => (
        title.events?.map(event => (
          {
            value: moment.utc(event.date).unix() * 1000,
            label: event.label,
            ip_id: title.ip_id,
            ip_name: title.ip,
            type: event.type,
          }
        ))
      )).flat(),
    ];
    setCrosslineData(crosslines);

    const tableData = multiParamInfo.map((paramInfo, paramIndex) => (
      titleMetadata.filter(t => paramInfo.title_ids.includes(t.ip_id)).map(title => {
        return {
          ip_id: title.ip_id,
          param_index: paramIndex,
          ...metricsToCompare.map(metric => {
            const metricId = metric.key;
            const timeseriesKey = `${title.ip_id}_${metricId}`;
            const timeseriesValues = timeseriesData.filter(d =>  d.timestamp >= paramInfo.start_ts && d.timestamp <= paramInfo.end_ts).map(d => d[timeseriesKey]).filter(v => v !== undefined);
            return {
              [metricId]: {
                sum: timeseriesValues.length > 0 ? timeseriesValues.reduce((a, b) => a + b, 0) : null,
                average: timeseriesValues.length > 0 ? timeseriesValues.reduce((a, b) => a + b, 0) / timeseriesValues.length : null,
                max: timeseriesValues.length > 0 ? Math.max(...timeseriesValues) : null,
              },
            };
          }).reduce((acc, val) => ({ ...acc, ...val }), {}),
        };
      })
    )).flat();
    setMetricsTableData(tableData);
  };

  const handleOnParametersChanged = () => {
    if (!_.isFunction(onParametersChanged)) {
      return;
    }
    onParametersChanged({
      detail: {
        parameters: dateRange.map((range, index) => ({
          titleIds: selectedTitles[index].map(title => title.value),
          dateRange: {
            startDate: range.startDate,
            endDate: range.endDate,
          },
        })),
        metricKeys: selectedMetrics.map(metric => metric.key),
        activeMetricKey,
      }
    });
  };

  useEffect(() => {
    if (selectedMetrics.length > 0 && allSelectedTitles.length > 0) {
      const dateRangeChanged = (fullDateRange.startDate !== prevFullDateRange.startDate || fullDateRange.endDate !== prevFullDateRange.endDate);
      const selectedTitlesChanged = (allSelectedTitles.length !== prevAllSelectedTitles.length || allSelectedTitles.some((title, index) => title.value !== prevAllSelectedTitles[index].value));
      const selectedMetricsChanged = (selectedMetrics.length !== prevSelectedMetrics.length || selectedMetrics.some((metric, index) => metric.key !== prevSelectedMetrics[index].key));
      if (dateRangeChanged || selectedTitlesChanged || selectedMetricsChanged) {
        setPrevFullDateRange(fullDateRange);
        setPrevAllSelectedTitles(allSelectedTitles);
        setPrevSelectedMetrics(selectedMetrics);
      } else {
        return;
      }
    }
  }, [selectedMetrics, allSelectedTitles, fullDateRange]);

  useEffect(() => {
    if (comparisonData) {
      processComparisonResponse({ responseData: comparisonData, metricsToCompare: selectedMetrics });
    }
  }, [comparisonData, selectedMetrics]);

  /*useEffect(() => {
    const pref = getNestedObjectValue(globalPref, PREFERENCE_PATH);

    const newPreferences = isSome(pref) ? pref : DEFAULT_PREFERENCES;
    setPreferences(newPreferences);
  }, [globalPref]);*/

  useEffect(() => {
    handleOnParametersChanged();
  }, [selectedMetrics, selectedTitles, dateRange, activeMetricKey]);

  useEffect(() => {
    const newProps = {
      defaults,
      metrics,
    };
    if (_.isEqual(newProps, prevProps)) {
      return;
    }
    setPrevProps(newProps);
    let newSelectedMetrics = selectedMetrics;
    if (defaults?.metricKeys) {
      newSelectedMetrics = metrics.filter(metric => defaults.metricKeys.includes(metric.key));
      setSelectedMetrics(newSelectedMetrics);
    }
    let newDateRange = dateRange;
    if (defaults?.parameters) {
      newDateRange = defaults.parameters.length > 0 ? defaults.parameters.map(p => (p.dateRange ? { type: "absolute", ...p.dateRange } : DEFAULT_DATE_RANGE)) : [DEFAULT_DATE_RANGE];
      setDateRange(newDateRange);
    }
    let newActiveMetricKey = activeMetricKey;
    if (defaults?.activeMetricKey) {
      newActiveMetricKey = defaults.activeMetricKey;
      setActiveMetricKey(newActiveMetricKey);
    }
    if (comparisonData) {
      const newNewSelectedMetrics = metrics.filter(metric => newSelectedMetrics.map(m => m.key).includes(metric.key));
      processComparisonResponse({ responseData: comparisonData, metricsToCompare: newNewSelectedMetrics });
      setPrevSelectedMetrics(selectedMetrics);
      setSelectedMetrics(newNewSelectedMetrics);
    }
  }, [defaults, metrics]);

  return (
    <CompareContext.Provider value={globalOptions}>
      <SpaceBetween direction="vertical" size="l">
        <Container
          disableContentPaddings={globalOptions.container.disablePadding}
          disableHeaderPaddings={globalOptions.container.disablePadding}
          variant={globalOptions.container.disableBorder ? "full-page" : "default"}
        >
          <div className="flex flex-col gap-y-4">
            <div className={globalOptions.parameters.enabled ? "" : "hidden"}>
              <Parameters
                defaults={defaults}
                metrics={metrics}
                editable={globalOptions.parameters.editable}
                dateRanges={dateRange}
                setDateRanges={setDateRange}
                selectedMetrics={selectedMetrics}
                setSelectedMetrics={setSelectedMetrics}
                selectedTitles={selectedTitles}
                setSelectedTitles={setSelectedTitles}
                activeMetricKey={activeMetricKey}
                setActiveMetricKey={setActiveMetricKey}
              />
            </div>
            <Tabs
              onChange={({ detail }) => setActiveMetricKey(detail.activeTabId)}
              activeTabId={activeMetricKey}
              tabs={[
                ...(globalOptions.summary.enabled ? [{ label: "Summary", id: "summary" }] : []),
                ...selectedMetrics.map(metric => ({ label: metric.name, id: metric.key })),
              ]}
              disableContentPaddings
            />
            {preferences && (
              comparisonDataLoading ? (
                <div className={`flex flex-col h-[200px] justify-center items-center text-slate-400`}>
                  <Spinner size="large" />
                </div> 
              ) : metricsTimeseriesData?.length == 0 || selectedMetrics?.length === 0 || allSelectedTitles.length === 0 ? (
                <div className={`flex flex-col h-[200px] justify-center items-center text-slate-400`}>
                  {selectedMetrics?.length === 0 ? "Select metrics to compare" : allSelectedTitles.length === 0 ? "Select titles to compare" : "No data available"}
                </div> 
              ) : (
                <div>
                  {globalOptions.chart.enabled && (
                    activeMetricKey === "summary" ? (
                      <RadialChart
                        metrics={selectedMetrics}
                        titles={allSelectedTitles}
                        timeseriesData={metricsTimeseriesData}
                        multiParamInfo={multiParamInfo}
                      />
                    ) : (
                      <TimeseriesChart
                        preferences={preferences}
                        metrics={selectedMetrics.filter(metric => activeMetricKey === metric.key)}
                        titles={allSelectedTitles}
                        timeseriesData={metricsTimeseriesData}
                        crosslineData={crosslineData}
                        multiParamInfo={multiParamInfo}
                        unitSuffix={selectedMetrics.find(metric => activeMetricKey === metric.key)?.normalize ? "" : ""}
                        reverseYAxis={selectedMetrics.find(metric => activeMetricKey === metric.key)?.reverseYAxis}
                      />
                    )
                  )}
                  {globalOptions.table.enabled && (
                    <MetricsTable
                      metrics={selectedMetrics}
                      titles={allSelectedTitles}
                      metricData={metricsTableData}
                      multiParamInfo={multiParamInfo}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </Container>
      </SpaceBetween>
    </CompareContext.Provider>
  );

};

export default DatapointComparison;