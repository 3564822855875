import React from "react";
import moment from "moment";
import { Box, ButtonDropdown } from "@cloudscape-design/components";

const TableOptions = ({
  tableRef,
  metrics,
}) => {

  return (
    <Box float="right">
      <ButtonDropdown
        items={[
          { text: "Table data as CSV", id: "download_data_csv" },
          { text: "Table data as XLSX", id: "download_data_xlsx" },
        ]}
        onItemClick={({ detail }) => {
          switch (detail.id) {
            case "download_data_csv":
              tableRef.current.api.exportDataAsCsv({
                fileName: `compare_${moment
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss")}`,
                columnKeys: [
                  "title",
                  ...metrics
                    .map((metric) => [
                      `${metric.key}_average`,
                      `${metric.key}_max`,
                      `${metric.key}_sum`,
                    ])
                    .flat(),
                ],
              });
              break;
            case "download_data_xlsx":
              tableRef.current.api.exportDataAsExcel({
                fileName: `compare_${moment
                  .utc()
                  .format("YYYY-MM-DDTHH:mm:ss")}`,
                columnKeys: [
                  "title",
                  ...metrics
                    .map((metric) => [
                      `${metric.key}_average`,
                      `${metric.key}_max`,
                      `${metric.key}_sum`,
                    ])
                    .flat(),
                ],
              });
              break;
          }
        }}
      >
        Download
      </ButtonDropdown>
    </Box>
  );
};

export default TableOptions;