import {
  useGetChartTheme,
  CHART_TYPES,
} from "../../hooks/UseTheme/useGetChartTheme";

export const useItemChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, {
    overrides: {
      line: {
        series: {
          highlightStyle: {
            series: {
              dimOpacity: 0.2,
              strokeWidth: 2,
            },
          },
        },
      },
      bar: {
        series: {
          highlightStyle: {
            series: {
              dimOpacity: 0.2,
              strokeWidth: 2,
            },
          },
        },
      },
    },
  });

  return {
    trackedWikiTrendsChartOption: getTrackedWikiTrendsChartOption(theme),
    baseTwitchChartOptions: getBaseTwitchChartOptions(theme),
    baseSteamChartOptions: getBaseSteamChartOptions(theme),
    trackedFandomTrendsChartOption: getTrackedFandomTrendsChartOption(theme),
    trackedRedditTrendsChartOption: getTrackedRedditTrendsChartOption(theme),
    trackedInstagramTrendsChartOption:
      getTrackedInstagramTrendsChartOption(theme),
    trackedSteamTrendsChartOption: getTrackedSteamTrendsChartOption(theme),
    trackedSteamReviewTrendsChartOption:
      getTrackedSteamReviewTrendsChartOption(theme),
    trackedTwitchTrendsChartOption: getTrackedTwitchTrendsChartOption(theme),
    trackedDiscordTrendsChartOption:
      getTrackedSteamReviewTrendsChartOption(theme),
    timelineTheme,
    timelineClasses,
  };
};

export const getTrackedWikiTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  series: [
    {
      type: "bar",
      xKey: "timestamp",
      xName: "Date",
      yKey: "views_user",
      yName: "Total",
      tooltip: {
        renderer: (params) => {
          return {
            title: "Total Views",
            content: `${
              params.datum.timestamp
            } : ${params.datum.views_user.toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        fontFamily: "Open Sans",
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      min: 0,
      interval: {
        minSpacing: 10,
      },
      keys: ["views_user"],
    },
  ],
  padding: {
    right: 0,
  },
});

const timelineTheme = {
  cardBgColor: "#0F1B2A",
  cardDetailsBackGround: "#000716",
  cardDetailsColor: "#ffffff",
  cardMediaBgColor: "#2f2f2f",
  cardSubtitleColor: "#94a3b8",
  cardTitleColor: "#539fe5",
  detailsColor: "#94a3b8",
  iconBackgroundColor: "#539fe5",
  nestedCardBgColor: "#333333",
  nestedCardDetailsBackGround: "#333333",
  nestedCardDetailsColor: "#94a3b8",
  nestedCardSubtitleColor: "#94a3b8",
  nestedCardTitleColor: "#94a3b8",
  primary: "#539fe5",
  secondary: "#ffffff",
  titleColor: "#539fe5",
  titleColorActive: "#0F1B2A",
};

const timelineClasses = {
  card: "timeline-card",
  cardMedia: "timeline-card-media",
  cardSubTitle: "timeline-card-subtitle",
  cardText: "timeline-card-text",
  cardTitle: "timeline-card-title",
  controls: "timeline-controls",
  title: "timeline-title",
};

export const getBaseTwitchChartOptions = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "time",
      yKey: "average_viewers",
      yName: "Average Viewers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "peak_viewers",
      yName: "Peak Viewers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "average_streamers",
      yName: "Average Streamers",
      marker: { enabled: false },
      //stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_streamers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "time",
      yKey: "peak_streamers",
      yName: "Peak Streamers",
      marker: { enabled: false },
      stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_streamers,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getBaseSteamChartOptions = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "time",
      yKey: "ccu",
      yName: "Concurrent Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.time * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Steam Concurrent Users",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.ccu,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
        avoidCollisions: true,
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
      keys: ["ccu"],
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getTrackedFandomTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "usersGrowth",
      yName: "Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "User Growth in %",
            content: `${
              params.datum.timestamp
            } : ${params.datum.usersGrowth.toFixed(2)}%`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "pagesGrowth",
      yName: "Pages",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "Pages Growth in %",
            content: `${
              params.datum.timestamp
            } : ${params.datum.pagesGrowth.toFixed(2)}%`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "articlesGrowth",
      yName: "Articles",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "Articles Growth in %",
            content: `${
              params.datum.timestamp
            } : ${params.datum.articlesGrowth.toFixed(2)}%`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      crosshair: { enabled: true },
      label: {
        fontFamily: "Open Sans",
      },
    },
    {
      type: "number",
      position: "left",
      title: {
        text: "Pages & Articles Growth %",
        fontSize: 10,
        color: "rgb(100 116 139)",
      },
      crosshair: { enabled: true },
      label: {
        formatter: (params) => `${params.value}%`,
      },
      min: 0,
      interval: {
        minSpacing: 10,
        maxSpacing: 100,
      },
      keys: ["pagesGrowth", "articlesGrowth"],
    },
    {
      type: "number",
      position: "right",
      crosshair: { enabled: true },
      label: {
        formatter: (params) => `${params.value}%`,
      },
      min: 0,
      interval: {
        minSpacing: 10,
        maxSpacing: 100,
      },
      keys: ["usersGrowth"],
      title: {
        text: "User Growth %",
        fontSize: 10,
        color: "rgb(100 116 139)",
      },
    },
  ],
  padding: {
    right: 0,
  },
});

export const getTrackedRedditTrendsChartOption = (theme) => ({
  theme: theme,
  legend: {
    position: "right",
    spacing: 8,
    item: {
      paddingY: 14,
      line: {
        strokeWidth: 12,
      },
    },
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "subscriberGrowth",
      yName: "Subscribers",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "New Subscribers",
            content: `${params.datum.timestamp} : ${params.datum.subscriberGrowth}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "comments",
      yName: "Comments",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "New Comments",
            content: `${params.datum.timestamp} : ${params.datum.comments}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "activeUsers",
      yName: "Active Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "Active Users",
            content: `${params.datum.timestamp} : ${parseInt(
              params.datum.activeUsers,
            )}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        format: "%b %-d, %Y",
        fontSize: 10,
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
    },
    {
      type: "log",
      position: "left",
      label: {
        format: "%b %-d, %Y",
        fontSize: 10,
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
      keys: ["subscriberGrowth", "activeUsers"],
      title: {
        text: "Active Users & Subscribers",
        fontSize: 10,
        color: "rgb(100 116 139)",
      },
    },
    {
      type: "log",
      position: "right",
      label: {
        format: "%b %-d, %Y",
        fontSize: 10,
      },
      crosshair: {
        label: {
          format: "%b %-d, %Y",
        },
      },
      keys: ["comments"],
      title: {
        text: "New Comments",
        fontSize: 10,
        color: "rgb(100 116 139)",
      },
    },
  ],
  padding: {
    right: 0,
  },
});

export const getTrackedInstagramTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "newPosts",
      yName: "Posts",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: "New Posts",
            content: `${params.datum.timestamp} : ${params.datum.newPosts}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      crosshair: { enabled: true },
      label: {
        format: "%d %b, %Y",
      },
    },
    {
      type: "number",
      position: "left",
      crosshair: { enabled: true },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      min: 0,
      interval: {
        minSpacing: 10,
      },
      keys: ["newPosts"],
      title: {
        text: "New Posts",
        fontSize: 10,
        color: "rgb(100 116 139)",
      },
    },
  ],
  padding: {
    right: 0,
  },
});

export const getTrackedSteamTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "player_count_avg",
      yName: "Average Concurrent Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Concurrent Users",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.player_count_avg,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "player_count_peak",
      yName: "Peak Concurrent Users",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Concurrent Users",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.player_count_peak,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getTrackedSteamReviewTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  series: [
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "positive_reviews_monthly",
      yName: "Positive Reviews",
      marker: { enabled: false },
      stacked: true,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "long",
            year: "numeric",
            timeZone: "UTC",
          });
          return {
            title: "Positive Reviews",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.positive_reviews_monthly,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "negative_reviews_monthly",
      yName: "Negative Reviews",
      marker: { enabled: false },
      stacked: true,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "long",
            year: "numeric",
            timeZone: "UTC",
          });
          return {
            title: "Negative Reviews",
            content: `${dateFormatter.format(date)}: ${Math.round(
              Math.abs(params.datum.negative_reviews_monthly),
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "positive_reviews",
      yName: "Positive Reviews",
      marker: { enabled: false },
      stacked: true,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            timeZone: "UTC",
          });
          return {
            title: "Positive Reviews",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.positive_reviews,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "negative_reviews",
      yName: "Negative Reviews",
      marker: { enabled: false },
      stacked: true,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            timeZone: "UTC",
          });
          return {
            title: "Negative Reviews",
            content: `${dateFormatter.format(date)}: ${Math.round(
              Math.abs(params.datum.negative_reviews),
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "number",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            year: "numeric",
            timeZone: "UTC",
          });
          return dateFormatter.format(date);
        },
        avoidCollisions: true,
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
      nice: false,
    },
    {
      type: "number",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            timeZone: "UTC",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
      nice: false,
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(Math.abs(params.value)),
      },
      interval: {
        minSpacing: 20,
      },
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getTrackedTwitchTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: true,
    position: "right",
  },
  series: [
    {
      type: "line",
      xKey: "timestamp",
      yKey: "average_viewers",
      yName: "Average Viewers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "peak_viewers",
      yName: "Peak Viewers",
      marker: { enabled: false },
      stroke: "#ffa03a",
      fill: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Viewers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_viewers,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "average_broadcasters",
      yName: "Average Streamers",
      marker: { enabled: false },
      //fill: "#5090dc",
      //stroke: "#2d588a",
      //strokeWidth: 2,
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Average Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.average_broadcasters,
            ).toLocaleString()}`,
          };
        },
      },
    },
    {
      type: "line",
      xKey: "timestamp",
      yKey: "peak_broadcasters",
      yName: "Peak Streamers",
      marker: { enabled: false },
      stroke: "#ffa03a",
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "Peak Twitch Streamers",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.peak_broadcasters,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});

export const getTrackedDiscordTrendsChartOption = (theme) => ({
  theme: theme,
  zoom: {
    enabled: true,
  },
  legend: {
    enabled: false,
    position: "right",
  },
  series: [
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "new_members",
      yName: "New Members",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "New Members",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.new_members,
            ).toLocaleString()} (${
              (params.datum.new_members_change >= 0 ? "+" : "") +
              params.datum.new_members_change.toLocaleString()
            }%)`,
          };
        },
      },
    },
    {
      type: "bar",
      xKey: "timestamp",
      yKey: "new_premium_subs",
      yName: "New Premium Members",
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          const date = new Date(params.datum.timestamp * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            title: "New Premium Members",
            content: `${dateFormatter.format(date)}: ${Math.round(
              params.datum.new_premium_subs,
            ).toLocaleString()}`,
          };
        },
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "bottom",
      label: {
        formatter: (params) => {
          const date = new Date(params.value * 1000);
          const dateFormatter = new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return dateFormatter.format(date);
        },
      },
      crossLines: [
        {
          type: "line",
          value: null,
          stroke: "rgb(100 116 139)",
          label: {
            text: "Release Date",
            position: "top",
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "rgb(100 116 139)",
          },
        },
      ],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      min: 0,
      keys: ["online_avg", "online_peak"],
    },
    {
      type: "number",
      position: "left",
      gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
      label: {
        formatter: (params) =>
          Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
          }).format(params.value),
      },
      interval: {
        minSpacing: 20,
      },
      keys: ["new_members", "new_premium_subs"],
    },
  ],
  padding: {
    right: 0,
  },
  data: [],
});
