import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";
import React from "react";

const ProdnDistrib = ({ distributors }) => {
  // Group distributors by format
  const distributorsByFormat = distributors.reduce((acc, distributor) => {
    distributor.formats.forEach((format) => {
      if (!acc[format]) acc[format] = [];
      acc[format].push(distributor);
    });
    return acc;
  }, {});

  // Sort groups by priority
  const formatPriority = ["Theatrical", "DVD", "Blu-ray", "TV"];
  const sortedFormats = Object.keys(distributorsByFormat).sort(
    (a, b) => formatPriority.indexOf(a) - formatPriority.indexOf(b),
  );

  if (sortedFormats.length === 0)
    return (
      <div className="text-center text-gray-500 my-16">No data available</div>
    );

  return (
    <SpaceBetween size="l" direction="vertical">
      {sortedFormats.map((format) => (
        <Container
          key={format}
          header={<Header variant="h4">{format.toUpperCase()}</Header>}
        >
          <ColumnLayout columns={3} borders="vertical" variant="text-grid">
            {distributorsByFormat[format]
              .sort((a, b) => a.startYear - b.startYear)
              .map((distributor, index) => (
                <Box key={index} padding="s">
                  <Link href="#">{distributor.name}</Link>
                  <TextContent>{`${distributor.startYear} - ${distributor.endYear}`}</TextContent>
                </Box>
              ))}
          </ColumnLayout>
        </Container>
      ))}
    </SpaceBetween>
  );
};

export default ProdnDistrib;
