import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { Link } from "@cloudscape-design/components";
import shortenInteger from "../../../../../utils/shortenInteger";

const numberCellRenderer = (params) => {
    return (
      <div className="h-full flex items-center">
       {params.value}
      </div>
    );
  };

// Flatten the data structure for AG Grid
const flattenData = (data) => {
    return data.flatMap((distributorData) =>
        distributorData.movies.map((movie) => ({
            distributor: distributorData.distributor,
            title: movie.ip,
            ip_id: movie.ip_id,
            release_date: movie.release_date,
            budget: movie.Budget,
            domestic: movie.Domestic,
            worldwide: movie.Worldwide,
            international: movie.International,
        }))
    );
};

// // Custom Sum Aggregation Function
// const customSumAggFunc = (params) => {
//     let sum = 0;
//     params.values.forEach((value) => {
//         if (value != null) sum += value;
//     });
//     return sum;
// };

export const BoStudioMovieGrid = ({ data, isLoading, error , filterText}) => {
    const { theme } = useGetGridTheme();
    const defaultColDef = {
        sortable: true,
        filter: false,
        resizable: true,
        suppressHeaderMenuButton: true,
    };

    const columnDefs = [
        { field: "distributor", rowGroup: true, hide: true },
        {
            headerName: "Distributor",
            cellRenderer: "agGroupCellRenderer",
            showRowGroup: "distributor",
            minWidth: 100,
        },
        {
            headerName: "Title",
            field: "title",
            minWidth: 200,
            flex: 1,
            cellRenderer: (params) => {
                const ipId = params.data?.ip_id;
                const destination = `/item/${ipId}`;
                return (
                    <Link variant="secondary" href={destination} target="_blank" onClick={(e) => !ipId && e.preventDefault()}>
                        {params.value}
                    </Link>
                );
            }
        },
        {
            headerName: "Release Date",
            field: "release_date",
            maxWidth: 150,
        },
        {
            headerName: "Budget",
            field: "budget",
            cellRenderer: (params) => shortenInteger(params.value),
            maxWidth: 150,
            aggFunc: "sum",
        },
        {
            headerName: "Domestic",
            field: "domestic",
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",
            
        },
        {
            headerName: "International",
            field: "international",
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",
        },
        {
            headerName: "Worldwide",
            field: "worldwide",
            sort: 'desc',
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",
        },
    ];

    if (isLoading) {
        return (
            <div className="flex flex-row justify-center h-160">
                <CustomLoadingOverlay />
            </div>
        );
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    const rowData = flattenData(data);

    return (
        <div className={theme} style={{ height: 925, width: "100%" }}>
            <AgGridReact
                defaultColDef={defaultColDef}
                quickFilterText={filterText}
                pagination={true}
                paginationPageSize={20}
                columnDefs={columnDefs}
                rowData={rowData}
                groupDisplayType={"custom"}
                groupHideOpenParents={false}
                suppressAggFuncInHeader={true}
            />
        </div>
    );
};

export default BoStudioMovieGrid;
