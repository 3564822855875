export const ID_TYPES = {
  IMDB: "IMDB",
  TMDB_IGDB: "TMDB_IGDB",
};

export const checkIdType = (id: string) => {
  const regexImdb = /^tt\d+$/; // starts with 'tt' and then numbers
  const regexTmdb = /\d/; // contains only numbers

  if (regexImdb.test(id)) return ID_TYPES.IMDB;
  if (regexTmdb.test(id)) return ID_TYPES.TMDB_IGDB;

  return null;
};
