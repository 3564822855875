import { Button, Container, Input } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import DateRangeSelector from "../../raw-data/header/DateRangeSelector";

export const BasicParameters = ({
  dateRange,
  setDateRange,
  titleSearchQuery,
  setTitleSearchQuery,
  additionalFilters = null,
  showAdvancedFilter = true,
  loading,
}) => {
  const [searchQuery, setSearchQuery] = useState(titleSearchQuery);

  useEffect(() => {
    setSearchQuery(titleSearchQuery);
  }, [titleSearchQuery]);

  return (
    <Container variant="borderless" disableContentPaddings>
      <div className="flex flex-col gap-y-4">
        <div className="flex items-center justify-between flex-wrap gap-y-4">
          <div className="flex space-x-2 items-center">
            <div className="flex space-x-2 items-center">
              <Input
                type="search"
                value={searchQuery}
                onChange={({ detail }) => {
                  setSearchQuery(detail.value);
                  if (detail.value === "") {
                    setTitleSearchQuery("");
                  }
                }}
                onKeyDown={({ detail }) => {
                  if (detail.key === "Enter") {
                    setTitleSearchQuery(searchQuery);
                  }
                }}
                placeholder="Search for a title"
              />
              <Button
                iconName="search"
                loading={loading}
                onClick={() => setTitleSearchQuery(searchQuery)}
              />
            </div>
          </div>
          <div className="flex gap-x-2 items-center flex-wrap gap-y-4">
            {additionalFilters}
            <DateRangeSelector
              defaults={dateRange}
              onChange={setDateRange}
              isDateEnabled={(date) => date <= new Date()}
            />
          </div>
        </div>
        {showAdvancedFilter && (
          <div
            id="advancedFilterParent"
            className="[&:not(:has(div))]:hidden"
          ></div>
        )}
      </div>
    </Container>
  );
};
