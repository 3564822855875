import { gamesLastWeek } from "../components/widgets/tiles/games-last-week";
import { gamesUpcoming } from "../components/widgets/tiles/games-upcoming";
import { imdbGamesEngagement } from "../components/widgets/tiles/imdb-games-engagement";
import { imdbMoviesEngagement } from "../components/widgets/tiles/imdb-movies-engagement";
import { imdbSeriesEngagement } from "../components/widgets/tiles/imdb-series-engagement";
import { ipAdded } from "../components/widgets/tiles/ip_added";
import { movieLastWeek } from "../components/widgets/tiles/movies-last-week";
import { movieUpcoming } from "../components/widgets/tiles/movies-upcoming";
import { piracyMovies } from "../components/widgets/tiles/piracy-movies-trends";
import { piracySeries } from "../components/widgets/tiles/piracy-series-trends";
import { seriesLastWeek } from "../components/widgets/tiles/series-last-week";
import { seriesUpcoming } from "../components/widgets/tiles/series-upcoming";
import { steamTrends } from "../components/widgets/tiles/steam-trends";
import { twitchTrends } from "../components/widgets/tiles/twitch-trends";
import { wikiGames } from "../components/widgets/tiles/wiki-games-trends";
import { wikiMovie } from "../components/widgets/tiles/wiki-movies-trends";
import { wikiSeries } from "../components/widgets/tiles/wiki-series-trends";
import { youtubeGames } from "../components/widgets/tiles/youtube-games-trends";
import { youtubeMovies } from "../components/widgets/tiles/youtube-movies-trends";
import { youtubeSeries } from "../components/widgets/tiles/youtube-series-trends";

export const allWidgets = {
  imdbSeriesEngagement: imdbSeriesEngagement,
  imdbMoviesEngagement: imdbMoviesEngagement,
  imdbGamesEngagement: imdbGamesEngagement,
  wikiMovie: wikiMovie,
  wikiSeries: wikiSeries,
  wikiGames: wikiGames,
  twitchTrends: twitchTrends,
  steamTrends: steamTrends,
  youtubeSeries: youtubeSeries,
  youtubeGames: youtubeGames,
  youtubeMovies: youtubeMovies,
  piracyMovies: piracyMovies,
  piracySeries: piracySeries,
  
  movieLastWeek: movieLastWeek,
  seriesLastWeek: seriesLastWeek,
  gamesLastWeek: gamesLastWeek,
  movieUpcoming: movieUpcoming,
  seriesUpcoming: seriesUpcoming,
  gamesUpcoming: gamesUpcoming,
  ipAdded: ipAdded,

};

const defaultLayout = [];

function merge(src, overrides) {
  return src.map((item) => {
    const match = overrides.find((override) => override.id === item.id);
    return match ? { ...item, ...match } : item;
  });
}

export function getDefaultLayout(width) {
  if (width >= 2160) {
    // 6-col layout
    return merge(defaultLayout, [{ id: "widgetOne", columnOffset: { 6: 0 } }]);
  }
  if (width > 1045) {
    // 4-col layout with 4-col overview
    return defaultLayout;
  }
  if (width > 911) {
    // 4-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  if (width > 708) {
    // 2-col layout with 4-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 2 }]);
  }
  if (width > 687) {
    // 2-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  if (width > 485) {
    // 1-col layout with 2-col overview
    return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 3 }]);
  }
  // 1-col layout with 1-col overview
  return merge(defaultLayout, [{ id: "widgetOne", rowSpan: 5 }]);
}

export function exportLayout(items) {
  return items.map((item) => ({
    id: item.id,
    columnSpan: item.columnSpan,
    columnOffset: item.columnOffset,
    rowSpan: item.rowSpan,
  }));
}

export function getBoardWidgets(layout) {
  return layout.map((position) => {
    const widget = allWidgets[position.id];
    return {
      ...position,
      ...widget,
      columnSpan:
        position?.columnSpan ?? widget?.definition?.defaultColumnSpan ?? 1,
      rowSpan: position?.rowSpan ?? widget?.definition?.defaultRowSpan ?? 2,
    };
  });
}

export function getPaletteWidgets(layout) {
  return Object.entries(allWidgets)
    .filter(([id]) => !layout.find((position) => position.id === id))
    .map(([id, widget]) => ({ id, ...widget }));
}
