import {
  Button,
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AddFranchiseIcon from "../../../../assets/images/AddFranchiseIcon";
import CompareIcon from "../../../../assets/images/CompareIcon";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { formatDate } from "../../../../utils/dateFormatter";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import BoxOffice from "../../components/BoxOffice";
import CastAndCrewComponent from "../../components/CastAndCrewComponent";
import NewsBox from "../../components/News";
import OTTProvider from "../../components/OTTProvider";
import ProdnDistrib from "../../components/ProductionnDistribution";
import IMDbScore from "../../components/Scores/IMDbScore";
import RottenTomatesAudienceScore from "../../components/Scores/RTAudienceScore";
import RottenTomatesCriticScore from "../../components/Scores/RTCriticScore";
import { SCORE_TYPES, Score } from "../../components/Scores/Score";
import StreamingWindowComponent from "../../components/StreamingWindow";
import TagList from "../../components/TagList";
import TagsAndIDsComponent from "../../components/TagsAndIDsComponent";
import TrailerGrid from "../../components/Trailer/Trailer";
import WikiSummaryBox from "../../components/WikipediaTab";
import { AddToFranchiseModal } from "../../modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../../modals/AddYoutubeTrailerModal";
import AttributesEditModalV2 from "../../modals/AttributesEditModalV2";
import AuditModal from "../../modals/AuditModal";
import { CompareModal } from "../../modals/CompareModal/CompareModal";
import {
  DATA_COMPARE_METRICS,
  SCORE_COMPARE_METRICS,
} from "../../modals/CompareModal/metrics";
import { DeleteConfirmationModal } from "../../modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../../modals/TrackUnTrackConfirmationModal";
import { calculateStartDate } from "../../utils";
import { PlatformTrends } from "../components/PlatformTrends";
import { RankTrends } from "../components/RankTrends";
import { useGetPlatformData } from "../game/api/hooks/useGetPlatformData";
import { Awards } from "./components/Awards";

const defaultDateRange = {
  type: "absolute",
  startDate: moment().subtract(6, "months").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  parts.push(`${minutes}m`);
  return parts.join(" ");
};

export default function ItemFilm({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
  onAddFranchise,
  onTagUpdateSubmit,
  attributeProcessing,
  setPiracyDate = (e) => {},
  setRedditDate = (e) => {},
  setInstagramDate = (e) => {},
  ...props
}) {
  const { user } = useAuthContext();
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [isTracked, setIsTracked] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const [piracyDateRange, setPiracyDateRange] = useState(defaultDateRange);
  const [redditDateRange, setRedditDateRange] = useState(defaultDateRange);
  const [instagramDateRange, setInstagramDateRange] =
    useState(defaultDateRange);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);
  const onShowAuditInit = () => setShowAuditModal(true);
  const [showAuditModal, setShowAuditModal] = useState(false);

  const releaseDate = new Date(Date.parse(itemData.data.release_date));
  const compareMetrics = [
    ...SCORE_COMPARE_METRICS.ALL,
    ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ];

  useEffect(() => {
    setRedditDate(redditDateRange);
  }, [redditDateRange]);
  useEffect(() => {
    setInstagramDate(instagramDateRange);
  }, [instagramDateRange]);
  useEffect(() => {
    setPiracyDate(piracyDateRange);
  }, [piracyDateRange]);

  useEffect(() => {
    if (itemData.data && itemData.data.tracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData]);

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const rtCriticSummary = itemData?.data?.rtCriticSummary;
  const rtFanSummary = itemData?.data?.rtFanSummary;
  const newsBoxId = new Date(
    itemData?.data?.release_dates[0]?.release_date,
  ).getFullYear();

  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };

  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);

  const [youtubeStat, setYoutubeStat] = useState(null);
  const youtubeStats = (youtubeLatestDataPoint) => {
    setYoutubeStat(youtubeLatestDataPoint);
  };
  const [imdbStat, setImdbStat] = useState(null);
  const imdbStats = (imdbLatestDataPoint) => {
    setImdbStat(imdbLatestDataPoint);
  };

  const [dateRange, setDateRange] = useState({
    ...defaultDateRange,
    startDate: calculateStartDate(releaseDate),
  });

  const { data: youtubeTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "youtube",
    dateRange,
  });
  const { data: imdbTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "imdb",
    dateRange,
  });
  const { data: wikipediaTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "wiki",
    dateRange,
  });

  const [wikipediaStat, setWikipediaStat] = useState(null);
  const wikipediaStats = (wikipediaLatestDataPoint) => {
    setWikipediaStat(wikipediaLatestDataPoint);
  };

  return (
    <Container
      variant="borderless"
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconName="check"
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={
                  !isAdmin
                    ? true
                    : checkIpList(itemData?.data?.ip_list) ||
                      itemData?.data?.tracked
                }
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconName="close"
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={!isAdmin ? true : !itemData?.data?.tracked}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Tags"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Compare Titles"
                variant="normal"
                onClick={() => onShowCompareModalInit()}
                disabled={false}
                disabledReason="This feature is currently unavailable."
                iconSvg={<CompareIcon />}
              />
              <Button
                ariaLabel="Add to Franchise"
                variant="normal"
                onClick={() => onAddFranchiseModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to add to franchise."
                    : ""
                }
                iconSvg={<AddFranchiseIcon />}
              />
              <Button
                ariaLabel="Add Youtube Trailer"
                variant="normal"
                onClick={() => setShowAddYoutubeTrailerModal(true)}
                iconSvg={
                  <svg
                    fill="#000000"
                    width="800px"
                    height="800px"
                    viewBox="-2 -5 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMinYMin"
                    className="jam jam-youtube"
                  >
                    <path d="M15.812.017H4.145C1.855.017 0 1.852 0 4.116v5.768c0 2.264 1.856 4.1 4.145 4.1h11.667c2.29 0 4.145-1.836 4.145-4.1V4.116c0-2.264-1.856-4.1-4.145-4.1zM13.009 7.28L7.552 9.855a.219.219 0 0 1-.314-.196V4.35c0-.161.173-.266.318-.193l5.458 2.735a.216.216 0 0 1-.005.389z" />
                  </svg>
                }
              />
              <Button
                ariaLabel="Audit Logs"
                iconName="security"
                variant="normal"
                onClick={() => {
                  console.log("audit logs");
                  onShowAuditInit();
                }}
              />

              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
            </SpaceBetween>
          }
        >
          <div className="flex items-center">
            <div className="text-xl font-bold inline-block">
              {itemData.data.name}
            </div>
            <div className="inline-block whitespace-nowrap ml-2">
              {isTracked ? (
                <div className="flex space-x-2">
                  {itemData.data?.ip_list?.length > 0 ? (
                    itemData.data.ip_list.map((ip) => (
                      <span
                        key={ip}
                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                      >
                        <svg
                          className="h-1.5 w-1.5 fill-green-400"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        {ip}
                      </span>
                    ))
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                      Tracked
                    </span>
                  )}
                </div>
              ) : (
                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700">
                  <svg
                    className="h-1.5 w-1.5 fill-slate-400"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Not tracked
                </span>
              )}
            </div>
          </div>

          <div className="text-slate-500 text-xs">
            {itemData.data?.type.name} {" • "}
            {itemData.data?.certificates?.rating} {" • "}
            {itemData.data?.original_language?.toUpperCase()} {" • "}
            {itemData.data?.countries
              ?.map((country) => country.toUpperCase())
              .join(", ")}{" "}
            {" • "}
            {formatDate(new Date(Date.parse(itemData?.data?.release_date)))}
            {" • "}
            {toHoursAndMinutes(itemData.data.runtime)} {" • "}
            {itemData.data.isTheatrical ? <>Theatrical</> : <>TV/OTT</>}
          </div>
        </Header>
      }
    >
      <Container>
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
            <div className="w-full rounded-md sm:w-auto">
              {itemData.data.image_url ? (
                <div className="w-64 h-auto">
                  <img
                    src={`https://image.tmdb.org/t/p/w342/${itemData.data.image_url}`}
                    alt="..."
                    className="w-64 drop-shadow-lg h-min rounded-md dark:border-slate-600 border-slate-400 border-solid border"
                  />
                </div>
              ) : (
                <div className="h-full w-60 flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div className="space-y-1">
                {/*itemData.data.summary && (
                  <div><b>Overview:</b>{" "}<ReadMore text={itemData.data.summary} maxLength={300} /></div>
                )*/}
                <TagList
                  title="Genre"
                  tagData={itemData.data.genre.map((genre) => ({
                    label: genre,
                    color: "purple",
                  }))}
                />
                {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                  <TagList
                    title="Tags"
                    tagData={itemData.data.cTags.map((cTags) => ({
                      label: cTags,
                      color: "green",
                    }))}
                  />
                )}
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                {itemData.data.production_company &&
                  itemData.data.production_company.length > 0 && (
                    <TagList
                      title="Production companies"
                      tagData={itemData.data.production_company.map(
                        (company) => ({
                          label: company.name,
                          color: "red",
                          href: `/item/companies-${company.id}`,
                        }),
                      )}
                    />
                  )}
                {itemData.data.providers && <OTTProvider itemData={itemData} />}
              </div>
              <div className="pt-4">
                <SpaceBetween direction="vertical" size="s">
                  <SpaceBetween direction="horizontal" size="xl">
                    {!!rtCriticSummary?.score && (
                      <RottenTomatesCriticScore
                        rtCriticSummary={rtCriticSummary}
                      />
                    )}
                    {!!rtFanSummary?.score && (
                      <RottenTomatesAudienceScore rtFanSummary={rtFanSummary} />
                    )}
                    {!!itemData.data.imdb_vote_average && (
                      <IMDbScore
                        score={itemData.data.imdb_vote_average}
                        votes={itemData.data.imdb_vote_count}
                        id={itemData.data.imdb_id}
                      />
                    )}
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <Score
                        type={SCORE_TYPES.PANTHEON}
                        rank={itemData.data.global_rank?.pantheon?.daily_rank}
                        weeklyRank={
                          itemData.data.global_rank?.pantheon?.weekly_rank
                        }
                      />
                    )}
                    {!!itemData.data.piracy_ranker && (
                      <Score
                        type={SCORE_TYPES.PIRACY}
                        rank={
                          itemData.data.piracy_ranker.vertical_rank_lifetime
                        }
                        rankType={"Movies"}
                        subRank={itemData.data.piracy_ranker.us_rank_lifetime}
                      />
                    )}
                    <Score
                      type={SCORE_TYPES.WIKIPEDIA}
                      rank={wikipediaStat?.dailyRank}
                      views={wikipediaStat?.allTimeRank}
                    />
                    <Score
                      type={SCORE_TYPES.YOUTUBE}
                      rank={youtubeStat?.dailyRank}
                      views={youtubeStat?.allTimeRank}
                    />
                    {itemData?.data?.imdb_id && (
                      <Score
                        type={SCORE_TYPES.IMDB}
                        rank={imdbStat?.dailyRank}
                        views={imdbStat?.allTimeRank}
                      />
                    )}
                  </SpaceBetween>
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-2 ">
        <div className="w-full ">
          <Tabs
            tabs={[
              {
                label: "Rank Trends",
                id: "rtrends",
                content: (
                  <RankTrends
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    wikipediaTrendsData={wikipediaTrendsData}
                    wikipediaStats={wikipediaStats}
                    youtubeTrendsData={youtubeTrendsData}
                    youtubeStats={youtubeStats}
                    imdbTrendsData={imdbTrendsData}
                    imdbStats={imdbStats}
                  />
                ),
              },
              {
                label: "Platform Trends",
                id: "advancedTrends",
                content: (
                  <PlatformTrends
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    itemData={itemData}
                    wikipediaTrendsData={wikipediaTrendsData}
                    wikipediaStats={wikipediaStats}
                    youtubeTrendsData={youtubeTrendsData}
                    youtubeStats={youtubeStats}
                    imdbTrendsData={imdbTrendsData}
                    imdbStats={imdbStats}
                    piracyTrends={props?.piracyTrends}
                    instagramHashtagsData={props.instagramHashtagsData}
                    trackedRedditTrendChartOption={
                      props.trackedRedditTrendChartOption
                    }
                  />
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data.wikidata_id}
                    summary={itemData.data.summary}
                  />
                ),
              },
              {
                label: "News",
                id: "news",
                content: (
                  <NewsBox
                    id={`${itemData?.data?.ip}+${newsBoxId}+${itemData?.data?.vertical}`}
                  />
                ),
              },
              {
                label: "Cast & Crew",
                id: "CCT",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="Cast & Crew"
                  >
                    <CastAndCrewComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "IDs",
                id: "tags",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="IDs"
                  >
                    <TagsAndIDsComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              ...(itemData.data.distributors &&
              itemData.data.distributors.length > 0
                ? [
                    {
                      label: "Distribution",
                      id: "PD",
                      content: (
                        <ProdnDistrib
                          distributors={itemData.data.distributors}
                        />
                      ),
                    },
                  ]
                : []),
              {
                label: "Box Office",
                id: "BO",
                content: (
                  <SpaceBetween direction="vertical" size="s">
                    <BoxOffice titleId={itemData.data.imdb_id} />
                  </SpaceBetween>
                ),
              },
              ...(itemData.data.awards && itemData.data.awards.length > 0
                ? [
                    {
                      label: "Awards",
                      id: "awds",
                      content: <Awards itemData={itemData} />,
                    },
                  ]
                : []),
              {
                label: "Streaming Window",
                id: "tl",
                content: (
                  <SpaceBetween direction="vertical" size="xl">
                    <StreamingWindowComponent
                      titleId={itemData.data.imdb_id}
                      vertical={itemData.data.vertical}
                    />
                  </SpaceBetween>
                ),
              },
            ]}
          />
        </div>
      </div>
      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onSubmit={(selectedOption) => {
          onAddFranchise({
            id: selectedOption.value,
            name: selectedOption.label,
            removed: [],
            added: [itemData?.data?.ip_id],
          });

          onAddFranchiseModalClose();
        }}
        onDiscard={onAddFranchiseModalClose}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModalV2
        visible={showAttrModal}
        itemData={itemData?.data}
        onSubmit={(data) => {
          onTagUpdateSubmit(data);
          setShowAttrModal(false);
        }}
        onDismiss={() => setShowAttrModal(false)}
        isLoading={attributeProcessing}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "twitch", "discord", "steam"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        metrics={compareMetrics}
        defaults={{
          metricKeys: [
            ...SCORE_COMPARE_METRICS.ALL,
            ...SCORE_COMPARE_METRICS.MOVIES_SERIES,
          ].map((x) => x.key),
          activeMetricKey: "summary",
          parameters: [
            {
              titleIds: [itemData?.data?.ip_id],
            },
          ],
        }}
      />
    </Container>
  );
}
