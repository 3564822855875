import React from "react";
import {
  ColumnLayout,
  Link,
  Box,
  SpaceBetween,
} from "@cloudscape-design/components";

const sections = [
  {
    title: "Cast",
    key: "cast",
    columns: 4,
  },
  {
    title: "Director",
    key: "director",
    columns: 3,
  },
  {
    title: "Writer",
    key: "writer",
    columns: 3,
  },
  {
    title: "Producer",
    key: "producer",
    columns: 3,
  },
];

const CastAndCrewComponent = ({ itemData }) => {
  const renderItemCategory = (
    title,
    items,
    columns = 1,
    groupByJob = false,
  ) => {
    const groupItemsByJob = (items) => {
      return items?.reduce((acc, item) => {
        const [, name, job] = item.split(":");
        const key = job || "";
        if (!acc[key]) acc[key] = [];
        acc[key].push(name + (job ? ":" + job : ""));
        return acc;
      }, {});
    };

    const chunkArray = (arr, size) =>
      Array.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size),
      );

    let itemChunks;
    if (groupByJob) {
      const groupedByJob = groupItemsByJob(items);
      const flattenedGroupedItems = Object.values(groupedByJob).flat();
      itemChunks =
        columns > 1
          ? chunkArray(
              flattenedGroupedItems,
              Math.ceil(flattenedGroupedItems?.length / columns),
            )
          : [flattenedGroupedItems];
    } else {
      itemChunks =
        columns > 1
          ? chunkArray(items, Math.ceil(items?.length / columns))
          : [items];
    }

    return (
      <div className="border-b border-gray-700 pb-8">
        <div className="mb-3">
          <Box variant="h3">{title}</Box>
        </div>
        {itemChunks.length === 0 && (
          <div className="text-center text-gray-500">No data available</div>
        )}
        {itemChunks.length > 0 && (
          <ColumnLayout columns={columns} variant="text-grid">
            {itemChunks.map((chunk, index) => (
              <div key={index}>
                {chunk.map((x) => {
                  const [, name, job] = x.split(":");
                  return (
                    <div key={x}>
                      <Link href={`#`} variant="primary">
                        {name}
                      </Link>
                      <span className="text-slate-500"> ({job})</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </ColumnLayout>
        )}
      </div>
    );
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      {sections.map(({ title, key, columns }) =>
        renderItemCategory(title, itemData[key], columns),
      )}
    </SpaceBetween>
  );
};

export default CastAndCrewComponent;
