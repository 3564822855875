import { dispatch } from "../../../../app/store";
import axiosInstance from "../../../../utils/axios";
import {
  fetchRealTimeMonthApi,
  fetchRealTimeMonthWithKeywordAndPropertyApi,
  fetchWikiRealTimeDataApi,
} from "../slices/general-trends-slice";
import { fetchInstagramHashtagsData } from "../slices/instagram-hashtags-slice";
import { fetchItemAuditDataApi } from "../slices/item-audit-slice";
import { fetchItemBoxOfficeDataApi } from "../slices/item-boxoffice-slice";
import { fetchItemChildrenData } from "../slices/item-children-slice";
import { fetchItemNewsDataApi } from "../slices/item-news-slice";
import { fetchItemData } from "../slices/item-slice";
import { fetchItemStreamingWindowDataApi } from "../slices/item-streamingwindow-slice";
import { fetchTrailersDataApi } from "../slices/item-trailer-slice";
import { fetchPiracyTrendsDataApi } from "../slices/piracy-trends-slice";
import { fetchSteamchartsData } from "../slices/steamcharts-slice";
import { fetchHashtagsData } from "../slices/tiktok-hashtags-slice";
import { fetchTrackedDiscordDataApi } from "../slices/tracked-discord-trends-slice";
import { fetchTrackedFandomDataApi } from "../slices/tracked-fandom-trends-slice";
import { fetchTrackedGoogleTrendsDataApi } from "../slices/tracked-google-trends-slice";
import { fetchTrackedInstagramDataApi } from "../slices/tracked-instagram-trends-slice";
import { fetchTrackedRedditDataApi } from "../slices/tracked-reddit-trends-slice";
// import { fetchTrackedSteamDataApi } from "../slices/tracked-steam-trends-slice";
import { fetchTrackedWikiRealTimeDataApi } from "../slices/tracked-trends-slice";
// import { fetchTrackedTwitchDataApi } from "../slices/tracked-twitch-trends-slice";
import { fetchTrackedYouTubeTrendsDataApi } from "../slices/tracked-youtube-trends-slice";
// import { fetchTwitchData } from "../slices/twitch-slice";

export async function getItemData(id, params) {
  await dispatch(fetchItemData({ id, params }));
}

export async function getItemChildrenData(params) {
  await dispatch(fetchItemChildrenData(params));
}

export async function getTikTokHashtagsData(params) {
  await dispatch(fetchHashtagsData({ params }));
}

export async function getInstagramHashtagsData(params) {
  await dispatch(fetchInstagramHashtagsData({ params }));
}

export async function fetchWikiRealTimeData(params) {
  await dispatch(fetchWikiRealTimeDataApi({ params }));
}

export async function fetchGoogleRealTimeData(params) {
  await dispatch(fetchRealTimeMonthApi({ params }));
}

export async function fetchYoutubeRealTimeData(params) {
  await dispatch(fetchRealTimeMonthWithKeywordAndPropertyApi({ params }));
}
export async function fetchTrackedWikiRealTimeData(params) {
  await dispatch(fetchTrackedWikiRealTimeDataApi({ params }));
}

export async function fetchTrackedFandomData(params) {
  await dispatch(fetchTrackedFandomDataApi({ params }));
}

export async function fetchTrackedRedditData(params) {
  await dispatch(fetchTrackedRedditDataApi({ params }));
}

// export async function fetchTrackedSteamData(params) {
//   await dispatch(fetchTrackedSteamDataApi({ params }));
// }

// export async function fetchTrackedTwitchData(params) {
//   await dispatch(fetchTrackedTwitchDataApi({ params }));
// }

export async function fetchTrackedDiscordData(params) {
  await dispatch(fetchTrackedDiscordDataApi({ params }));
}

export async function fetchTrackedInstagramData(params) {
  await dispatch(fetchTrackedInstagramDataApi({ params }));
}

export async function fetchTrackedGoogleTrendsData(params) {
  await dispatch(fetchTrackedGoogleTrendsDataApi({ params }));
}

export async function fetchTrackedYoutubeTrendsData({ ip_id }) {
  await dispatch(fetchTrackedYouTubeTrendsDataApi({ ip_id }));
}
export async function fetchPiracyTrendsData(params) {
  await dispatch(fetchPiracyTrendsDataApi({ params }));
}

export async function getSteamchartsData(params) {
  await dispatch(fetchSteamchartsData({ params }));
}

// export async function getTwitchData(params) {
//   await dispatch(fetchTwitchData({ params }));
// }

export const mergeFranchiseAction = async (source, destination) => {
  await axiosInstance.post(`/franchise/merge`, { source, destination });
};

export const updateFranchiseAction = async (id, name, summary) => {
  await axiosInstance.put(`/franchise/${id}`, { name, summary });
};
export const updateFranchiseCoverImageAction = async (id) => {
  await axiosInstance.patch(`/franchise/${id}/image`);
};

export const deleteFranchiseAction = async (ip_id) => {
  await axiosInstance.request({
    url: `/franchise`,
    method: "DELETE",
    data: {
      ip_id,
    },
  });
};

export const trackIP = async (ip_id, ip_list, username, user_role) => {
  await axiosInstance.request({
    url: `/bridge/event`,
    method: "POST",
    data: {
      eventType: "addToTrackedList",
      data: {
        manual_tracking: true,
        id: ip_id,
        ip_list,
        username,
        user_role,
      },
    },
  });
};

export const unTrackIP = async (ip_id, ip_list, username, user_role) => {
  await axiosInstance.request({
    url: `/bridge/event`,
    method: "POST",
    data: {
      eventType: "removeFromTrackedList",
      data: {
        id: ip_id,
        ip_list,
        username,
        user_role,
      },
    },
  });
};

export const deleteItemAction = async (ip_id) => {
  await axiosInstance.request({
    url: `/item/${ip_id}`,
    method: "DELETE",
  });
};
export const updateTagAction = async (ip_id, data) => {
  await axiosInstance.request({
    url: `/item/${ip_id}/tags/v2`,
    method: "PUT",
    data: data,
  });
};

export async function fetchItemAuditData({ ip_id }) {
  await dispatch(fetchItemAuditDataApi({ id: ip_id }));
}

export async function fetchItemNewsData({ id }) {
  await dispatch(fetchItemNewsDataApi({ id }));
}

export async function fetchTrailersData({ id }) {
  await dispatch(fetchTrailersDataApi({ id }));
}

export async function fetchItemBoxOfficeData({ id }) {
  await dispatch(fetchItemBoxOfficeDataApi({ id }));
}

export async function fetchItemStreamingWindowData({ id, vertical }) {
  await dispatch(fetchItemStreamingWindowDataApi({ id, vertical }));
}
