import { SpaceBetween } from "@cloudscape-design/components";
import React, { FC } from "react";
import "./index.css";

interface FavoritesBarListProps {
  favoriteServices: Object;
}

export const FavoritesBarList: FC<FavoritesBarListProps> = ({
  favoriteServices,
}) => {
  if (!favoriteServices || Object.keys(favoriteServices)?.length === 0)
    return null;

  return (
    <div className="mx-6 overflow-x-auto hide-scrollbar flex gap-2">
      {Object.keys(favoriteServices).map((key) => (
        <a 
          key={key}
          href={key}
          className="rounded-full whitespace-nowrap px-3 py-1 mb-1 text-blue-400 ring-1 ring-inset ring-blue-400/60 hover:ring-blue-400/80 hover:bg-blue-400/10 text-xs font-medium"
        >
          {favoriteServices[key]}
        </a>
      ))}
    </div>
  );
};
