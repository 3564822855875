import { Button, Link } from "@cloudscape-design/components";
import Popover from "@cloudscape-design/components/popover";
const RottenTomatesCriticScore = ({ rtCriticSummary }) => {
  if (!rtCriticSummary || rtCriticSummary.score == null) {
    return (
      <div>
        <div className="pb-1 flex space-x-2 items-center">
          <img
            src="/rticons/tomatometer-empty.svg"
            alt="No Score"
            className="h-7"
          />
          <div className="text-xl text-slate-600 font-bold">- %</div>
        </div>
        <div className="text-xs text-slate-600 font-bold">TOMATOMETER</div>
      </div>
    );
  }

  const isFresh =
    rtCriticSummary.criticRating === "Fresh" ||
    rtCriticSummary.rating === "Fresh";
  const isCertifiedFresh =
    rtCriticSummary.criticRating === "Certified Fresh" ||
    rtCriticSummary.rating === "Certified Fresh";
  const isRotten =
    rtCriticSummary.criticRating === "Rotten" ||
    rtCriticSummary.rating === "Rotten";

  const score = rtCriticSummary.score;
  const count = rtCriticSummary.count || rtCriticSummary.numReviews;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center space-x-2">
        {isCertifiedFresh && (
          <img
            src="/rticons/certified_fresh.svg"
            alt="Fresh"
            className="h-12 w-12"
          />
        )}
        {isFresh && score >= 75 && count > 80 && (
          <img
            src="/rticons/certified_fresh.svg"
            alt="Fresh"
            className="h-12 w-12"
          />
        )}
        {isFresh && score < 75 && (
          <img
            src="/rticons/tomatometer-fresh.svg"
            alt="Fresh"
            className="h-12 w-12"
          />
        )}
        {isFresh && score >= 75 && count < 80 && (
          <img
            src="/rticons/tomatometer-fresh.svg"
            alt="Fresh"
            className="h-12 w-12"
          />
        )}
        {isRotten && (
          <img
            src="/rticons/tomatometer-rotten.svg"
            alt="Rotten"
            className="h-12 w-12"
          />
        )}
        <div className="text-black dark:text-slate-100">
          <div className="text-xl dark:text-slate-50 font-bold leading-[1.25rem]">
            {score ? `${score}%` : "N/A"}
          </div>
          {rtCriticSummary.url ? (
            <div className="font-bold">
              <Link fontSize="body-s" href={rtCriticSummary.url} target="_blank" rel="noopener noreferrer" >
                Tomatometer
              </Link>
            </div>
          ) : (
            <div className="text-xs font-bold">Tomatometer</div>
          )}
          <div className="text-xs italic flex items-center dark:text-slate-400">
            {`${count} reviews `}
            {rtCriticSummary.consensus && (
              <Popover
                header="Critic Consensus"
                dismissButton={false}
                position="bottom"
                triggerType="custom"
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rtCriticSummary.consensus,
                    }}
                  />
                }
              >
                <Button iconName="status-info" variant="inline-icon" />
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RottenTomatesCriticScore;
