import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Header,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Toggle,
} from "@cloudscape-design/components";

import { navItems } from "../../../layouts/common/menu/side-menu";

import { AgCharts } from "ag-charts-react";
import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import {
  useGetChartTheme,
  CHART_TYPES,
} from "../../../hooks/UseTheme/useGetChartTheme";
import numberWithCommas from "../../../utils/ag-utils";
import createFlashMessage from "../../../utils/createFlashMessage";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { getTarSeriesWikiViewGenrePlatformResponse } from "./redux/actions/tar-series-wiki-genre-platform-actions";

const TarSeriesWikiViewGenrePlatformPage = () => {
  const { theme } = useGetGridTheme();
  const { theme: chartTheme } = useGetChartTheme(CHART_TYPES.DEFAULT, {
    overrides: {
      common: {
        axes: {
          number: {
            tick: {
              width: 0.5,
            },
          },
          category: {
            tick: {
              width: 0,
            },
          },
        },
      },
      bar: {
        series: {
          highlightStyle: {
            series: {
              dimOpacity: 0.2,
              strokeWidth: 1,
            },
          },
        },
      },
    },
  });
  const [dateRange, setDateRange] = useState({
    startDate: moment()
      .subtract(12, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const chartRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const [, setChartData] = useState([]);

  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [excludeOTT, setExcludeOTT] = React.useState(false);

  const tarSeriesWikiViewGenrePlatform = useSelector(
    (state) => state.tarSeriesWikiViewGenrePlatform,
  );

  const [options, setOptions] = useState({});
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (rowData.length > 0) {
      setColumnDefs([
        {
          headerName: "Genre",
          field: "title",
          filter: true,
          rowGroup: true,
        },
        {
          headerName: "Platform",
          field: "platform",
        },
        {
          headerName: "Views",
          field: "pageViews",
          valueFormatter: (params) =>
            params.value ? numberWithCommas(params) : "",
        },
        {
          headerName: "Title Count",
          field: "titleCount",
        },
      ]);
    }
  }, [rowData]);

  useEffect(() => {
    if (dateRange.startDate) {
      getTarSeriesWikiViewGenrePlatformResponse({
        gte: moment(dateRange.startDate).format("YYYYMMDD"),
        lte: moment(dateRange.endDate).format("YYYYMMDD"),
        excludeOTT,
      });
    }
  }, [dateRange, excludeOTT]);

  useEffect(() => {
    if (
      tarSeriesWikiViewGenrePlatform.completed &&
      tarSeriesWikiViewGenrePlatform.hasError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: tarSeriesWikiViewGenrePlatform.message,
        }),
      );
    }
  }, [tarSeriesWikiViewGenrePlatform]);

  const breadcrumbs = [
    { text: "Dashboard" },
    { text: "Peacock Dashboard", href: "/peacock-dashboard" },
    { text: "Genre-based Wikipedia Insights", href: "/" },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      minWidth: 120,
      flex: 1,
    }),
    [],
  );

  useEffect(() => {
    if (tarSeriesWikiViewGenrePlatform.data !== null) {
      setRowData(
        Object.entries(tarSeriesWikiViewGenrePlatform.data)
          .map(([title, { titleCount, data }]) => {
            return {
              title: title,
              titleCount: titleCount,
              data: data,
            };
          })
          .flatMap(({ title, data }) =>
            data.map((item) => ({
              ...item,
              title: title,
            })),
          ),
      );
      let newChartData = [];
      let platforms = [];

      Object.entries(tarSeriesWikiViewGenrePlatform.data).forEach(
        ([genre, { data }]) => {
          let genreRow = { genre: genre };
          data.forEach((datum) => {
            genreRow[datum.platform] = datum.pageViews;
            if (!platforms.includes(datum.platform))
              platforms.push(datum.platform);
          });
          newChartData.push(genreRow);
        },
      );

      setChartData(newChartData);

      setOptions((prevOptions) => ({
        ...prevOptions,
        data: newChartData,
        series: platforms.map((platform, index) => ({
          type: "bar",
          xKey: "genre",
          yKey: platform,
          yName: platform,
          stacked: true,
          // fill: colors[index % colors.length],
          strokeWidth: 0.5,
        })),
        legend: { position: "bottom", item: { marker: { strokeWidth: 0 } } },
        theme: chartTheme,
        axes: [
          {
            type: "category",
            position: "bottom",
          },
          {
            type: "number",
            position: "left",
            label: {
              // Add this to your axis definition
              formatter: (params) => {
                if (Math.abs(params.value) >= 1.0e9) {
                  return (Math.abs(params.value) / 1.0e9).toFixed(0) + "B";
                } else if (Math.abs(params.value) >= 1.0e6) {
                  return (Math.abs(params.value) / 1.0e6).toFixed(0) + "M";
                } else if (Math.abs(params.value) >= 1.0e3) {
                  return (Math.abs(params.value) / 1.0e3).toFixed(0) + "K";
                }
                return params.value;
              },
            },
          },
        ],
      }));
    }
  }, [tarSeriesWikiViewGenrePlatform]);

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>

      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        content={
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(12, "months")
                                    .startOf("month")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(12, "months")
                                  .startOf("month")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD",
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                      />
                      <Toggle
                        checked={excludeOTT}
                        onChange={() => setExcludeOTT(!excludeOTT)}
                      >
                        Exclude OTT Originals
                      </Toggle>
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="pr-5">
                      Platform Influence on Genre Popularity
                    </span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>

                <CustomFlashBar />
              </>
            }
          >
            <Container>
              <Box>
                {tarSeriesWikiViewGenrePlatform.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : rowData.length > 0 ? (
                  <SpaceBetween direction="vertical" size="xxl">
                    <>
                      <div
                        className={theme}
                        style={{
                          height: "330px",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <AgGridReact
                          rowData={rowData}
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowSelection="single"
                        />
                      </div>
                      <div style={{ height: "900px" }}>
                        <AgCharts options={options} style={{ height: "100%" }} />
                      </div>
                    </>
                  </SpaceBetween>
                ) : null}
              </Box>
            </Container>
          </ContentLayout>
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default TarSeriesWikiViewGenrePlatformPage;
