import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import { useDeleteChannel } from "../api/hooks/useDeleteChannel";
import { ChannelListResponse } from "../api/request";

interface DeleteModalProps {
  visible: boolean;
  selectedChannels: ChannelListResponse;
  onSuccess: () => void;
  onFailure: () => void;
  onDismiss: () => void;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  visible,
  selectedChannels,
  onSuccess,
  onFailure,
  onDismiss,
}) => {
  const { mutate: deleteChannel, isLoading } = useDeleteChannel(
    onSuccess,
    onFailure,
  );

  const payload = {
    channels: selectedChannels.map((item) => item.channel_id),
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={onDismiss} loading={isLoading}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => deleteChannel(payload)}
              loading={isLoading}
            >
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Delete Channels"
    >
      Are you sure to delete the selected channels ?
    </Modal>
  );
};
