import { Container, Header, Spinner } from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../hooks/UseTheme/useGetChartTheme";
import { fetchItemStreamingWindowData } from "../redux/actions/item-actions";

const StreamingWindowComponent = ({ titleId, vertical }) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, {
    overrides: {
      "range-bar": {
        series: {
          highlightStyle: {
            item: {
              stroke: "gold",
              strokeWidth: 100,
            },
          },
        },
      },
    },
  });
  const [streamingWindowData, setStreamingWindowData] = useState(null);
  const [error, setError] = useState(null);
  const itemStreamingWindowData = useSelector(
    (state: any) => state?.itemStreamingWindowData?.data,
  );
  const isLoading = useSelector(
    (state: any) => state?.itemStreamingWindowData?.loading,
  );

  useEffect(() => {
    setStreamingWindowData(itemStreamingWindowData);
  }, [itemStreamingWindowData]);

  useEffect(() => {
    if (itemStreamingWindowData === null) {
      fetchItemStreamingWindowData({ id: titleId, vertical });
    }
  }, []);

  if (isLoading)
    return (
      <div className="justify-center flex">
        <Spinner size="large" />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  if (!streamingWindowData || Object.keys(streamingWindowData).length === 0) {
    return "";
  }

  const fills = [
    "#5C2983",
    "#0076C5",
    "#21B372",
    "#FDDE02",
    "#F76700",
    "#D30018",
  ];

  const transformedData = streamingWindowData.map((item, index) => ({
    ...item,
    unique_key: `${item.service_name}_${index}`,
    service_name: item.service_name,
    start_date: new Date(item.start_date).getTime(),
    end_date: new Date(item.end_date).getTime(),
    days: parseInt(item.days, 10),
  }));

  const serviceToColor = transformedData.reduce((acc, item) => {
    acc[item.service_name] =
      acc[item.service_name] || fills[Math.floor(Math.random() * fills.length)];
    return acc;
  }, {});

  const tooltip = {
    renderer: ({ datum, xKey, yLowKey, yHighKey }) => {
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()} ${date.toLocaleString("en-US", {
          month: "long",
        })} ${date.getFullYear()}`;
      };
      return {
        title: datum.service_name,
        content: `<b>Days:</b> ${
          datum.days
        } days <br/><b>Start Date: </b>${formatDate(
          datum[yLowKey],
        )}<br/><b>End Date: </b>${formatDate(datum[yHighKey])}`,
      };
    },
  };

  const options = {
    theme: theme,
    data: transformedData,
    series: [
      {
        type: "range-bar",
        direction: "horizontal",
        xKey: "service_name",
        yLowKey: "start_date",
        yHighKey: "end_date",
        label: {
          placement: "outside",
          color: "white",
          fontSize: 10,
          padding: 5,
          formatter: ({ datum, itemId }) => {
            if (itemId === "high") {
              return `${datum.days} days`;
            }
            return "";
          },
        },
        tooltip,
        formatter: (params) => ({
          fill: serviceToColor[params.datum.service_name],
          fillOpacity: 1,
          labelKey: "service_name",
        }),
      },
    ],
    axes: [
      {
        type: "category",
        position: "left",
        crosshair: { enabled: true },
      },
      {
        type: "time",
        position: "bottom",
        gridLine: {
          style: [
            {
              stroke: "#383838",
              lineDash: [2, 2],
            },
          ],
        },
        crosshair: { enabled: false },
        label: {
          avoidCollisions: true,
          autoRotate: true,
          minSpacing: 20,
        },
      },
    ],
  } as unknown;

  return (
    <Container
      header={<Header variant="h3">Service Platform Streaming Window</Header>}
    >
      <div style={{ height: "450px", width: "100%" }}>
        <AgCharts options={options} style={{ height: "100%" }} />
      </div>
    </Container>
  );
};

export default StreamingWindowComponent;
