import axiosInstance from "../../../../../utils/axios";
import { isNone } from "../../../../../utils/sugarUtils";

export interface PantheonRankingResponseItem {
  ip: string;
  certificates: string;
  image_url: string;
  score: number;
  imdb_vote: number;
  release_date: number;
  ip_id: number;
  imdb_rating: number;
  isTheatrical: boolean;
  genre: Array<string>;
  distributors: Array<string>;
  production: Array<string>;
  franchise: Array<string>;
}

export type PantheonRankingResponse = Array<PantheonRankingResponseItem>;

export const getPantheonRankingData = ({
  vertical = "movies",
  limit = 50,
  offset = 0,
  sort = "score",
  searchStr = "",
  filters = {},
  start_ts,
  end_ts,
  weightageValues,
}) => {
  let query = [];

  if (!weightageValues) {
    return Promise.reject("Weightage values not found");
  }

  if (searchStr.length) {
    query.push({
      field: "ip",
      operation: "contains",
      value: searchStr,
    });
  }

  Object.keys(filters).forEach((key) => {
    if (isNone(filters[key])) return;

    query.push({
      field: key,
      operation: "in",
      value: filters[key],
    });
  });

  const payload = {
    weights: weightageValues,
    q: query,
  };

  return axiosInstance.request({
    url: `/pantheon/globalranking/v2?start_ts=${start_ts}&end_ts=${end_ts}&offset=${offset}&limit=${limit}&sort=${sort}&vertical=${vertical}`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    data: payload,
  });
};

export const getPantheonRankingDataV2 = ({
  filterQuery,
  sort = "score",
  vertical,
  sortOrder = "desc",
  offset = 0,
  paginationPageSize=50,
  startTs,
  endTs,
  weightageValues,
}) => {

  if (!weightageValues) {
    return Promise.reject("Weightage values not found");
  }

  const payload = {
    weights: weightageValues,
    q: filterQuery,
  };

  return axiosInstance.request({
    url: `/pantheon/globalranking/v2?start_ts=${startTs}&end_ts=${endTs}&offset=${offset}&limit=${paginationPageSize}&sort=${sort}&sort_order=${sortOrder}&vertical=${vertical}`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    data: payload,
  });
};


