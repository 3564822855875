import React, { useState, useEffect } from "react";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { ButtonDropdown, Header, SpaceBetween } from "@cloudscape-design/components";
import DatapointComparison from "../../../components/datapoint-comparison/DatapointComparison";
import { parametersFromUrlParams, urlParamsFromParameters } from "../../../components/datapoint-comparison/utils";
import { useSearchParams } from "react-router-dom";
import { WeightsModal } from "./modals/WeightsModal";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../ranking/constants";
import { useMetrics } from "./hooks/useMetrics";

export const CompareTool = () => {

  const [searchParams] = useSearchParams();

  const title = "Compare";
  const breadcrumbs = [
    { text: "Tools", href: "/" },
    { text: "Compare" },
  ];
  const defaultDefaults = {
    metricKeys: [
      "weighted_score", 
      "global_wiki_score", 
      "global_imdb_score", 
      "global_rt_score", 
      "global_piracy_score", 
      "global_yt_score", 
      "global_steam_score", 
      "global_twitch_score"
    ],
    activeMetricKey: "weighted_score",
  };

  const [defaults, setDefaults] = useState(null);
  const [weightsModalVisible, setWeightsModalVisible] = useState(false);
  const [weightsObjects, setWeightsObjects] = useState(DEFAULT_WEIGHTS_OBJECTS);

  const { metrics } = useMetrics({ weightsObjects });

  useEffect(() => {
    if (defaults) return;
    setDefaults(parametersFromUrlParams(searchParams) || defaultDefaults);
  }, [searchParams]);

  return (
    <>
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        navItems={navItems as NavItemsWithId}
        content={
          <div className="flex flex-col gap-4">
            <Header
              variant="h2"
              actions={
                <SpaceBetween size="s">
                  <ButtonDropdown
                    items={[
                      { text: "Manage Z-Score weights", id: "manage-weights", disabled: true },
                      { text: "Manage custom metrics", id: "manage-custom-metrics", disabled: true },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "manage-weights":
                          setWeightsModalVisible(true);
                          break;
                        case "manage-custom-metrics":
                          console.log("manage-custom-metrics");
                          break;
                        default:
                          break;
                      }
                    }}
                    expandToViewport
                  >
                    Actions
                  </ButtonDropdown>
                </SpaceBetween>
              }
            >
              Compare Titles
            </Header>
            <DatapointComparison 
              metrics={metrics}
              defaults={defaults}
              onParametersChanged={({ detail }) => {
                const urlParams = urlParamsFromParameters(detail);
                window.history.pushState({}, "", `?${urlParams}`);
              }}
              options={{
                container: {
                  disablePadding: true,
                  disableBorder: true,
                },
                chart: {
                  chartNotes: {
                    enabled: true,
                  },
                },
                summary: {
                  enabled: true,
                },
              }}
            />
          </div>
        }
      />
      <WeightsModal
        visible={weightsModalVisible}
        setVisible={setWeightsModalVisible}
        weightsObjects={weightsObjects}
        setWeightsObjects={setWeightsObjects}
      />
    </>
  );
};