import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import AuditBox from "../components/AuditBox";

interface AuditModalProps {
  visible: boolean;
  onDiscard: () => void;
  ip_id: string; // Adjust the type if necessary
}

const AuditModal: FC<AuditModalProps> = ({ visible, onDiscard, ip_id }) => {
  return (
    <Modal
      onDismiss={onDiscard}
      visible={visible}
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Audit Logs"
    >
      <AuditBox ip_id={ip_id} />
    </Modal>
  );
};

export default AuditModal;
