import React, { useContext, useState } from "react";
import { AgCharts } from "ag-charts-react";
import { SpaceBetween } from "@cloudscape-design/components";
import { CompareContext } from "../../DatapointComparison";
import { useChartConfig } from "./hooks/useChartConfig";

const RadialChart = ({
  metrics,
  titles,
  timeseriesData,
  multiParamInfo,
}) => {

  const options = useContext(CompareContext);

  const [ titleMetricInfo, setTitleMetricInfo ] = useState([]);
  const [ chartBaseTheme, setChartBaseTheme ] = useState(null);

  const { chartOptions } = useChartConfig({
    metrics: metrics,
    titles: titles,
    chartHeight: options.chart.height,
    multiParamInfo: multiParamInfo,
    timeseriesData: timeseriesData,
    titleMetricInfo: titleMetricInfo,
    setTitleMetricInfo: setTitleMetricInfo,
    chartBaseTheme,
  });

  return (
    <SpaceBetween direction="vertical" size="s">
      <div 
        style={{ height: `${options.chart.height}px`, width: "100%", position: "relative" }} 
        className="ag-theme-quartz-dark" 
      >
        <AgCharts
          options={chartOptions}
        />
      </div>
    </SpaceBetween>
  );
};

export default RadialChart;