export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getYearList = (currentYear: number) =>
  Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index);

export const dateFormatter = (date: number) => {
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return new Intl.DateTimeFormat("en-US", options as any).format(date);
};

export const formatDate = (date: Date) => {
  return `${
    monthList[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};
