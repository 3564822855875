import { Box, SpaceBetween } from "@cloudscape-design/components";
import React from "react";

export const EmptyGrid = () => {
  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <span>No data available for the selected date range</span>
        </div>
      </SpaceBetween>
    </Box>
  );
};
