import { Button, ButtonDropdown, Drawer, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useMemo, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { FILTER_TYPES, SERVICES, TYPES } from '../../../config-global';
import { SetFilter } from '../../../components/filter-options/components/SetFilter';
import _ from 'lodash';
import { DEFAULT_FILTER_OBJECTS } from '../constants';
import { useGetGenericPreference_V2 } from '../../../services/generic_v2/hooks/useGetGenericPreference_V2';
import { usePutGenericPreference_V2 } from '../../../services/generic_v2/hooks/usePutGenericPreference_V2';
import { usePostGenericPreference_V2 } from '../../../services/generic_v2/hooks/usePostGenericPreference_V2';
import { invalidatePreferenceData } from '../../../services/generic_v2/utils/serviceUtils';
import { EditableTextAttribute } from './EditableTextAttribute';
import { MessageBox } from '../../../components/MessageBox';

export const FiltersDrawer = forwardRef(({
  filterObject,
  setFilterObject,
  vertical,
}, ref) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.RANKING, module: "global" };
  const messageBoxRef = useRef(null);

  const [currentFilterObject, setCurrentFilterObject] = useState(filterObject);
  const [loadedFilters, setLoadedFilters] = useState([]);

  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });
  
  const setFilterListItem = (filterListItem) => {
    const newFilters = currentFilterObject.filters.map(f => f.key === filterListItem.key ? filterListItem : f);
    setCurrentFilterObject({ ...currentFilterObject, filters: newFilters });
  }

  const savePreferences = (filterObjects) => {
    const prefValue = rankingPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      data.filters = filterObjects;

      updatePreference({ id: prefValue.id, payload: data });
      return;
    }

    const data = { filters: filterObjects };
    postGenericPreference(data);
  };

  const currentFiltersAreSaved = useMemo(() => {
    return loadedFilters?.map(w => _.isEqual(w, currentFilterObject)).includes(true);
  }, [loadedFilters, currentFilterObject]);

  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;

    if (pref) {
      if (pref.filters) {
        setLoadedFilters(pref.filters);
      }
    }
  }, [rankingPreferenceData]);

  useEffect(() => {
    const newFilterObject = { 
      ...currentFilterObject,
      titleSearchQuery: filterObject.titleSearchQuery,
      advancedFilter: filterObject.advancedFilter,
    };
    setCurrentFilterObject(newFilterObject);
  }, [filterObject.titleSearchQuery, filterObject.advancedFilter]);

  useImperativeHandle(ref, () => ({
    setCurrentFilterObject,
  }), []);

  return (
    <Drawer header="Filters">
      <SpaceBetween direction="vertical" size="m">
        <div className="border-b border-1 pb-4 mb-2 border-slate-600">
          <div className="flex justify-between items-center w-full">
            <div className="min-w-0">
              <EditableTextAttribute
                value={currentFilterObject.name}
                onChange={(value) => setCurrentFilterObject({ ...currentFilterObject, name: value })}
              />
            </div>
            <ButtonDropdown
              expandableGroups
              expandToViewport
              items={[
                {
                  text: "Load filter",
                  id: "load",
                  disabled: loadedFilters?.filter(w => w.category === vertical)?.length === 0,
                  items: loadedFilters?.filter(w => w.category === vertical)?.map(w => (
                    {
                      text: w.name,
                      id: `load_${w.name}`,
                      disabled: false,
                    }
                  ))
                },
                { text: "Load defaults", id: "reset", disabled: (_.isEqual(currentFilterObject, DEFAULT_FILTER_OBJECTS[vertical])) },
                { text: "Save filter", id: "save", disabled: (_.isEqual(currentFilterObject, DEFAULT_FILTER_OBJECTS[vertical]) || currentFiltersAreSaved) },
                { text: "Delete filter", id: "delete", disabled: (loadedFilters.filter(w => w.name === currentFilterObject.name && w.category === vertical).length === 0) },
              ]}
              variant="icon"
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case "save":
                    const save = () => {
                      const newLoadedFilters = [...loadedFilters.filter(w => !(w.name === currentFilterObject.name && w.category === vertical)), currentFilterObject];
                      setLoadedFilters(newLoadedFilters);
                      savePreferences(newLoadedFilters);
                    };
                    if (loadedFilters.filter(w => w.name === currentFilterObject.name && w.category === vertical).length > 0) {
                      messageBoxRef.current.open({
                        headerText: "Overwrite filter?",
                        messageBody: (<div>A filter named <b>{currentFilterObject.name}</b> already exists. Overwrite it?</div>),
                        primaryButtonText: "Yes",
                        secondaryButtonText: "No",
                        onPrimaryButtonClick: save,
                      });
                    } else {
                      save();
                    }
                    break;
                  case "delete":
                    messageBoxRef.current.open({
                      headerText: "Are you sure?",
                      messageBody: (<div>Are you sure you want to delete filter <b>{currentFilterObject.name}</b>?</div>),
                      primaryButtonText: "Delete",
                      secondaryButtonText: "Cancel",
                      onPrimaryButtonClick: () => {
                        const newLoadedFilters2 = loadedFilters.filter(w => !(w.name === currentFilterObject.name && w.category === vertical));
                        setLoadedFilters(newLoadedFilters2);
                        savePreferences(newLoadedFilters2);
                        setCurrentFilterObject(DEFAULT_FILTER_OBJECTS[vertical]);
                      },
                    });
                    break;
                  case "reset":
                    setCurrentFilterObject(DEFAULT_FILTER_OBJECTS[vertical]);
                    break;
                  default:
                    if (detail.id.startsWith("load_")) {
                      const newFilters = loadedFilters.find(w => w.name === detail.id.replace("load_", "") && w.category === vertical);
                      if (newFilters) {
                        setCurrentFilterObject(newFilters);
                      }
                    }
                }
              }}
            />
          </div>
          <div className="flex w-full">
            {!currentFiltersAreSaved && !_.isEqual(currentFilterObject, DEFAULT_FILTER_OBJECTS[vertical]) && (
              <div className="text-xs text-slate-400">You have unsaved changes</div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          {currentFilterObject?.filters?.map(filter => (
            <div key={filter.key}>
              {filter.filterType === FILTER_TYPES.OBJECT ? (
                <SetFilter
                  filterObject={filter}
                  setFilterObject={setFilterListItem}
                />
              ) : null}
            </div>
          ))}
        </div>
        <div className="flex space-x-2 float-right">
          <Button
            onClick={() => {
              const defaultFilterObject = DEFAULT_FILTER_OBJECTS[vertical];
              setCurrentFilterObject(defaultFilterObject);
            }}
            disabled={_.isEqual(currentFilterObject, DEFAULT_FILTER_OBJECTS[vertical])}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setFilterObject(currentFilterObject);
            }}
            disabled={_.isEqual(filterObject, currentFilterObject)}
          >
            Apply
          </Button>
        </div>
      </SpaceBetween>
      <MessageBox ref={messageBoxRef} />
    </Drawer>
  );
});