import {
  Box,
  Container,
  Header,
  SpaceBetween,
  TextContent,
} from "@cloudscape-design/components";
import React from "react";
import "tippy.js/dist/tippy.css";
import CustomLoadingOverlay from "../../../components/PantheonLoading";
import ReadMore from "../../../components/ReadMore";
import { useGetItemWikipediaSummaryData } from "../api/hooks/useGetItemWikipediaSummaryData";

function WikiSummaryBox({ id, summary }) {
  const [hoveredWord, setHoveredWord] = React.useState("sdlkfsdkfnskvcv");

  const { data: wikitpediaSummaryData, isLoading } =
    useGetItemWikipediaSummaryData(id);

  return (
    <>
      {isLoading ? (
        <Box textAlign="center" padding="l">
          <CustomLoadingOverlay />
        </Box>
      ) : (
        <SpaceBetween direction="vertical" size="xs">
          <Container header={<Header>Short Description</Header>}>
            <ReadMore text={summary} maxLength={1000} />
          </Container>
          <Container header={<Header>Summary</Header>}>
            {wikitpediaSummaryData?.summary ? (
              <>
                <TextContent>
                  {wikitpediaSummaryData.summary
                    .split("\n")
                    .map((paragraph, index) => {
                      const parts = paragraph.split(
                        new RegExp(`(${hoveredWord})`, "gi"),
                      );
                      return (
                        <p key={index}>
                          {parts.map((part) =>
                            part.toLowerCase() === hoveredWord.toLowerCase() ? (
                              <span
                                style={{
                                  backgroundColor: "yellow",
                                  color: "black",
                                }}
                              >
                                {part}
                              </span>
                            ) : (
                              part
                            ),
                          )}
                        </p>
                      );
                    })}
                </TextContent>
              </>
            ) : (
              <div className="py-8 flex flex-1 flex-row justify-center items-center">
                <b>Summary not available</b>
              </div>
            )}
          </Container>
        </SpaceBetween>
      )}
    </>
  );
}

export default WikiSummaryBox;
