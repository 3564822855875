import React, { useState } from "react";
import { 
  Select, 
  Button, 
  Input, 
  Modal, 
  AttributeEditor, 
  Box,
  SpaceBetween,
} from "@cloudscape-design/components";
import { TAGS_LIST } from "../../../config-global";
import _ from "lodash";
import AutosuggestSelector from "../../../components/data-mapping/AutosuggestSelector";
import TagSelector from "../../../components/data-mapping/TagSelector";


const AttributesEditModalV2 = ({ itemData, visible, onSubmit, onDismiss, isLoading, editableAttributes=TAGS_LIST.map(x => x.value) }) => {

  const validTagKeys = TAGS_LIST.map(x => x.value).filter(x => editableAttributes.includes(x));
  const originalTags = Object.keys(itemData).filter(key => validTagKeys.includes(key)).map(key => ({ key: key, value: itemData[key] }));
  
  const [ currentTags, setCurrentTags ] = useState(originalTags);

  const onAutosuggestValueSelected = (itemIndex, value) => {
    const tmpItems = [...currentTags];
    tmpItems[itemIndex].value = value;
    setCurrentTags(tmpItems);
  }

  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={onDismiss}
      header="Add or edit attributes"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>Cancel</Button>
            <Button 
              variant="primary"
              onClick={() => {
                const newTags = currentTags.map(tag => ({ key: tag.key, value: tag.value })).filter(tag => !!tag.key && !!tag.value && tag.value.length > 0);

                const isSameTag = (tag1, tag2) => tag1.key === tag2.key;

                const removedTags = _.differenceWith(originalTags, newTags, isSameTag);
                const addedOrUpdatedTags = _.differenceWith(newTags, originalTags, _.isEqual);

                const updates = _.reduce(addedOrUpdatedTags, (result, tag) => {
                  result[tag.key] = tag.value;
                  return result;
                }, {});
                const removes = _.map(removedTags, "key");

                //console.log({ update: updates, remove: removes });
                onSubmit({ update: updates, remove: removes });
              }}
              disabled={isLoading}
              loading={isLoading}
              loadingText={"Submitting"}
            >
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <AttributeEditor
        onAddButtonClick={() => setCurrentTags([...currentTags, {}])}
        onRemoveButtonClick={({ detail: { itemIndex } }) => {
          const tmpItems = [...currentTags];
          tmpItems.splice(itemIndex, 1);
          setCurrentTags(tmpItems);
        }}
        items={currentTags}
        addButtonText="Add new tag"
        definition={[
          {
            label: "Platform",
            control: (item, itemIndex) => (
              <Select
                selectedOption={currentTags[itemIndex] ? TAGS_LIST.find(x => x.value === currentTags[itemIndex].key) : null}
                onChange={({ detail }) => {
                  const selectedPlatform = detail.selectedOption.value;
                  const tmpItems = [...currentTags];
                  tmpItems[itemIndex].key = selectedPlatform;
                  tmpItems[itemIndex].value = "";
                  setCurrentTags(tmpItems);
                }}
                options={TAGS_LIST.filter(z => validTagKeys.filter(x => !currentTags.map(y => y.key).includes(x)).includes(z.value))}
                placeholder="Select a platform"
              />
            ),
          },
          {
            label: "Value",
            control: (item, itemIndex) => {
              return (
                (currentTags[itemIndex].key === "wikipedia") ? (
                  <AutosuggestSelector
                    platform="wikipedia"
                    placeholder="Search for a Wikipedia article"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                    onPreview={(value) => window.open(`https://en.wikipedia.org/wiki/${value.replace(/ /g, "_")}`)}
                  />
                ) : (currentTags[itemIndex].key === "subreddit") ? (
                  <AutosuggestSelector
                    platform="reddit"
                    placeholder="Search for a subreddit"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => {
                      const tmpItems = [...currentTags];
                      tmpItems[itemIndex].value = value;
                      setCurrentTags(tmpItems);
                    }}
                    onPreview={(value) => window.open(`https://reddit.com/r/${value}`)}
                  />
                ) : (currentTags[itemIndex].key === "twitch") ? (
                  <AutosuggestSelector
                    platform="twitch"
                    placeholder="Search for a game on Twitch"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                  />
                ) : (currentTags[itemIndex].key === "steam") ? (
                  <AutosuggestSelector
                    platform="steam"
                    placeholder="Search for a game on Steam"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                    onPreview={(value) => window.open(`https://store.steampowered.com/app/${value}`)}
                  />
                ) : (currentTags[itemIndex].key === "trends") ? (
                  <AutosuggestSelector
                    platform="trends"
                    placeholder="Search for a Google Trends topic"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                    onPreview={(value) => window.open(`https://trends.google.com/trends/explore?q=${value}`)}
                  />
                ) : (currentTags[itemIndex].key === "a03") ? (
                  <AutosuggestSelector
                    platform="ao3"
                    placeholder="Search for a fandom on AO3"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                    onPreview={(value) => window.open(`https://archiveofourown.org/tags/${value}/works`)}
                  />
                ) : (currentTags[itemIndex].key === "fandom") ? (
                  <AutosuggestSelector
                    platform="fandom"
                    placeholder="Search for a fandom"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                    onPreview={(value) => window.open(`https://${value}/`)}
                  />
                ) : (currentTags[itemIndex].key === "youtubeChannel") ? (
                  <AutosuggestSelector
                    platform="youtube_channel"
                    placeholder="Search for a YouTube channel"
                    value={currentTags[itemIndex].value}
                    onValueSelected={(value) => onAutosuggestValueSelected(itemIndex, value)}
                  />
                ) : (["instagram", "tiktok", "wattpad"].includes(currentTags[itemIndex].key)) ? (
                  <TagSelector
                    tags={currentTags[itemIndex].value}
                    onTagsChanged={(tags) => {
                      const tmpItems = [...currentTags];
                      tmpItems[itemIndex].value = tags.join(",");
                      setCurrentTags(tmpItems);
                    }}
                    platformName={TAGS_LIST.find(x => x.value === currentTags[itemIndex].key).label}
                  />
                ) : (currentTags[itemIndex].key === "cTags") ? ( // why are we naming things like this?? non-descriptive.
                  <TagSelector
                    tags={currentTags[itemIndex].value}
                    onTagsChanged={(tags) => {
                      const tmpItems = [...currentTags];
                      tmpItems[itemIndex].value = tags;
                      setCurrentTags(tmpItems);
                    }}
                    platform="custom_tags"
                    platformName={TAGS_LIST.find(x => x.value === currentTags[itemIndex].key).label}
                  />
                ) : (
                  <Input
                    value={currentTags[itemIndex] ? currentTags[itemIndex].value : ""}
                    onChange={({ detail }) => {
                      const tmpItems = [...currentTags];
                      tmpItems[itemIndex].value = detail.value;
                      setCurrentTags(tmpItems);
                    }}
                    placeholder="Enter a value"
                  />
                )
              );
            },
          }
        ]}
        empty="No tags added"
        removeButtonText="Remove"
      />
    </Modal>
  );
};

export default AttributesEditModalV2;