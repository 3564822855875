import { Container, ContentLayout, Grid, Header, SpaceBetween, } from "@cloudscape-design/components";
import React from "react";
import { NavItemsWithId, navItems, } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { GRPageCards } from "./GRPageCards";
import TrendingWidget from "../components/TrendingWidget";

const title = "Overview";
const gridItems = 9;
const gridDefinition = Array.from({ length: gridItems }, () => ({
  colspan: { default: 12, s: 6, l: 4 },
}));
const fields = "s.ip_id, s.ip, s.daily_rank, s.weekly_rank, s.daily_rank_change, s.weekly_rank_change, s.daily_rank_trend, s.weekly_rank_trend, s.month_score,s.release_date";
const movies_endpoint = "/pql?key=platform/global_movies&file_type=P&sort=daily_rank:asc&size=10"
const series_endpoint = "/pql?key=platform/global_series&file_type=P&sort=daily_rank:asc&size=10"
const gaming_endpoint = "/pql?key=platform/global_gaming&file_type=P&sort=daily_rank:asc&size=10"

const universalPicturesPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.distributors LIKE '%Universal Pictures%' OR s.distributors LIKE '%Focus Features%'`
};

const peacockPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.providers LIKE '%Peacock%' OR s.providers LIKE '%Peacock Premium%' OR s.providers LIKE '%Peacock Premium Plus%'`
};

const trendingPayload = {
  sql: `SELECT ${fields} FROM s3object s WHERE s.weekly_rank_change > 100 OR s.daily_rank_change > 100`
};

function GRHomePage() {
  const breadcrumbs = [
    { text: "Ranking" },
    { text: "Global" },
    { text: title },
  ];

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="A collection of dashboards that provide insights into global titles and their performance."
            >
              {title}
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <Grid gridDefinition={gridDefinition}>
              <TrendingWidget
                title="Top 10 Trending Movie Titles"
                payload={trendingPayload}
                endpoint={movies_endpoint}
              />
              <TrendingWidget
                title="Top 10 Trending Series Titles"
                payload={trendingPayload}
                endpoint={series_endpoint}
              />
              <TrendingWidget
                title="Top 10 Trending Game Titles"
                payload={trendingPayload}
                endpoint={gaming_endpoint}
              />

              <TrendingWidget
                title="Top 10 Universal Movies"
                payload={universalPicturesPayload}
                endpoint={movies_endpoint}
              />
              <TrendingWidget
                title="Top 10 Peacock Movies"
                payload={peacockPayload}
                endpoint={movies_endpoint}
              />
              <TrendingWidget
                title="Top 10 Peacock Series"
                payload={peacockPayload}
                endpoint={series_endpoint}
              />
            </Grid>
            <GRPageCards />
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="cards"
    />
  );
}

export default GRHomePage;
