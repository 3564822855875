import { Icon, Link } from "@cloudscape-design/components";
import React, { useMemo } from "react";

export const usePlatformRankingTableConfig = ({
  selectedGenres,
}) => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
      suppressMovable: true,
    }),
    [],
  );

  const colDefs = useMemo(() => {
    return [
      /*{
        headerName: "Rank",
        field: "rank",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },*/
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        cellRenderer: (params) => {
          const releaseYear = new Date(params.data.release_date).getFullYear();
          const ipId = params.data.ip_id;
          const destination = `/item/${ipId}`;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.ip_id != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link
                variant="secondary"
                href={destination}
                onClick={(e) => !ipId && e.preventDefault()}
              >
                {params.value}
              </Link>
              <span className="text-xs text-slate-500 mt-1">
                ({releaseYear})
              </span>
            </div>
          );
        },
      },
      {
        field: "genre",
        headerName: "Genre",
        cellDataType: "object",
        cellRenderer: (params) => {
          const highlightedGenres = selectedGenres.map(g => g.value);
          console.log(highlightedGenres, params.value)
          return <div>
            {params.value.map(genre => highlightedGenres.includes(genre) ? <span className="bg-[rgba(100,116,139,0.25)] rounded-md px-1 py-0.5">{genre}</span> : <span>{genre}</span>).reduce((prev, curr) => [prev, ', ', curr])}
          </div>;
        }
      },
    ];
  }, [selectedGenres]);

  return {
    columnDefs: colDefs,
    defaultColDef,
  };
};