import React, { useMemo, useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import { Multiselect } from "@cloudscape-design/components";
import { useGetTitleSearchData } from "./api/hooks/useGetTitleSearchData";
import { useGetTitleSearchByIdData } from "./api/hooks/useGetTitleSearchByIdData";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const verticalTypeLabels = {
  "Movies": "Movie",
  "Television": "Series",
  "Gaming": "Game",
  "Franchise": "Franchise",
};

const convertData = (data) => {
  const convertedData = data.map(item => {
    const imageUrl = getImageUrl(item.vertical, item.ip_id, item.image_url);
    const labelTags = [verticalTypeLabels[item.vertical]];
    if (item.release_date) {
      labelTags.push(new Date(item.release_date).getFullYear().toString());
    }
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: imageUrl,
      iconAlt: `${item.ip} cover`,
      tags: labelTags,
    };
  });

  return convertedData;
};

const MultiTitleSelector = ({ onSelectedItemsChanged, defaultSelectedTitleIds=[], shortenPlaceholder=false }) => {

  const [ searchResults, setSearchResults ] = useState([]);
  const [ selectedItems, setSelectedItems ] = useState([]);
  const [ queryString, setQueryString ] = useState("");

  const {
    data: titleSearchData,
    isLoading,
  } = useGetTitleSearchData({ queryString: queryString });

  const {
    data: titleSearchByIdData,
    searchByIdIsLoading,
  } = useGetTitleSearchByIdData({ ipIds: defaultSelectedTitleIds });

  const placeholder = useMemo(() => {
    if (shortenPlaceholder === true) {
      return selectedItems.length > 0 ? `${selectedItems.length} ${selectedItems.length == 1 ? "title" : "titles"} selected` : "Search for titles";
    } else {
      return selectedItems.length > 0 ? selectedItems.map(item => item.label).join(", ") : "Search for titles";
    }
  }, [selectedItems]);

  useEffect(() => {
    if (titleSearchByIdData) {
      const convertedItems = convertData(titleSearchByIdData);
      setSelectedItems(convertedItems);
      onSelectedItemsChanged(convertedItems);
    }
  }, [titleSearchByIdData]);

  useEffect(() => {
    if (titleSearchData) {
      setSearchResults(titleSearchData);
    }
  }, [titleSearchData]);

  return (
    <Multiselect
      options={[
        {
          label: "Search results",
          options: (searchResults && searchResults?.length > 0) ? convertData(searchResults) : [],
        },
        {
          label: "Selected",
          options: selectedItems,
        },
      ]}
      selectedOptions={selectedItems}
      onChange={({ detail }) => {
        setSelectedItems(detail.selectedOptions);
        onSelectedItemsChanged(detail.selectedOptions);
      }}
      onLoadItems={({ detail }) => setQueryString(detail.filteringText)}
      onBlur={() => setSearchResults([])}
      statusType={isLoading ? "loading" : "finished"}
      hideTokens={true}
      loadingText="Loading"
      placeholder={placeholder}
      filteringType="manual"
    />
  );
};

export default MultiTitleSelector;