import { Link } from "@cloudscape-design/components";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { FiSave } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { YoutubeVideosContext } from "../../Layout";
import { useUpdateVideos } from "../../api/hooks/useUpdateVideos";
import { AutoSuggestEditor } from "../../videos/components/AutoSuggestEditor";
import { ValueWithLabel } from "./ValueWithLabel";

type IPContainerProps = {
  ip: string;
  ipId: string;
  videoId: string;
};

export const IPContainer: FC<IPContainerProps> = ({ ip, ipId, videoId }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { mutateAsync: updateVideos } = useUpdateVideos();
  const { modifiedData } = useContext(YoutubeVideosContext);

  const handleSave = useCallback(() => {
    const { ip, ip_id } = modifiedData[videoId];

    updateVideos({
      videoId,
      payload: {
        ip,
        ip_id,
      },
    }).then(() => setIsEdit(false));
  }, [modifiedData, updateVideos, videoId]);

  const Edit = useMemo(
    () => (
      <>
        <AutoSuggestEditor videoId={videoId} />
        <span className="ml-2 cursor-pointer text-blue-500 flex gap-2">
          <FiSave onClick={handleSave} />
          <IoMdClose onClick={() => setIsEdit(false)} />
        </span>
      </>
    ),
    [handleSave, videoId],
  );

  const View = useMemo(
    () => (
      <>
        {!!ipId ? <Link href={`/item/${ipId}`}>{ip}</Link> : "N/A"}
        <span className="ml-2 cursor-pointer" onClick={() => setIsEdit(true)}>
          <MdModeEdit className="text-blue-500" />
        </span>
      </>
    ),
    [ip, ipId],
  );

  return (
    <ValueWithLabel label="IP">
      <div className="flex items-center">{isEdit ? Edit : View}</div>
    </ValueWithLabel>
  );
};
