import {
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import Chart from "react-google-charts";
import { useParams } from "react-router-dom";
import shortenInteger from "../../../utils/shortenInteger";
import TrailerGrid from "../../item/components/Trailer/Trailer";
import { getSentimentCategory } from "../channelPage/components/VideoList/utils";
import { IPContainer } from "./components/IPContainer";
import { ValueWithLabel } from "./components/ValueWithLabel";
import { VideoTrends } from "./components/VideoTrends";
import { determineType } from "./utils";

export const VideoPages = ({ videoDetails }) => {
  const { id: videoId } = useParams();
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);

  const options = {
    width: 200,
    height: 200,
    min: -1,
    max: 1,
    redFrom: -1,
    redTo: -0.2,
    yellowFrom: -0.2,
    yellowTo: 0.2,
    greenFrom: 0.2,
    greenTo: 1,
    minorTicks: 5,
    animation: { duration: 1000, easing: "out" },
  };

  const formatPublishedDate = () => {
    return new Date(videoDetails?.publishedAt * 1000).toLocaleDateString(
      "en-US",
      {
        day: "numeric",
        month: "long",
        year: "numeric",
      },
    );
  };

  return (
    <ContentLayout>
      <SpaceBetween direction="vertical" size="m">
        <div className="h-120">
          <Container
            fitHeight
            header={
              <Header
                variant="h2"
                description={videoDetails?.description}
                counter={
                  videoDetails?.madeForKids ? (
                    <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
                      Kids
                    </span>
                  ) : (
                    ""
                  )
                }
              >
                <Link
                  fontSize="heading-l"
                  href={`https://www.youtube.com/watch?v=${videoId}`}
                  target="_blank"
                  external
                >
                  {videoDetails?.title}
                </Link>
                {videoDetails?.official && (
                  <span className="ml-2 inline-flex items-center rounded-md bg-amber-400/10 px-1 py-0.5 text-sm font-bold text-amber-400 ring-1 ring-inset ring-amber-400/30">
                    Official
                  </span>
                )}
              </Header>
            }
            media={{
              content: (
                <iframe
                  title={videoId}
                  src={`https://www.youtube.com/embed/${videoId}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ),
              width: "50%",
              position: "side",
            }}
            footer={
              <ColumnLayout
                minColumnWidth={120}
                columns={3}
                variant="text-grid"
              >
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Views
                  </Box>
                  <Box variant="h1" color="text-status-info" textAlign="center">
                    {shortenInteger(videoDetails?.views)}
                  </Box>
                </div>
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Likes
                  </Box>
                  <Box variant="h1" color="text-status-info" textAlign="center">
                    {shortenInteger(videoDetails?.likes)}
                  </Box>
                </div>
                <div>
                  <Box variant="awsui-key-label" textAlign="center">
                    Comments
                  </Box>
                  <Box variant="h1" color="text-status-info" textAlign="center">
                    {shortenInteger(videoDetails?.comments)}
                  </Box>
                </div>
              </ColumnLayout>
            }
          >
            <Grid
              gridDefinition={[
                { colspan: { xxs: 6 } },
                { colspan: { xxs: 6 } },
              ]}
            >
              <SpaceBetween direction="vertical" size="xs">
                <IPContainer
                  ip={videoDetails?.ip}
                  ipId={videoDetails?.ip_id}
                  videoId={videoId}
                />
                <ValueWithLabel label="YouTube Channel">
                  <Link
                    href={`/mediacatalog/youtube/channels/${videoDetails?.channelId}`}
                  >
                    {videoDetails?.channel}
                  </Link>
                </ValueWithLabel>
                <ValueWithLabel label="Type">
                  <span className="font-bold">{determineType()}</span>
                </ValueWithLabel>
                <ValueWithLabel label="Published On">
                  {formatPublishedDate()}
                </ValueWithLabel>
              </SpaceBetween>
              {videoDetails?.sentimentScore && (
                <Box>
                  <Box variant="awsui-key-label">Sentiment</Box>
                  <Chart
                    options={options}
                    chartType="Gauge"
                    graphID={`gauge-sentiment`}
                    data={[
                      ["Label", "value"],
                      [
                        getSentimentCategory(videoDetails?.sentimentScore),
                        Number(videoDetails?.sentimentScore),
                      ],
                    ]}
                  />
                </Box>
              )}
            </Grid>
          </Container>
        </div>
        <ExpandableSection
          variant="default"
          defaultExpanded
          headerText="Video Trends"
        >
          <VideoTrends videoId={videoId} />
        </ExpandableSection>
        <ExpandableSection
          variant="default"
          defaultExpanded
          headerText="Related Videos"
        >
          <TrailerGrid
            id={videoDetails?.ip_id}
            videoId={videoId}
            triggerTrailersApi={triggerTrailersApi}
            setTriggerTrailersApi={setTriggerTrailersApi}
          />
        </ExpandableSection>
      </SpaceBetween>
    </ContentLayout>
  );
};
