import { Spinner, Link, Icon } from "@cloudscape-design/components";
import React from "react";

const SocialTrends = ({ itemData, instagramHashtagsData }) => {
  return (
    <>
      {itemData?.data?.instagram && instagramHashtagsData?.data ? (
        <dl className=" grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
          {itemData.data.instagram.map((tag, index) => {
            const tagData = instagramHashtagsData.data.find(
              (h) => h.hashtag === tag,
            );
            return (
              <div
                key={index}
                className="overflow-hidden rounded-xl dark:bg-darkerBg  shadow-lg p-6 sm:px-4"
              >
                <dt className="truncate text-base font-medium">
                  <Link
                    href={`https://instagram.com/explore/tags/${tag}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #{tag} <Icon name="external" />
                  </Link>
                </dt>
                <dd className="flex items-baseline justify-between">
                  <span className="text-xs italic">
                    {instagramHashtagsData?.loading ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : tagData ? (
                      `${tagData.num_posts.toLocaleString()} posts`
                    ) : (
                      "N/A"
                    )}
                  </span>
                </dd>
              </div>
            );
          })}
        </dl>
      ) : (
        <div className="text-center text-gray-500">No tags available</div>
      )}
    </>
  );
};

export default SocialTrends;
