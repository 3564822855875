import { ExpandableSection } from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { PlatformError } from "../../game/api/mappers/mapGetPlatformResponse";
import {
  IMDB_GRID_HEADER_MAP,
  MappedGetImdbResponseItem,
} from "../../game/api/mappers/mapImdbResponse";
import { DateRangeType } from "../DateRange";
import { axisTypeOptions, TrendsActions } from "../TrendsActions";
import { useBasicChartConfig } from "../useBasicChartConfig";
import { useChartConfig } from "./useChartConfig";

interface ImdbTrendsProps {
  data: { data: Array<MappedGetImdbResponseItem>; errors: PlatformError };
  onLatestStat: any;
  dateRange: DateRangeType;
  configType: "basic" | "advanced";
}

export const ImdbTrends: FC<ImdbTrendsProps> = ({
  data,
  onLatestStat,
  dateRange,
  configType,
}) => {
  const chartRef = useRef(null);
  const exportTableRef = useRef<AgGridReact>(null);
  const basicChartConfig = useBasicChartConfig(data?.data);
  const advancedChartConfig = useChartConfig(data?.data);
  const { getLineChartOptions } =
    configType === "basic" ? basicChartConfig : advancedChartConfig;
  const [chartOptions, setChartOptions] = useState<AgChartOptions>(null);
  const [selectedAxisType, setSelectedAxisType] = useState<OptionDefinition>(
    axisTypeOptions[0],
  );

  const prevLatestDataRef = useRef(null);
  const memoizedGetLineChartOptions = useCallback(getLineChartOptions, [
    getLineChartOptions,
  ]);

  const keys = Object.keys(IMDB_GRID_HEADER_MAP);

  useEffect(() => {
    setChartOptions({
      ...memoizedGetLineChartOptions(selectedAxisType.value),
      ...{ data: data?.data ?? {} },
    } as AgChartOptions);

    if (data?.data?.length > 0) {
      const latestDataPoint = data?.data?.reduce((latest, current) =>
        current.timestamp > latest.timestamp ? current : latest,
      );
      if (
        JSON.stringify(prevLatestDataRef.current) !==
        JSON.stringify(latestDataPoint)
      ) {
        prevLatestDataRef.current = latestDataPoint;
        onLatestStat(latestDataPoint);
      }
    }
  }, [data, memoizedGetLineChartOptions, selectedAxisType.value, onLatestStat]);

  const getFileName = () =>
    `IMDbTrends_${dateRange.startDate}_${dateRange.endDate}`;

  const downloadAsCsv = () =>
    exportTableRef.current.api.exportDataAsCsv({ fileName: getFileName() });
  const downloadAsExcel = () =>
    exportTableRef.current.api.exportDataAsExcel({ fileName: getFileName() });
  const downloadChart = () =>
    chartRef.current.download({
      fileName: getFileName(),
    });

  return (
    <ExpandableSection
      headingTagOverride="h4"
      variant="container"
      defaultExpanded
      headerText={configType === "basic" ? "IMDb Rank" : "IMDb"}
      headerActions={
        configType === "advanced" ? (
          <TrendsActions
            downloadChart={downloadChart}
            downloadAsCsv={downloadAsCsv}
            downloadAsExcel={downloadAsExcel}
          />
        ) : null
      }
    >
      {chartOptions && (
        <div className="h-60">
          {data?.data?.length > 0 && (
            <AgCharts
              ref={chartRef}
              options={chartOptions}
              style={{ height: "100%" }}
            />
          )}
          {data?.data?.length === 0 && (
            <div className="h-full flex justify-center items-center text-center">
              <p className="w-8/12">{data?.errors?.[0]?.description}</p>
            </div>
          )}
        </div>
      )}
      {/** following piece of code is only for the purpose download chart */}
      {data?.data?.length > 0 && (
        <div
          style={{ height: "450px", width: "100%" }}
          className="ag-theme-quartz-dark hidden"
        >
          <AgGridReact
            ref={exportTableRef}
            rowData={data?.data}
            columnDefs={[
              {
                field: "timestamp",
                headerName: "Date",
                valueFormatter: (params) =>
                  moment.utc(params.value).format("YYYY-MM-DD"),
                valueGetter: (params) =>
                  moment
                    .utc(params.data.timestamp)
                    .format("YYYY-MM-DDT00:00:00"),
                width: 150,
                cellClass: "dateType",
              },
              ...keys.map((key: string) => {
                return {
                  field: key,
                  headerName: IMDB_GRID_HEADER_MAP[key],
                  width: 150,
                  valueFormatter: (params) => params.value,
                  valueGetter: (params) =>
                    Math.round(params.data[key] * 10000.0) / 10000.0,
                  cellClass: "numberType",
                };
              }),
            ]}
            excelStyles={[
              {
                id: "numberType",
                numberFormat: {
                  format: "0.0000",
                },
              },
              {
                id: "dateType",
                dataType: "DateTime",
                numberFormat: {
                  format: "m/d/yyy",
                },
              },
            ]}
          />
        </div>
      )}
    </ExpandableSection>
  );
};
