import React from "react";
import IndexPageGrid from "../../../components/IndexPageGrid";

export const GRPageCards = () => {
  const items = [
    {
      link: "/dashboards/globalranking/movies",
      image: "/images/gr-movies.png",
      name: "Movies",
      path: "Ranking > Global ",
      description: "Curated ranking based on defined platform weights.",
    },
    {
      link: "/dashboards/globalranking/series",
      image: "/images/gr-series.png",
      name: "Series",
      path: "Ranking > Global",
      description: "Curated ranking based on defined platform weights.",
    },
    {
      link: "/dashboards/globalranking/gaming",
      image: "/images/gr-games.png",
      name: "Gaming",
      path: "Ranking > Global",
      description: "Curated ranking based on defined platform weights.",
    },
  ];
  const gridDefinition = items.map(() => ({
    colspan: { xxs: 6, xs: 6, s: 4 },
  }));

  return <IndexPageGrid items={items} gridDefinition={gridDefinition} />;
};
