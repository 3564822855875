import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import { Layout } from "../Layout";
import { VideoPages } from "./VideoPages";
import { determineType } from "./utils";

function YoutubeVideoItemPage() {
  const { id: videoId } = useParams();
  const [videoDetails, setChannelDetails] = useState(null);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/youtube/v1/videos/${videoId}`,
        );
        setChannelDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch video details:", error);
      }
    };

    fetchChannelDetails();
  }, [videoId]);

  const mediaTypeMappings = {
    Film: { path: "movietrailers", text: "Movie Trailers" },
    TV: { path: "seriestrailers", text: "TV Show Trailers" },
    Game: { path: "gamestrailers", text: "Game Trailers" },
  };
  const mediaType = determineType();
  const { path, text } = mediaTypeMappings[mediaType] || {};

  const breadcrumbs = [
    { text: "YouTube", href: "/mediacatalog/youtube" },
    path && text ? { text: text, href: `/mediacatalog/youtube/${path}` } : null,
    { text: videoDetails?.title },
  ].filter(Boolean);

  return (
    <Layout
      title={videoDetails?.title}
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={<VideoPages videoDetails={videoDetails} />}
      contentType="dashboard"
    />
  );
}

export default YoutubeVideoItemPage;
