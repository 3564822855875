import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  LineChart,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import { occassion } from "../../../../config-global";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { filterRegionsByOccassion } from "../utils/utils";
import { getBoxOfficeTimeseriesData } from "./redux/actions/box-office-timeseries-action";
import { getBoxOfficeTimeseries } from "./redux/selectors";

const yTickFormatter = (value) => {
  if (value >= 1e9) {
    return "$" + (value / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (value >= 1e6) {
    return "$" + (value / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (value >= 1e3) {
    return "$" + (value / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return "$" + value.toString();
  }
};

const defaultDateRange = {
  type: "absolute",
  startDate: moment().subtract(16, "days").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().subtract(2, "days").startOf("day").format("YYYY-MM-DD"),
};

function BoxOfficeTimeseriesPage() {
  const { theme } = useGetGridTheme();
  const gridRef = useRef();
  const boxOfficeTimeseries = useSelector(getBoxOfficeTimeseries);
  const [selectedRegionOption, setSelectedRegionOption] = useState({
    label: "Domestic",
    value: "XDOM",
  });
  const [selectedOption, setSelectedOption] = React.useState({
    label: "Daily",
    value: "daily",
  });
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [options, setOptions] = useState({ series: [] });
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterText, setFilterText] = React.useState("");

  const onGridReady = useCallback((params) => {
    setTimeout(() => {
      let count = 0;
      params.api.forEachNodeAfterFilterAndSort((node) => {
        node.setSelected(count < 9);
        count++;
      });
      const selectedNodes = params.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedItems(selectedData);
    });
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
    }
  }, []);

  useEffect(() => {
    getBoxOfficeTimeseriesData({
      occasion: selectedOption?.value,
      region: selectedRegionOption?.value,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  }, []);

  useEffect(() => {
    if (boxOfficeTimeseries?.data?.length > 0) {
      const filteredData = boxOfficeTimeseries.data.filter(
        (item) => item.ip_id !== undefined,
      );
      const seriesData = filteredData.map((item) => ({
        title: item.title,
        type: "line",
        data: item.grossTimeseries.map((ts) => {
          const timestamp = moment(ts.endDate, "YYYY-MM-DD").valueOf();
          const gv = ts.gross !== undefined ? ts.gross : 0;
          return { x: timestamp, y: gv };
        }),
      }));

      const initialItems = filteredData.slice(0, 6);
      setSelectedItems(initialItems);

      setOptions((prevOptions) => ({ ...prevOptions, series: seriesData }));
    } else {
      setOptions((prevOptions) => ({ ...prevOptions, series: [] }));
    }
  }, [boxOfficeTimeseries]);

  useEffect(() => {
    if (selectedItems.length > 0) {
      const filteredItems = selectedItems.filter(
        (item) => item.ip_id !== undefined,
      );
      const filteredSeriesData = filteredItems.map((item) => ({
        title: item.title,
        type: "line",
        data: item.grossTimeseries.map((ts) => {
          const timestamp = moment(ts.endDate, "YYYY-MM-DD").valueOf();
          const gv = ts.gross !== undefined ? ts.gross : 0;
          return { x: timestamp, y: gv };
        }),
        valueFormatter: (value) => `$${value.toLocaleString()}`,
      }));
      setOptions({ series: filteredSeriesData });
    } else {
      setOptions({ series: [] });
    }
  }, [selectedItems]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      filter: true,
      suppressHeaderMenuButton: true,
      cellStyle: {
        lineHeight: "40px",
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const processedData = useMemo(() => {
    const filteredData =
      boxOfficeTimeseries?.data?.filter((item) => item.ip_id !== undefined) ||
      [];

    return filteredData.map((item) => {
      const totalGross = item.grossTimeseries.reduce(
        (sum, entry) => sum + (entry.gross || 0),
        0,
      );
      const rankSparklineData = item.grossTimeseries.map((entry) => entry.rank);
      const theatersSparklineData = item.grossTimeseries.map(
        (entry) => entry.numTheaters,
      );
      const releaseDateMoment = moment(item.releaseDate, "MMMM DD, YYYY");
      const endDateMoment = moment(dateRange.endDate, "YYYY-MM-DD");
      const daysSinceRelease = endDateMoment.diff(releaseDateMoment, "days");

      return {
        ...item,
        totalGross,
        rankSparklineData,
        theatersSparklineData,
        daysSinceRelease,
      };
    });
  }, [boxOfficeTimeseries?.data, dateRange.endDate]);

  function tooltipRenderer(params) {
    return {
      title: "Rank",
      color: "white",
      backgroundColor: "#0F1B2A",
      content: params.yValue.toFixed(0),
    };
  }

  const formattedTimeSeries = () => {
    const parsedStartDate = moment(dateRange.startDate, "YYYY-MM-DD");
    const parsedEndDate = moment(dateRange.endDate, "YYYY-MM-DD");
    return `Timeseries (${parsedStartDate.format(
      "MMM DD, YYYY",
    )} - ${parsedEndDate.format("MMM DD, YYYY")})`;
  };

  const onGridSelectionChanged = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedItems(selectedData);
  }, []);

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container
        header={
          <Header
            variant="h3"
            actions={
              <div
                className="gap-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <FormField description="Title search">
                  <TextFilter
                    filteringText={filterText}
                    filteringPlaceholder="Search title"
                    filteringAriaLabel="Filter title"
                    onChange={({ detail }) =>
                      setFilterText(detail.filteringText)
                    }
                  />
                </FormField>
                <FormField description="Box office cycle">
                  <Select
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                      setSelectedOption(detail.selectedOption)
                    }
                    options={occassion}
                  />
                </FormField>
                <FormField description="Region/country">
                  <Select
                    selectedOption={selectedRegionOption}
                    onChange={({ detail }) => {
                      setSelectedRegionOption(detail.selectedOption);
                      getBoxOfficeTimeseriesData({
                        occasion: selectedOption?.value,
                        region: detail.selectedOption?.value,
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                      });
                    }}
                    options={filterRegionsByOccassion(selectedOption.value)}
                  />
                </FormField>
                <FormField description="Date range">
                  <RangeDateSelector
                    defaults={dateRange}
                    onChange={(e) => {
                      const formattedStartDate = moment(e.startDate).format(
                        "YYYY-MM-DD",
                      );
                      const formattedEndDate = moment(e.endDate).format(
                        "YYYY-MM-DD",
                      );
                      setDateRange({
                        ...e,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                      });
                      getBoxOfficeTimeseriesData({
                        occasion: selectedOption?.value,
                        region: selectedRegionOption?.value,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                      });
                    }}
                  />
                </FormField>
              </div>
            }
          >
            {formattedTimeSeries()}
          </Header>
        }
      >
        <FormField stretch description="Advanced query builder">
          <div id="advancedFilterParent"></div>
        </FormField>
      </Container>

      {boxOfficeTimeseries?.status === "loading" ? (
        <div className="flex flex-row justify-center items-end">
          <CustomLoadingOverlay />
        </div>
      ) : (
        <SpaceBetween direction="vertical" size="s">
          <div style={{ height: "447px" }} className={theme}>
            <AgGridReact
              ref={gridRef}
              quickFilterText={filterText}
              rowData={processedData}
              rowHeight={40}
              // paginationPageSize={9}
              // pagination={true}
              enableAdvancedFilter={true}
              onSelectionChanged={onGridSelectionChanged}
              onGridReady={onGridReady}
              gridOptions={{
                getContextMenuItems: (params) => {
                  if (params.column.colId === "title") {
                    return null;
                  }
                  return params.defaultItems;
                },
              }}
              columnDefs={[
                {
                  field: "selected",
                  headerName: " ",
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  floatingFilter: false,
                  minWidth: 50,
                  maxWidth: 50,
                  flex: 0,
                  resizable: false,
                  sortable: false,
                  editable: false,
                  suppressMovable: true,
                  filter: false,
                  lockPosition: "left",
                },
                {
                  field: "rank",
                  headerName: "#",
                  maxWidth: 70,
                  minWidth: 70,
                  filter: false,
                  sortable: false,
                  valueGetter : "node.rowIndex + 1",
                },
                {
                  field: "title",
                  headerName: "Title",
                  minWidth: 150,
                  cellRenderer: (params) => {
                    if (!params.value) return null;

                    const ipId = params.data.ip_id;
                    const destination = ipId
                      ? `${window.location.origin}/item/${ipId}`
                      : undefined;
                    const style = ipId
                      ? {}
                      : { color: "grey", cursor: "default" };

                    return (
                      <a
                        href={destination}
                        style={style}
                        onClick={(e) => !ipId && e.preventDefault()}
                      >
                        {params.value}
                      </a>
                    );
                  },
                },
                {
                  field: "totalGross",
                  headerName: "Gross",
                  cellRenderer: (params) => {
                    if (params.value != null) {
                      return "$" + shortenInteger(params.value);
                    } else {
                      return "N/A";
                    }
                  },
                  cellStyle: {
                    textAlign: "right",
                  },
                  maxWidth: 120,
                  minWidth: 100,
                },
                {
                  field: "daysSinceRelease",
                  headerName: "Days Since Release",
                  maxWidth: 120,
                  minWidth: 100,
                  cellStyle: { textAlign: "center" },
                },
                {
                  field: "grossToDate",
                  headerName: "Cumulative Gross",
                  cellRenderer: (params) => {
                    if (params.value != null) {
                      return "$" + shortenInteger(params.value);
                    } else {
                      return "N/A";
                    }
                  },
                  cellStyle: {
                    textAlign: "right",
                  },
                  maxWidth: 120,
                  minWidth: 100,
                },
                {
                  field: "rankSparklineData",
                  headerName: "Rank Trend",
                  filter: false,
                  sortable: false,
                  cellRenderer: "agSparklineCellRenderer",
                  minWidth: 150,
                  cellRendererParams: {
                    sparklineOptions: {
                      type: "area",
                      tooltip: {
                        renderer: tooltipRenderer,
                      },
                      marker: {
                        size: 4,
                        fill: "orange",
                        shape: "diamond",
                      },
                    },
                  },
                },
                {
                  field: "theatersSparklineData",
                  headerName: "Theaters Trend",
                  filter: false,
                  sortable: false,
                  cellRenderer: "agSparklineCellRenderer",
                  cellRendererParams: {
                    sparklineOptions: {
                      type: "column",
                      paddingInner: 0.3,
                    },
                  },
                  minWidth: 150,
                },
              ]}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              defaultColDef={defaultColDef}
              suppressDragLeaveHidesColumns={true}
            />
          </div>

          <Container>
            <LineChart
              height={300}
              hideFilter
              series={options.series}
              i18nStrings={{
                filterLabel: "Filter Movies",
                filterPlaceholder: "Select Movies",
                xTickFormatter: (timestamp) => {
                  const date = new Date(timestamp);
                  return date
                    .toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })
                    .split(",")
                    .join("\n");
                },
                yTickFormatter: yTickFormatter,
                tooltipDetailsFormatter: ({ x, y }) => `${yTickFormatter(y)}`,
              }}
              xTitle="Date"
              yTitle="Gross Revenue"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No data available</b>
                  <Box variant="p" color="inherit">
                    There is no data Available or Selected
                  </Box>
                </Box>
              }
              noMatch={
                <Box textAlign="center" color="inherit">
                  <b>No matching data</b>
                  <Box variant="p" color="inherit">
                    There is no matching data to display
                  </Box>
                  <Button>Clear filter</Button>
                </Box>
              }
              legendTitle="Movie Titles"
            />
          </Container>
        </SpaceBetween>
      )}
    </SpaceBetween>
  );
}

export default BoxOfficeTimeseriesPage;
