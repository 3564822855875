import { ContentLayout, Header } from "@cloudscape-design/components";
import React from "react";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../../common/CustomFlashBar";
import { Layout } from "../../Layout";
import BoxOfficeTimeseriesPage from "./box-office-timeseries-page";

export const BoxOfficeGrossTimeseriesPage = () => {
  const breadcrumbs = [
    { text: "Tools" },
    { text: "Box Office Analysis", href: "/box-office" },
    { text: "TimeSeries" },
  ];
  return (
    <Layout
      title="Box Office By Studio"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={
        <ContentLayout
          header={
            <>
              <Header
                variant="h2"
                description="Timeseries of total revenue generated from movie ticket sales in theaters."
              >
                Box Office Timeseries
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <BoxOfficeTimeseriesPage />
        </ContentLayout>
      }
      contentType="dashboard"
    />
  );
};
