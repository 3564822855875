import { SpaceBetween } from "@cloudscape-design/components";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import { AgCharts } from "ag-charts-react";
import "ag-grid-enterprise";
import { AgChartOptions } from "ag-grid-enterprise";
import React, { FC } from "react";
import { useChartConfig } from "./useChartConfig";

interface PiracyTrendsProps {
  piracyTrends: {
    data: any[];
  };
}

export const PiracyTrends: FC<PiracyTrendsProps> = ({ piracyTrends }) => {
  const piracyTrendsChartOption = useChartConfig(piracyTrends?.data);

  if (piracyTrends?.data?.length === 0) return null;

  return (
    <ExpandableSection
      variant="container"
      defaultExpanded
      headerText="Piracy Trends"
    >
      <SpaceBetween direction="vertical" size="l">
        {piracyTrendsChartOption && (
          <div className="h-96">
            <AgCharts
              options={piracyTrendsChartOption as AgChartOptions}
              style={{ height: "100%" }}
            />
          </div>
        )}
      </SpaceBetween>
    </ExpandableSection>
  );
};
