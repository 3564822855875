import { Link } from "@cloudscape-design/components";
import React, { FC } from "react";
import shortenInteger from "../../../../utils/shortenInteger";

interface ScoreProps {
  rank: number;
  views?: string;
  weeklyRank?: number;
  subRank?: number;
  rankType?: string;
  type: SCORE_TYPES;
}

export enum SCORE_TYPES {
  DEMAND,
  ENGAGEMENT,
  PANTHEON,
  PIRACY,
  SEARCH,
  WIKIPEDIA,
  YOUTUBE,
  STEAM,
  TWITCH,
  IMDB,
}

const scoreMap = {
  [SCORE_TYPES.DEMAND]: {
    text: "Demand Rank",
    description: "Demand rank",
    imgSrc: "/svg/demand.svg",
  },
  [SCORE_TYPES.ENGAGEMENT]: {
    text: "Engagement Rank",
    description: "Weekly rank",
    imgSrc: "/svg/engagement.svg",
  },
  [SCORE_TYPES.PANTHEON]: {
    text: "Pantheon Rank",
    description: "Weekly rank",
    imgSrc: "/svg/pantheon.svg",
  },
  [SCORE_TYPES.PIRACY]: {
    text: `Piracy Rank`,
    description: "Rank (US)",
    imgSrc: "/svg/piracy.svg",
    path: "/dashboards/piracy",
  },
  [SCORE_TYPES.SEARCH]: {
    text: "Search Rank",
    description: "Weekly rank",
    imgSrc: "/svg/search.svg",
  },
  [SCORE_TYPES.WIKIPEDIA]: {
    text: "Wikipedia Rank",
    description: "All-time Rank",
    imgSrc: "/svg/wikipedia.svg",
    path: "/platforms/wikipedia?tab=trends",
  },
  [SCORE_TYPES.STEAM]: {
    text: "Steam Rank",
    description: "Peak player count",
    imgSrc: "/svg/steam.svg",
    path: "/platforms/steam",
  },
  [SCORE_TYPES.TWITCH]: {
    text: "Twitch Rank",
    description: "Peak viewers",
    imgSrc: "/svg/twitch.svg",
    path: "/platforms/twitch",
  },
  [SCORE_TYPES.YOUTUBE]: {
    text: "YouTube Trailer Rank",
    description: "All-time Rank",
    imgSrc: "/svg/youtube.svg",
    path: "/platforms/youtube?tab=trailers",
  },
  [SCORE_TYPES.IMDB]: {
    text: "IMDb Rank",
    description: "All-time Rank",
    imgSrc: "/rticons/imdb_star.svg",
    path: "/platforms/imdb?tab=trends",
  },
};

export const Score: FC<ScoreProps> = ({
  rank,
  views,
  weeklyRank,
  subRank,
  rankType,
  type,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <img src={scoreMap[type].imgSrc} alt="Votes" className="h-12" />
      <div className="text-black dark:text-slate-100">
        <div className="text-xl dark:text-slate-50 font-bold leading-[1.25rem]">
          <span className="mr-[0.2rem]">#</span>
          {!!rank ? rank.toLocaleString() : "N/A"}
        </div>

        <ScoreItem
          type={type}
          views={views}
          subRank={subRank}
          rankType={rankType}
          weeklyRank={weeklyRank}
        />
      </div>
    </div>
  );
};

const ScoreItem = ({ type, views, subRank, rankType, weeklyRank }) => {
  const { text, description, path } = scoreMap[type];

  if (
    [
      SCORE_TYPES.WIKIPEDIA,
      SCORE_TYPES.YOUTUBE,
      SCORE_TYPES.STEAM,
      SCORE_TYPES.TWITCH,
      SCORE_TYPES.IMDB,
    ].includes(type)
  ) {
    return (
      <>
        <div className="font-bold">
          <Link fontSize="body-s" href={path}>
            {text}
          </Link>
        </div>
        <div className="text-xs italic dark:text-slate-400">
          {`${description}: ${!!views ? shortenInteger(views) : "N/A"}`}
        </div>
      </>
    );
  }

  if (type === SCORE_TYPES.PIRACY) {
    return (
      <>
        <div className="font-bold">
          <Link fontSize="body-s" href="/dashboards/piracy">
            Piracy {rankType} Rank
          </Link>
        </div>
        <div className="text-xs italic dark:text-slate-400">
          {`${description}: #${subRank ? subRank : "N/A"}`}
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-xs font-bold">{text}</div>
      <div className="text-xs italic dark:text-slate-400">
        {`${description}: #${
          !!weeklyRank ? weeklyRank.toLocaleString() : "N/A"
        }`}
      </div>
    </>
  );
};
