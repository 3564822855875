/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../ag-styles.css";

import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  ContentLayout,
  Header,
  Select,
  SideNavigation,
  SpaceBetween,
  Spinner,
  Toggle,
} from "@cloudscape-design/components";
import moment from "moment";
import { useSelector } from "react-redux";
import { RangeDateSelector } from "../../components/RangeDateSelector";
import { granularityItems } from "../../components/forms/GranularitySelect";
import { useGetGridTheme } from "../../hooks/UseTheme/useGetGridTheme";
import { navItems } from "../../layouts/common/menu/side-menu";
import createFlashMessage from "../../utils/createFlashMessage";
import CustomFlashBar from "../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../common/redux/flash-action";
import getAo3RawData from "./redux/actions/ao3-raw-actions";

function CommaSeparatedNumberCellRenderer(props) {
  if (props?.value) {
    return <div>{props?.value?.toLocaleString()}</div>;
  }
  return null;
}

function getScoreValueGetter(key) {
  return (params) => {
    const fields = [
      "popular_likes",
      "recent_likes",
      "popular_views",
      "recent_views",
      "new_stories",
      "num_works",
    ];
    const weights = {
      popular_likes: 0.0002,
      recent_likes: 0.00002,
      popular_views: 0.00002,
      recent_views: 0.00002,
      new_stories: 0.02,
      num_works: 0.002,
    };

    const total = fields.reduce((sum, field) => {
      // Check if the field key exists and is not null
      const fieldValue =
        params.data[key] && params.data[key][field]
          ? params.data[key][field]
          : 0;
      return sum + fieldValue * weights[field];
    }, 0);

    return total;
  };
}

function Ao3RawDataPage() {
  const { theme } = useGetGridTheme();
  const columnHoverHighlight = true;
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const ao3RawData = useSelector((state) => state.ao3RawData);
  const [isLoading, setIsLoading] = useState(false);
  const [granularity, setGranularity] = useState({ label: "Day", value: "1d" });
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getAo3RawData({
      lte: dateRange ? moment(dateRange.endDate).valueOf() / 1000 : "now/d",
      gte: dateRange
        ? moment(dateRange.startDate).valueOf() / 1000
        : "now-15d/d",
      interval: granularity.value,
    }).finally(() => setIsLoading(false));
  }, [granularity, dateRange]);

  // if call is redux for flash message
  useEffect(() => {
    if (ao3RawData.completed && ao3RawData.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: ao3RawData.message,
        }),
      );
    }
  }, [ao3RawData]);

  const groupDisplayType = "groupRows";

  const customChartThemes = {
    myCustomTheme: {
      baseTheme: "ag-default-dark",
      palette: {
        fills: [
          "#2196F3",
          "#4CAF50",
          "#F44336",
          "#9C27B0",
          "#3F51B5",
          "#FF9800",
          "#009688",
          "#8BC34A",
          "#CDDC39",
          "#00BCD4",
        ],
        strokes: ["#874349", "#718661", "#a48f5f"],
      },
      overrides: {
        common: {
          background: { fill: "#0F172B" },
          title: { fontSize: 22, fontFamily: "Arial, sans-serif" },
          axes: {
            number: { tick: { width: 0.5 } },
            category: { tick: { width: 0.5 } },
          },
        },
      },
    },
  };
  const gridRef = useRef();
  useEffect(
    () => () => {
      cleanAllFlashMessage();
      if (localStorage.getItem("platforms_fan_sites_ao3_raw_data")) {
        localStorage.removeItem("platforms_fan_sites_ao3_raw_data");
      }
    },
    [],
  );

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      if (localStorage.getItem("platforms_fan_sites_ao3_raw_data")) {
        const rawData = JSON.parse(
          localStorage.getItem("platforms_fan_sites_ao3_raw_data"),
        );
        gridRef.current.api.setFilterModel(rawData);
      }
      const handleFilterChanged = () => {
        localStorage.setItem(
          "platforms_fan_sites_ao3_raw_data",
          JSON.stringify(gridRef.current.api.getFilterModel()),
        );
      };

      gridRef.current.api.addEventListener(
        "filterChanged",
        handleFilterChanged,
      );
    }

    return () => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.removeEventListener("filterChanged");
      }
    };
  }, [gridRef.current]);

  const clearFilters = useCallback(() => {
    if (localStorage.getItem("platforms_fan_sites_ao3_raw_data")) {
      localStorage.removeItem("platforms_fan_sites_ao3_raw_data");
    }
    gridRef.current.api.setFilterModel(null);
  }, []);
  const chartThemes = ["myCustomTheme"];
  const [columnDefs, setColumnDefs] = useState();

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      resizable: true,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      filter: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
    }),
    [],
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 400,
          width: 225,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 225,
          maxWidth: 400,
          width: 225,
        },
      ],
      defaultToolPanel: "",
    }),
    [],
  );
  const getContextMenuItems = useCallback(() => {
    const result = [
      "autoSizeAll",
      "resetColumns",
      "separator",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "separator",
      "chartRange",
      "separator",
      "export",
    ];
    return result;
  }, []);

  const groupDefaultExpanded = 1;

  useEffect(() => {
    if (
      ao3RawData.data &&
      ao3RawData.data.data &&
      ao3RawData.data.data.length > 0
    ) {
      const sampleData = ao3RawData.data.data[0];
      const keys = Object.keys(sampleData).filter(
        (key) => key !== "ip" && key !== "ip_id" && key !== "vertical",
      );

      setColumnDefs([
        { headerName: "IP Name", field: "ip", pinned: "left" },
        {
          headerName: "Vertical",
          field: "vertical",
          pinned: "left",
          filterParams: { buttons: ["reset", "apply"], debounceMs: 20000 },
          // rowGroup: true,
          // enableRowGroup: true,
          // hide: true,
        },
        ...keys.map((key) => ({
          headerName: key,
          marryChildren: true,
          children: [
            {
              headerName: "Score",
              field: `${key}.total`,
              // columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
              valueGetter: getScoreValueGetter(key),
              valueFormatter: (params) => parseFloat(params.value).toFixed(2),
            },
            {
              headerName: "Popular Likes",
              field: `${key}.popular_likes`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
            {
              headerName: "Recent Likes",
              field: `${key}.recent_likes`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
            {
              headerName: "Popular Views",
              field: `${key}.popular_views`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
            {
              headerName: "Recent Views",
              field: `${key}.recent_views`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
            {
              headerName: "New Stories",
              field: `${key}.new_stories`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
            {
              headerName: "# of Works",
              field: `${key}.num_works`,
              columnGroupShow: "open",
              filter: "agNumberColumnFilter",
              cellStyle: { textAlign: "right" },
              cellRenderer: "CommaSeparatedNumberCellRenderer",
            },
          ],
        })),
      ]);
    }
  }, [ao3RawData]);

  const Content = (
    <>
      {isLoading && (
        <div className="flex flex-row justify-center items-center">
          <Spinner size="large" />
        </div>
      )}
      <div
        style={{
          height: "86vh",
          paddingRight: 20,
          paddingLeft: 20,
          paddingTop: 10,
        }}
        className={theme}
      >
        {ao3RawData.data &&
          ao3RawData.data.data &&
          ao3RawData.data.data.length > 0 && (
            <AgGridReact
              // domLayout="autoHeight"
              rowData={ao3RawData.data.data}
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              enableRangeSelection
              allowContextMenuWithControlKey
              getContextMenuItems={getContextMenuItems}
              groupDisplayType={groupDisplayType}
              rowHeight={35}
              animateRows
              // rowGroupPanelShow={"always"}
              tooltipShowDelay={500}
              groupRowsSticky
              groupDefaultExpanded={groupDefaultExpanded}
              customChartThemes={customChartThemes}
              chartThemes={chartThemes}
              components={{ CommaSeparatedNumberCellRenderer }}
              columnHoverHighlight={columnHoverHighlight}
            />
          )}
      </div>
    </>
  );
  const breadcrumbs = [
    {
      text: "Platforms",
      // href: "",
    },
    {
      text: "Ao3",
      // href: "/platforms/youtubetrends",
    },
    {
      text: "Raw Data",
      href: "/platforms/consumer-products/ao3/raw-data",
    },
  ];

  return (
    <AppLayout
      disableContentPaddings={isFullWidth}
      stickyNotifications
      toolsHide
      headerSelector="#header"
      ariaLabels={{ navigationClose: "close" }}
      navigation={
        <SideNavigation
          activeHref={window.location.pathname}
          items={navItems}
        />
      }
      breadcrumbs={
        <div>
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        </div>
      }
      content={
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ContentLayout
            header={
              <>
                <Header
                  variant="h3"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button onClick={clearFilters}>Clear All Filters</Button>
                      <Select
                        selectedOption={granularity}
                        onChange={({ detail }) =>
                          setGranularity(detail.selectedOption)
                        }
                        selectedAriaLabel="Selected"
                        options={granularityItems}
                      />
                      <RangeDateSelector
                        defaults={
                          dateRange
                            ? dateRange?.type === "relative"
                              ? {
                                  ...dateRange,
                                  startDate: moment()
                                    .subtract(15, "days")
                                    .startOf("day")
                                    .format("YYYY-MM-DD"),
                                  endDate: moment(dateRange.endDate),
                                }
                              : dateRange
                            : {
                                type: "absolute",
                                startDate: moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .format("YYYY-MM-DD"),
                                endDate: moment().format("YYYY-MM-DD"),
                              }
                        }
                        onChange={(e) => {
                          if (e.type === "relative") {
                            setDateRange({
                              ...e,
                              startDate: moment(e.startDate).format(
                                "YYYY-MM-DD",
                              ),
                              endDate: moment(e.endDate).format("YYYY-MM-DD"),
                            });
                          } else {
                            setDateRange(e);
                          }
                        }}
                      />
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="px-5">Raw Data</span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>

                <div className="py-5">
                  <CustomFlashBar />
                </div>
              </>
            }
          >
            <Container fitHeight>{Content}</Container>
          </ContentLayout>
        </div>
      }
    />
  );
}

export default Ao3RawDataPage;
