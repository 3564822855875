import { PropertyFilterProps } from "@cloudscape-design/components";

export const getFilteredTokens = (tokens: Array<PropertyFilterProps.Token>) => {
  const include = [];
  const exclude = [];

  const checkForDateString = (str: string) =>
    /^\d{4}-\d{2}-\d{2}$/.test(str) ? new Date(str).getTime() / 1000 : str;

  const formatFilter = (token: PropertyFilterProps.Token) => {
    switch (token.operator) {
      case "=":
        include.push({
          match: {
            [token.propertyKey]: {
              query: token.value,
              auto_generate_synonyms_phrase_query: false,
            },
          },
        });
        break;
      case ":":
        include.push({
          query_string: {
            default_field: token.propertyKey,
            query: `"*${token.value}*"`,
          },
        });
        break;
      case "!=":
        exclude.push({
          query_string: {
            default_field: token.propertyKey,
            query: `*${token.value}*`,
          },
        });
        break;
      case ">=":
        include.push({
          range: {
            [token.propertyKey]: {
              gte: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<=":
        include.push({
          range: {
            [token.propertyKey]: {
              lte: checkForDateString(token.value),
            },
          },
        });
        break;
      case ">":
        include.push({
          range: {
            [token.propertyKey]: {
              gt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "<":
        include.push({
          range: {
            [token.propertyKey]: {
              lt: checkForDateString(token.value),
            },
          },
        });
        break;
      case "blank":
        exclude.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      case "notBlank":
        include.push({
          exists: {
            field: token.propertyKey,
          },
        });
        break;
      default:
        return null;
    }
  };

  tokens.forEach(formatFilter);

  return { include, exclude };
};

export const getFilterConditionsFromFilters = (filters) => {
  const conditions = [];

  if (!filters || filters.length === 0) {
    return conditions;
  }

  filters.filter(filter => filter.value).forEach(filter => {
    if (filter.value.length === 1) {
      conditions.push({
        filterType: filter.filterType,
        colId: filter.key,
        type: filter.filterOperation,
        filter: filter.value[0],
      });
    } else if (filter.value.length > 1) {
      conditions.push({
        filterType: "join",
        type: filter.valueJoinOperation,
        conditions: filter.value.map(value => ({
          filterType: filter.filterType,
          colId: filter.key,
          type: filter.filterOperation,
          filter: value,
        })),
      });
    }
  });

  return conditions;
};