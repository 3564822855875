import {
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
} from "@cloudscape-design/components";
import React from "react";
import { YoutubeChannelWidget } from "./ChannelWidget/ChannelWidget";
import { YoutubeVideoWidget } from "./VideoWidget/VideoWidget";
import { YoutubeCards } from "./YoutubeCards";

export const YoutubeHome = () => {
  const gridItems = 9;
  const gridDefinition = Array.from({ length: gridItems }, () => ({
    colspan: { default: 12, s: 6, l: 4 },
  }));
  const videoGridItems = 5;
  const videoGridDefinition = Array.from({ length: videoGridItems }, () => ({
    colspan: { default: 12, s: 6, l: 4 },
  }));

  return (
    <ContentLayout
      disableOverlap
      header={
        <Header
          variant="h2"
          description="YouTube enables you to discover channels, movie trailers, TV shows, games and more based on audience views, engagement and sentiment."
        >
          YouTube
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <ExpandableSection
          variant="default"
          defaultExpanded
          headerText="YouTube Channels Summary"
        >
          <Grid gridDefinition={gridDefinition}>
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "General" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=General"
              headerText="General Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[{ match: { madeForKids: true } }]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=Kids    "
              headerText="Kids Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "OTT" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=OTT"
              headerText="OTT Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "Gaming" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=Gaming"
              headerText="Game Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "Franchise" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=Franchise"
              headerText="Franchise Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "Company" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=Company"
              headerText="Company Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "Movies" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=Movies"
              headerText="Movies Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "TV" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=TV"
              headerText="TV Channels"
            />
            <YoutubeChannelWidget
              sort={[{ todaysViews: { order: "desc" } }]}
              include={[
                { match: { madeForKids: false } },
                { match: { type: "News" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/channels?type=News"
              headerText="News Channels"
            />
          </Grid>
        </ExpandableSection>
        <ExpandableSection
          variant="default"
          defaultExpanded
          headerText="YouTube Video Summary"
        >
          <Grid gridDefinition={videoGridDefinition}>
            <YoutubeVideoWidget
              sort={[{ todayViews: { order: "desc" } }]}
              include={[
                { match: { official: true } },
                { prefix: { ip_id: "film" } },
              ]}
              exclude={[{ match: { madeForKids: true } }]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/movietrailers"
              headerText="Movie Trailers"
            />
            <YoutubeVideoWidget
              sort={[{ todayViews: { order: "desc" } }]}
              include={[
                { match: { official: true } },
                {
                  prefix: {
                    ip_id: "series",
                  },
                },
              ]}
              exclude={[{ match: { madeForKids: true } }]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/seriestrailers"
              headerText="TV Show Trailers"
            />
            <YoutubeVideoWidget
              sort={[{ todayViews: { order: "desc" } }]}
              include={[
                { match: { official: true } },
                { prefix: { ip_id: "film" } },
                { match: { madeForKids: true } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/movietrailers"
              headerText="Kids Movie Trailers"
            />
            <YoutubeVideoWidget
              sort={[{ todayViews: { order: "desc" } }]}
              include={[
                { match: { official: true } },
                { prefix: { ip_id: "series" } },
                { match: { madeForKids: true } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/seriestrailers"
              headerText="Kids TV Show Trailers"
            />
            <YoutubeVideoWidget
              sort={[{ todayViews: { order: "desc" } }]}
              include={[
                { match: { official: true } },
                { prefix: { ip_id: "game" } },
              ]}
              exclude={[]}
              condition={"and"}
              headerLink="/mediacatalog/youtube/gamestrailers"
              headerText="Gaming Trailers"
            />
          </Grid>
        </ExpandableSection>
        <YoutubeCards />
      </SpaceBetween>
    </ContentLayout>
  );
};
