import {
  Box,
  ColumnLayout,
  Container,
  ExpandableSection,
  SpaceBetween,
  Spinner,
  TextContent
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COMMON_BOX_OFFICE_REGIONS } from "../../../config-global";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../hooks/UseTheme/useGetChartTheme";
import { fetchItemBoxOfficeData } from "../redux/actions/item-actions";
import BoxOfficeTable from "./BoxOfficeTable";

const countryColors = {
  "US & CA": "#34568B",
  Other: "#6B5B95",
  FR: "#88B04B",
  DE: "#F7CAC9",
  KR: "#92A8D1",
  GB: "#955251",
  AU: "#B565A7",
  MX: "#009B77",
  ES: "#DD4124",
  IT: "#D65076",
  BR: "#45B8AC",
  JP: "#EFC050",
};

const transformAreaCodes = (grossTimeseries) => {
  if (!grossTimeseries || typeof grossTimeseries !== 'object') {
    console.error('Invalid input for transformAreaCodes:', grossTimeseries);
    return {};
  }

  const items = {};
  Object.keys(grossTimeseries).forEach((countryCode) => {
    if (Array.isArray(grossTimeseries[countryCode])) {
      items[countryCode] = grossTimeseries[countryCode].map((item) => ({
        ...item,
        area: COMMON_BOX_OFFICE_REGIONS[countryCode] || countryCode,
      }));
    } else {
      console.error(`Expected an array for ${countryCode}, received:`, grossTimeseries[countryCode]);
      items[countryCode] = [];
    }
  });
  return items;
};


const usdShortOptions = {
  style: "currency",
  currency: "USD",
  notation: "compact",
};
const usdShortFormatter = new Intl.NumberFormat("en-US", usdShortOptions);

const BoxOffice = ({ titleId }) => {
  const [boxOfficeData, setBoxOfficeData] = useState(null);
  const [error, setError] = useState(null);
  const itemBoxOfficeData = useSelector(
    (state) => state?.itemBoxOfficeData?.data
  );
  const isLoading = useSelector((state) => state?.itemBoxOfficeData?.loading);
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  useEffect(() => {
    setBoxOfficeData(itemBoxOfficeData);
  }, [itemBoxOfficeData]);

  useEffect(() => {
    if (itemBoxOfficeData === null) {
      fetchItemBoxOfficeData({ id: titleId });
    }
  }, []);

  if (isLoading)
    return (
      <div className="justify-center flex">
        <Spinner size="large" />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  if (!boxOfficeData || Object.keys(boxOfficeData).length === 0) {
    return (
      <div className="p-10 text-center text-base font-semibold text-gray-500">
        No data available
      </div>
    );
  }

  const rankCategories = [
    { area: "XDOM", label: "Domestic (US & Canada)" },
    { area: "XNDOM", label: "International" },
    { area: "XWW", label: "WorldWide" },
  ];

const prepareChartData = (data, areaKey, includeExtra = false) => {
  if (!data.hasOwnProperty(areaKey)) {
    return [];
  }

  const chartData = Object.keys(data[areaKey])
    .filter(
      (countryCode) => !["XDOM", "XWW", "XNDOM", "IN", "TH"].includes(countryCode)
    )
    .map((countryCode) => {
      const valueKey = areaKey === "grossToDate" ? "grossToDate" : "gross";
      const countryData = data[areaKey][countryCode];
      if (!Array.isArray(countryData) || countryData.length === 0) {
        return null;
      }
      return {
        name: COMMON_BOX_OFFICE_REGIONS[countryCode] || countryCode,
        value: countryData[0][valueKey],
        color: countryColors[countryCode] || countryColors["Other"],
      };
    })
    .filter((item) => item !== null);

  if (includeExtra) {
    const extraValueKey = areaKey === "grossToDate" ? "grossToDate" : "gross";
    const xdomData = data[areaKey]["XDOM"];
    if (Array.isArray(xdomData) && xdomData.length > 0) {
      chartData.push({
        name: COMMON_BOX_OFFICE_REGIONS["XDOM"] || "US & CA",
        value: xdomData[0][extraValueKey],
        color: countryColors["US & CA"],
      });
    }
    chartData.push({
      name: "Other",
      value: calculateOtherValue(data, areaKey, chartData), 
      color: countryColors["Other"],
    });
  }

  return chartData.sort((a, b) => a.name.localeCompare(b.name));
};

const calculateOtherValue = (data, areaKey, currentData) => {
  if (!data[areaKey] || !data[areaKey]["XNDOM"] || data[areaKey]["XNDOM"].length === 0) {
    return 0;
  }

  const total = data[areaKey]["XNDOM"][0].grossToDate;
  const knownTotal = currentData.reduce((acc, country) => acc + country.value, 0);
  return total - knownTotal; 
};

const grossCollectionPieChartData = (prepareChartData(boxOfficeData, "grossToDate", true)).filter(item => !isNaN(item.value) && item.value !== null);
const openingWeekendPieChartData = (prepareChartData(boxOfficeData, "openingWeekend", true)).filter(item => !isNaN(item.value) && item.value !== null);

const filteredOpeningWeekendPieChartData = openingWeekendPieChartData.filter((d) =>
  grossCollectionPieChartData.some((g) => g.name === d.name)
);

const chartOptions = {
  theme: theme, 
  legend: {
    position: "right", 
  },
  series: [
    {
      type: "donut",
      angleKey: "value", 
      legendItemKey: "name", 
      calloutLabelKey: "name", 
      outerRadiusRatio: 1, 
      innerRadiusRatio: 0.8, 
      data: grossCollectionPieChartData,
      fills: grossCollectionPieChartData.map((item) => item.color),
      title: { text: "Gross to Date" },
      showInLegend: true,
    },
    {
      type: "donut",
      angleKey: "value", 
      legendItemKey: "name", 
      calloutLabelKey: "name", 
      outerRadiusRatio: 0.7, 
      innerRadiusRatio: 0.4,
      data: filteredOpeningWeekendPieChartData, 
      fills: filteredOpeningWeekendPieChartData.map((item) => item.color), 
      title: { text: "Opening Weekend" }, 
      showInLegend: false, 
    },
  ],
};

  return (
    <SpaceBetween size="l">
      <Container>
        <ColumnLayout
          columns={4}
          variant="text-grid"
          minColumnWidth={190}
        >
          <div className="flex-column">
            <Box variant="awsui-key-label">
              Production Budget
            </Box>
            <TextContent>
              {boxOfficeData?.budget?.amount
                ? `$${new Intl.NumberFormat().format(boxOfficeData.budget.amount)}`
                : "N/A"}
            </TextContent>
          </div>
          {rankCategories.map(({ area, label }) => (
            <div className="flex-column" key={area}>
              <Box variant="awsui-key-label">
                {label}
              </Box>
              <TextContent>
                {(boxOfficeData.hasOwnProperty("grossToDate") && boxOfficeData?.grossToDate[area]?.[0]?.grossToDate)
                  ? `$${new Intl.NumberFormat().format(boxOfficeData.grossToDate[area][0].grossToDate)}`
                  : "N/A"}
                {" "}
                ({(boxOfficeData.hasOwnProperty("grossToDate") && boxOfficeData?.grossToDate[area]?.[0]?.rank)
                  ? `#${boxOfficeData.grossToDate[area][0].rank}`
                  : "N/A"})
              </TextContent>
            </div>
          ))}
        </ColumnLayout>
      </Container>

      <ExpandableSection
        defaultExpanded
        headingTagOverride="h4"
        variant="container"
        headerText="Box Office Revenue Breakdown: Opening Weekend vs. Cumulative Gross to Date"
      >
        <div style={{ height: "600px" }}>
          <AgCharts options={chartOptions} style={{ height: "100%" }} />
        </div>
      </ExpandableSection>

      <BoxOfficeTable
        data={transformAreaCodes(boxOfficeData?.grossTimeseries)}
      />
    </SpaceBetween>
  );
};

export default BoxOffice;
