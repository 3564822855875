import {
  Button,
  Container,
  ContentLayout,
  FormField,
  Header,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { AdvancedFilters } from "../../../components/filters/AdvancedFilters";
import { FiltersDropdown } from "../../../components/filters/FiltersDropdown";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { useSetGridAdvancedFilterTheme } from "../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import { isNone, isSomeOrElse } from "../../../utils/sugarUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { useUpdateRowData } from "../api/hooks/useUpdateRowData";
import { getDataMappingIps } from "../api/request";
import AutoSuggestEditor from "../components/AutoSuggestEditor";
import { PlatformsDropdown } from "../components/PlatformsDropdown";
import { WarningModal } from "../components/WarningModal";
import { useGridConfig } from "../hooks/useGridConfig";
import { createConditions } from "../utils/filter-conditions";
import { DataMappingContext } from "./index.js";

function CategoryDataMapping() {
  useSetGridAdvancedFilterTheme();
  const { theme } = useGetGridTheme();
  const { selectedVertical, setSelectedVertical } =
    useContext(DataMappingContext);
  const gridRef = useRef();
  const [rowData, setRowData] = useState(null);
  const [filter, setFilter] = useState(null);
  const [warningModal, setWarningModal] = useState({
    show: false,
    callback: () => {},
  });
  const [modifiedRows, setModifiedRows] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [checkboxConfig, setCheckboxConfig] = useState([]);
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [counter, setCounter] = useState(0);

  const { mutateAsync: updateRowData, isLoading } = useUpdateRowData();

  const getPreferencesAfterDelete = (name) => {
    const currentFilters = {
      ...isSomeOrElse(globalPref?.ipManagement?.dataMapping?.filters, {}),
    };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingDataMappingPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const currentFilters = {
      ...isSomeOrElse(globalPref?.ipManagement?.dataMapping?.filters, {}),
    };
    currentFilters[name] = { ...filter };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingDataMappingPreferences(currentFilters);
  };

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const getExistingDataMappingPreferences = (currentFilters) => ({
    ...globalPref,
    ipManagement: {
      ...globalPref?.ipManagement,
      dataMapping: {
        ...globalPref?.ipManagement?.dataMapping,
        filters: { ...currentFilters },
      },
    },
  });

  const createServer = useCallback(() => {
    return {
      getData: async (request) => {
        const { includes, excludes } = createConditions(filter);
        const include_condition = filter?.conditions ? filter?.type : "and";
        const sortModel = isSomeOrElse(request?.sortModel, []);
        const sorts = sortModel[0]?.colId
          ? [{ [sortModel[0]?.colId]: sortModel[0]?.sort }]
          : [];

        const params = {
          vertical: selectedVertical.value,
          includes,
          include_condition,
          sort: sorts,
          excludes,
          from: request.startRow,
          size: 100,
        };

        const requestedRows = await getDataMappingIps(params);
        const rowData = isSomeOrElse(requestedRows?.data?.data, []);
        setCounter(requestedRows?.data?.total);
        setRowData(rowData);

        const initialData = {};
        rowData.forEach((row) => {
          initialData[row.ip_id] = { ...row };
        });
        setInitialValues(initialData);

        return {
          success: true,
          rows: rowData,
        };
      },
    };
  }, [filter, selectedVertical.value]);

  const createServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        const response = await server.getData(params.request);

        if (response.success) {
          params.success({ rowData: response.rows });
        } else {
          params.fail();
        }
      },
    };
  };

  const fetchData = useCallback(async () => {
    try {
      const server = createServer();
      const datasource = createServerSideDatasource(server);
      gridRef?.current?.api?.setGridOption("serverSideDatasource", datasource);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  }, [createServer, gridRef?.current]);

  const { defaultColDef, columnDefs, initialCheckboxConfig, onGridReady } =
    useGridConfig(checkboxConfig, selectedVertical, fetchData);

  const isRowEdited = useCallback(
    () => Object.keys(modifiedRows).length > 0,
    [modifiedRows],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUnloadListener = useCallback(
    (event) => {
      if (isRowEdited()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [isRowEdited],
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnloadListener);

    return () =>
      window.removeEventListener("beforeunload", handleUnloadListener);
  }, [handleUnloadListener]);

  useEffect(() => {
    const selectedOptions = isSomeOrElse(
      globalPref?.ipManagement?.dataMapping?.preferences?.platforms?.selected,
      [],
    );

    const mappedConfig = initialCheckboxConfig.map((config) => {
      return {
        ...config,
        value: config.field,
        visible: selectedOptions.includes(config.field),
      };
    });

    setCheckboxConfig(mappedConfig);
  }, [globalPref, initialCheckboxConfig]);

  const handleCellValueChanged = (event) => {
    const { data, newValue, colDef } = event;
    const initialValue = initialValues[data.ip_id]?.[colDef.field];

    if (initialValue === newValue) return;

    let result = {};
    const prevRowValue = isSomeOrElse(modifiedRows[data.ip_id], {
      data: {
        update: {},
        remove: [],
      },
    });

    if (newValue === "") {
      const fieldArr =
        colDef.field === "tmsId" ? ["tmsId", "emsId"] : [colDef.field];

      result = {
        ...prevRowValue.data,
        remove: _.union(prevRowValue.data.remove, fieldArr),
      };
    } else {
      let fieldObj = { [colDef.field]: newValue };
      if (colDef.field === "tmsId") {
        fieldObj = { ...fieldObj, emsId: data.emsId };
      }

      if (colDef.field === "cTags") {
        const value = newValue.split(",");
        const cTagsNew = value.map((item) => _.startCase(item));
        fieldObj = { ...fieldObj, cTags: cTagsNew };
      }

      result = {
        ...prevRowValue.data,
        update: { ...prevRowValue.data.update, ...fieldObj },
      };
    }

    setModifiedRows({
      ...modifiedRows,
      [data.ip_id]: { id: isSomeOrElse(data.ip_id, ""), data: result },
    });
  };

  const updateRowSelected = () => {
    const promises = Object.values(modifiedRows).map((modifiedRow) =>
      updateRowData(_.omit(modifiedRow, ["edited"])),
    );

    Promise.allSettled([...promises]).then(fetchData);
  };

  const getDatasource = (data) => {
    return {
      getRows: (params) => {
        params.success({
          rowData: data,
        });
      },
    };
  };

  const clearRowSelected = () => {
    const revertEditedRows = rowData.map((row) => {
      if (row.edited) return { ...initialValues[row.ip_id] };

      return row;
    });

    gridRef?.current?.api.setGridOption(
      "serverSideDatasource",
      getDatasource(revertEditedRows),
    );

    setModifiedRows({});
  };

  const onCategoryChange = ({ detail }) => {
    const callback = () => {
      setSelectedVertical(detail.selectedOption);
      clearRowSelected();
    };

    if (isRowEdited()) {
      setWarningModal({ show: true, callback });
      return;
    }

    callback();
  };

  const handleClearFilter = () =>
    gridRef.current.api.setAdvancedFilterModel(null);

  const handleFilterItemClick = (id, filters) => {
    const filter = filters[id];

    gridRef.current.api.setAdvancedFilterModel(filter);
    setSelectedFilterName(id);
  };

  return (
    <ContentLayout
      header={
        <>
          <Header
            variant="h2"
            counter={`(${counter})`}
            actions={
              isRowEdited() && (
                <SpaceBetween direction="horizontal" size="xs">
                  <Button onClick={updateRowSelected} loading={isLoading}>
                    Update Selected
                  </Button>
                  <Button onClick={clearRowSelected} loading={isLoading}>
                    Clear Selected
                  </Button>
                </SpaceBetween>
              )
            }
            description="ID Management Interface for Unified Content Identifier Editing and Lookup"
          >
            ID Management
          </Header>
          <CustomFlashBar />
        </>
      }
    >
      <SpaceBetween direction="vertical" size="xs">
        <Container
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <FiltersDropdown
                    handleFilterItemClick={handleFilterItemClick}
                    path="ipManagement.dataMapping.filters"
                  />
                  <AdvancedFilters
                    handleClearFilter={handleClearFilter}
                    selectedFilterName={selectedFilterName}
                    filter={filter}
                    getPreferencesAfterDelete={getPreferencesAfterDelete}
                    getPreferencesAfterSaveOrUpdate={
                      getPreferencesAfterSaveOrUpdate
                    }
                  />
                  <Button
                    onClick={() => gridRef.current.api.exportDataAsExcel()}
                  >
                    Export
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="horizontal" size="xs">
                <PlatformsDropdown
                  checkboxConfig={checkboxConfig}
                  setCheckboxConfig={setCheckboxConfig}
                />
                <FormField description="Category">
                  <Select
                    selectedOption={selectedVertical}
                    expandToViewport
                    onChange={onCategoryChange}
                    controlId="select-vertical"
                    placeholder="Category"
                    options={[
                      { label: "All", value: "all" },
                      { label: "Movies", value: "Movies" },
                      { label: "Series", value: "Television" },
                      { label: "Games", value: "Gaming" },
                    ]}
                  />
                </FormField>
              </SpaceBetween>
            </Header>
          }
        >
          <div id="advancedFilterParent"></div>
        </Container>
        <div style={{ height: "76vh" }} className={theme}>
          <AgGridReact
            ref={gridRef}
            rowHeight={30}
            columnDefs={columnDefs}
            onCellValueChanged={handleCellValueChanged}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            suppressRowUpdate={true}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            enableFillHandle={true}
            // enableCellChangeFlash={true}
            enableAdvancedFilter={true}
            rowModelType={"serverSide"}
            tooltipShowDelay={100}
            onFilterChanged={(params) => {
              const f = params?.api?.getAdvancedFilterModel();
              setFilter(f);
            }}
            gridOptions={{
              components: {
                autoSuggestEditor: AutoSuggestEditor,
              },
              getRowStyle: (params) =>
                params?.data?.edited ? { background: "darkolivegreen" } : null,
            }}
            onGridReady={onGridReady}
          />
        </div>
        <WarningModal
          title="Leave Page"
          description="Are you sure that you want to switch the categories ? The changes that you made won't be saved"
          visible={warningModal.show}
          onDismiss={() => setWarningModal(false)}
          onSuccess={() => {
            warningModal.callback();
            setWarningModal(false);
          }}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}

export default CategoryDataMapping;
