import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  ExpandableSection,
  PropertyFilter,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import { useEffect, useState } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../hooks/UseTheme/useGetChartTheme";

function ImdbPlot({ itemData }) {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);
  const [options, setOptions] = useState({});
  const [containerHeight, setContainerHeight] = useState("400px");
  const [containerWidth, setContainerWidth] = useState("400px");

  useEffect(() => {
    const seasonCounts = itemData.reduce(
      (acc, { season_number, episode_number }) => {
        if (!acc[season_number]) {
          acc[season_number] = 1;
        } else {
          acc[season_number] = Math.max(acc[season_number], episode_number);
        }
        return acc;
      },
      {},
    );

    const totalSeasons = Object.keys(seasonCounts).length;
    const maxEpisodesInSeason = Math.max(...Object.values(seasonCounts));

    if (maxEpisodesInSeason > 20) {
      setContainerHeight("800px");
      setContainerWidth("");
    } else if (maxEpisodesInSeason > 10 && maxEpisodesInSeason <= 20) {
      setContainerHeight("600px");
    } else if (totalSeasons < 5 && maxEpisodesInSeason <= 30) {
      setContainerHeight("400px");
      setContainerWidth("400px");
    }

    setOptions({
      theme: theme,
      data: itemData.map((d) => ({
        ...d,
        season: `${d.season_number}`,
        episode: `${d.episode_number}`,
        rating: d.imdb_vote_average,
      })),
      series: [
        {
          type: "heatmap",
          xKey: "season",
          yKey: "episode",
          xName: "Season",
          yName: "Episode",
          colorKey: "rating",
          colorName: "Rating",
          label: {
            color: "black",
            formatter: ({ datum }) =>
              datum.rating.toFixed(1) ? `${datum.rating.toFixed(1)}` : "N/A",
          },
          tooltip: {
            renderer: (params) => {
              const { datum } = params;
              const airDate = new Date(datum.air_date);
              const formattedDate = airDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              });
              return {
                title: datum.name,
                content: `
              <b>Aired</b>: ${formattedDate}<br/>
              <b>Season</b>: ${datum.season_number}<br/>
              <b>Episode</b>: ${datum.episode_number}<br/>
              <b>Rating</b>: ${datum.rating?.toFixed(1)}<br/>
              <span style="color: blue;font-weight: bold">${
                datum.imdb_vote_count
              } votes</span>
              `,
              };
            },
          },
        },
      ],
      axes: [
        {
          type: "category",
          position: "bottom",
          title: { text: "Season" },
        },
        {
          type: "category",
          position: "left",
          title: { text: "Episode" },
        },
      ],
      gradientLegend: {
        gradient: {
          thickness: 6,
          preferredLength: 400,
        },
        spacing: 25,
        position: "right",
      },
    });
  }, [itemData]);

  return (
    <div style={{ height: containerHeight, width: containerWidth }}>
      <AgCharts options={options} style={{ height: "100%" }} />
    </div>
  );
}

const filterProperties = [
  {
    propertyLabel: "Episode",
    key: "episode_number",
    groupValuesLabel: "Episode",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Season",
    key: "season_number",
    groupValuesLabel: "Season",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "IMDb Rating",
    key: "imdb_vote_average",
    groupValuesLabel: "IMDb Rating",
    operators: ["<", "<=", ">", ">="],
  },
  {
    propertyLabel: "IMDb Votes",
    key: "imdb_vote_count",
    groupValuesLabel: "IMDb Votes",
    operators: ["<", "<=", ">", ">="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));

const SortableFilterableTable = ({
  columnDefinitions,
  items,
  filterCountTextFn,
}) => {
  const {
    items: filteredItems,
    collectionProps,
    propertyFilterProps,
    filteredItemsCount,
  } = useCollection(items, {
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    propertyFiltering: { filteringProperties: filterProperties },
  });

  return (
    <SpaceBetween direction="vertical" size="m">
      <ExpandableSection
        variant="container"
        defaultExpanded
        headerText="IMDb Ratings"
      >
        <ImdbPlot itemData={filteredItems} />
      </ExpandableSection>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        items={filteredItems}
        variant="embedded"
        filter={
          <PropertyFilter
            countText={filterCountTextFn(filteredItemsCount)}
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: "your choice",
              dismissAriaLabel: "Dismiss",
              clearAriaLabel: "Clear",

              filteringPlaceholder: "Filter users by text, property or value",
              groupValuesText: "Values",
              groupPropertiesText: "Properties",
              operatorsText: "Operators",

              operationAndText: "and",
              operationOrText: "or",

              operatorLessText: "Less than",
              operatorLessOrEqualText: "Less than or equal",
              operatorGreaterText: "Greater than",
              operatorGreaterOrEqualText: "Greater than or equal",
              operatorContainsText: "Contains",
              operatorDoesNotContainText: "Does not contain",
              operatorEqualsText: "Equals",
              operatorDoesNotEqualText: "Does not equal",

              editTokenHeader: "Edit filter",
              propertyText: "Property",
              operatorText: "Operator",
              valueText: "Value",
              cancelActionText: "Cancel",
              applyActionText: "Apply",
              allPropertiesLabel: "All properties",

              tokenLimitShowMore: "Show more",
              tokenLimitShowFewer: "Show fewer",
              clearFiltersText: "Clear filters",
              removeTokenButtonAriaLabel: (token) =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
              enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            {...propertyFilterProps}
            expandToViewport={true}
          />
        }
      />
    </SpaceBetween>
  );
};

export default SortableFilterableTable;
