import { Modal } from "@cloudscape-design/components";
import DatapointComparison from "../../../../components/datapoint-comparison/DatapointComparison";

const useWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export const CompareModal = ({ visible, setVisible, metrics, defaults }) => {
  const { height: windowHeight } = useWindowDimensions();

  return (
    <Modal
      size="max"
      onDismiss={() => setVisible(false)}
      visible={visible}
      header="Compare Titles"
    >
      {visible && (
        <DatapointComparison
          metrics={metrics}
          defaults={defaults}
          onParametersChanged={() => null}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            chart: {
              height: windowHeight - 325,
            },
            table: {
              enabled: false,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      )}
    </Modal>
  );
};
