import {
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Header,
  Grid,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import shortenInteger from "../../../utils/shortenInteger";
import { ChannelTrends } from "./components/ChannelTrends";
import TopVideos from "./components/TopVideos/TopVideos";
import VideoList from "./components/VideoList/VideoList";
import RecentVideos from "./components/RecentVideos/RecentVideos";

export const ChannelPage = () => {
  const { id: channelId } = useParams();
  const [channelDetails, setChannelDetails] = useState(null);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/youtube/v1/channels/${channelId}`,
        );
        setChannelDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch channel details:", error);
      }
    };

    fetchChannelDetails();
  }, [channelId]);

  return (
    <ContentLayout>
      <SpaceBetween direction="vertical" size="m">
        <Container
          header={
            <Header
              variant="h2"
              description={`Channel Type: ${channelDetails?.type}`}
              actions={
                <Box variant="small">
                  Published On:
                  {new Date(
                    channelDetails?.publishedAt * 1000,
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </Box>
              }
              counter={
                channelDetails?.madeForKids ? (
                  <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
                    Kids
                  </span>
                ) : (
                  ""
                )
              }
            >
              <Link
                fontSize="heading-l"
                href={`https://www.youtube.com/channel/${channelId}`}
                target="_blank"
                external
              >
                {channelDetails?.title}
              </Link>
            </Header>
          }
          media={{
            content: (
              <img
                src={`https://images.searchpantheon.com/youtubechannels/${channelId}.jpeg`}
                alt={channelDetails?.title}
                style={{ width: "180px" }}
              />
            ),
            position: "side",
          }}
        >
          {channelDetails?.description}
        </Container>
        <Container>
          <ColumnLayout columns={4} variant="text-grid">
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Subscribers
              </Box>
              <Box variant="h1" color="text-status-info" textAlign="center">
                {shortenInteger(channelDetails?.subscriberCount)}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Videos
              </Box>
              <Box variant="h1" color="text-status-info" textAlign="center">
                {shortenInteger(channelDetails?.videoCount)}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Views
              </Box>
              <Box variant="h1" color="text-status-info" textAlign="center">
                {shortenInteger(channelDetails?.viewCount)}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Today's View
              </Box>
              <Box variant="h1" color="text-status-info" textAlign="center">
                {shortenInteger(channelDetails?.todaysViews)}
              </Box>
            </div>
          </ColumnLayout>
        </Container>

        <ExpandableSection
          variant="container"
          defaultExpanded
          headerText="Channel Trends"
        >
          <ChannelTrends channelId={channelId} />
        </ExpandableSection>
        <Grid
          gridDefinition={[
            { colspan: { m: 12, l: 6 } },
            { colspan: { m: 12, l: 6 } }
          ]}
        >
          <RecentVideos channelId={channelId} />
          <TopVideos channelId={channelId} />
        </Grid>
        <VideoList channelId={channelId} />
      </SpaceBetween>
    </ContentLayout>
  );
};
