import { Box, Container, Link } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetYoutubeVideosData } from "../../api/hooks/useGetYoutubeChannelsData";
import { columnDefs } from "./gridColumnDefs";
import { defaultColDef } from "./gridDefaultColDef";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";

export const YoutubeVideoWidget = ({
  sort = [],
  include = [],
  exclude = [],
  condition = "and",
  headerLink = "#",
  headerText = " ",
}) => {
  const { theme } = useGetGridTheme();
  const { data: youtubeVideosData } = useGetYoutubeVideosData({
    sort,
    include,
    exclude,
    condition,
  });

  return (
    <Container
      disableContentPaddings
      fitHeight
      header={
        <Box variant="h3" textAlign="center">
          {headerText}
        </Box>
      }
      data-style-id="border-radius-0"
      footer={
        <Box>
          <Link href={headerLink} variant="secondary">
            View All
          </Link>
        </Box>
      }
    >
      <hr className="border-slate-600 border-t-[1px]" />
      <div className={`${theme} compact h-46`}>
        <AgGridReact
          rowData={youtubeVideosData}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          loadingOverlayComponent={() => (
            <CustomLoadingOverlay compact={true} />
          )}
          noRowsOverlayComponent={() => <CustomLoadingOverlay compact={true} />}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </Container>
  );
};
