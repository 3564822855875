import { Container, SpaceBetween } from "@cloudscape-design/components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React from "react";
import { CompanyCards } from "./components/CompanyCards";

interface ItemCompanyProps {
  itemData: any;
}

export default function ItemCompany({ itemData }: ItemCompanyProps) {
  const videoGames = itemData?.data?.["Video Game"] || [];
  const movies = itemData?.data?.["Film"] || [];
  const series = itemData?.data?.["Television Series"] || [];
  const noImage = require("../../../../assets/images/icons/no-image_342.jpg");

  if (itemData.loading || !itemData.data?.type) return null;

  return (
    <Container>
      <div className="space-y-6">
        <div className="flex space-x-6">
          <img
            src={`https://www.themoviedb.org/t/p/h50_filter(negate,000,666)/${itemData.data.poster_path}`}
            alt="..."
            className="w-48 xl:w-64 drop-shadow-lg h-min rounded-md"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = noImage;
            }}
          />

          <div className="flex-1 flex-col">
            <div className="text-3xl font-bold">{itemData.data.name}</div>
            <div className="text-slate-400">{itemData.data.type.name}</div>
            {itemData.data.description && (
              <>
                <div className="text-lg xl:text-xl font-bold mt-6">
                  Overview
                </div>
                {itemData.data.description}
              </>
            )}
          </div>
        </div>
        <div className="flex space-x-2 ">
          <div className="w-full ">
            <SpaceBetween direction="vertical" size="l">
              {videoGames.length > 0 && (
                <CompanyCards items={videoGames} headerText="Video Games" />
              )}
              {movies.length > 0 && (
                <CompanyCards items={movies} headerText="Movies" />
              )}
              {series.length > 0 && (
                <CompanyCards items={series} headerText="Series" />
              )}
            </SpaceBetween>
          </div>
        </div>
      </div>
    </Container>
  );
}
