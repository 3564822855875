import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useGridConfig } from "./hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../../api/hooks/useFetchServerSideGridData";
import { getPantheonRankingDataV2 } from "../../api/request";
import { useSetGridAdvancedFilterTheme } from "../../../../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import TableLegend from "../../../../../../components/table/TableLegend";
import _ from "lodash";
import { VERTICALS } from "../../../../../../config-global";

const verticalMap = {
  [VERTICALS.MOVIES]: "movies",
  [VERTICALS.SERIES]: "series",
  [VERTICALS.GAMES]: "gaming",
};

export const DataTable = ({
  vertical,
  selectedIpIds=[],
  setSelectedIpIds=null,
  setSplitPanelStatus,
  filterQuery,
  weightageValues,
  dateRange,
  advancedFilter,
  setAdvancedFilter,
  additionalColumns,
  selectedAdditionalColumns,
  showLegend=true,
  showAdvancedFilter=true,
  autoHeight=false,
  usePagination=true,
  onRowCountSet=null,
  sortField="score",
  sortOrder="desc",
}) => {

  const { theme } = useGetGridTheme();
  const gridRef = useRef(null);

  const [paginationPageSize, setPaginationPageSize] = useState(50);
  const [disabled, setDisabled] = useState(true);
  const [innerSelectedIpIds, setInnerSelectedIpIds] = useState(selectedIpIds);
  const [rowCount, setRowCount] = useState(0);

  const gridOptions = useGridConfig({
    weightageValues,
    additionalPlatformColumns: additionalColumns,
    selectedOptions: selectedAdditionalColumns.map(o => o.value),
    sortField,
    sortOrder,
  })

  useSetGridAdvancedFilterTheme();
  useFetchServerSideGridData({
    filter: filterQuery,
    gridRef,
    vertical: verticalMap[vertical],
    fetchMethod: getPantheonRankingDataV2,
    startTs: moment.utc(dateRange.startDate).unix(),
    endTs: moment.utc(dateRange.endDate).unix(),
    weightageValues,
  });

  useEffect(() => {
    if (disabled) {
      setTimeout(() => setDisabled(false), 100);
    }
  }, []);

  useEffect(() => {
    gridRef.current?.api?.setAdvancedFilterModel(advancedFilter);
  }, [advancedFilter]);

  useEffect(() => {
    if (!_.isEqual(selectedIpIds, innerSelectedIpIds)) {
      setInnerSelectedIpIds(selectedIpIds);
    }
  }, [selectedIpIds]);

  useEffect(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.applyColumnState({
        state: [{ colId: sortField, sort: sortOrder }],
        defaultState: { sort: null },
      });
    }
  }, [gridRef, sortField, sortOrder]);

  return (
    <div className="flex flex-col space-y-4">
      <div style={{ width: "100%", height: (autoHeight && rowCount <= 20) ? "fit-content" : "70vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          enableAdvancedFilter={showAdvancedFilter}
          domLayout={(autoHeight && rowCount <= 20) ? "autoHeight" : "normal"}
          paginationPageSize={paginationPageSize}
          pagination={usePagination}
          paginationPageSizeSelector={[20, 50, 100]}
          onPaginationChanged={(params) => {
            setRowCount(params.api.getDisplayedRowCount());
            if (onRowCountSet) {
              if (params.api.getServerSideGroupLevelState()?.[0]?.lastRowIndexKnown) {
                onRowCountSet(params.api.getDisplayedRowCount());
              } else {
                onRowCountSet(-1);
              }
            }
            const size = params.api.paginationGetPageSize();
            if (size !== paginationPageSize) setPaginationPageSize(size);
            params.api.forEachNode(node => {
              if (innerSelectedIpIds.includes(node?.data?.ip_id)) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          }}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
          rowSelection="multiple"
          suppressRowClickSelection
          onFilterChanged={(params) => {
            const f = params?.api?.getAdvancedFilterModel();
            if (!_.isEqual(f, advancedFilter)) {
              setAdvancedFilter(f);
            }
          }}
          onRowSelected={(params) => {
            const rowIpId = params.data.ip_id;
            const selectedIpIdsCopy = [...innerSelectedIpIds];
            if (params.node.selected) {
              if (selectedIpIdsCopy.includes(rowIpId)) {
                return;
              }
              selectedIpIdsCopy.push(rowIpId);
              setSplitPanelStatus(true);
            } else {
              const index = selectedIpIdsCopy.indexOf(rowIpId);
              if (index === -1) {
                return;
              }
              selectedIpIdsCopy.splice(index, 1);
            }
            setInnerSelectedIpIds(selectedIpIdsCopy);
            if (setSelectedIpIds) {
              setSelectedIpIds(selectedIpIdsCopy);
            }
          }}
          {...gridOptions}
        />
      </div>
      {showLegend && <TableLegend />}
    </div>
  );
};