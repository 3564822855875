import { Box, Modal, SpaceBetween, Button } from "@cloudscape-design/components";
import React, { forwardRef, useImperativeHandle, useState } from "react";

interface MessageBoxOptions {
  headerText?: string;
  messageBody?: any;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

const defaultOptions: MessageBoxOptions = {
  headerText: "Message",
  messageBody: null,
  primaryButtonText: "Ok",
  secondaryButtonText: "Cancel",
  onPrimaryButtonClick: () => {},
  onSecondaryButtonClick: () => {},
};

export const MessageBox = forwardRef(({}, ref) => {

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<MessageBoxOptions>(defaultOptions);

  useImperativeHandle(ref, () => ({
    open: (options: MessageBoxOptions) => {
      setIsOpen(true);
      if (options) {
        setOptions({ ...defaultOptions, ...options });
      }
    },
    close: () => setIsOpen(false),
  }), []);

  return (
    <Modal
      onDismiss={() => setIsOpen(false)}
      visible={isOpen}
      header={options.headerText}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => {
              options.onSecondaryButtonClick();
              setIsOpen(false);
            }}>{options.secondaryButtonText}</Button>
            <Button variant="primary" onClick={() => {
              options.onPrimaryButtonClick();
              setIsOpen(false);
            }}>{options.primaryButtonText}</Button>
          </SpaceBetween>
        </Box>
      }
    >
      {options.messageBody}
    </Modal>
  );
});