import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const humanReadableDate = date.toLocaleString('en-US', options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;

    return {
      title: title,
      content: `
                <b>Daily Rank:</b> ${datum.dailyRank}<br/>
                <b>Viewers (Peak):</b> ${shortenInteger(datum.peakViewers)} <br />
                <b>Viewers (Avg):</b> ${shortenInteger(datum.averageViewers)}<br/>
                <b>Broadcasters (Peak):</b> ${shortenInteger(datum.peakBroadcasters)}<br/>
                <b>Broadcasters (Avg):</b> ${shortenInteger(datum.averageBroadcasters)}<br/>
                <b>Max Followers:</b> ${shortenInteger(datum.maxFollowers)}<br/>
                <b>New Followers:</b> ${shortenInteger(datum.newFollowers)}<br/>
            `,
    };
  },
};

export const useChartConfig = () => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(
    (axisTypeValue: string) => ({
      padding: {
        right: 0,
        bottom: 0,
        left: 0,
      },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: {
        enabled: true,
        position: "right",
        spacing: 8,
        item: {
          paddingY: 14,
          line: {
            strokeWidth: 12,
          },
        },
      },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "peakViewers",
          yName: "Peak Viewers",
          marker: { enabled: false },
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "averageViewers",
          yName: "Avg Viewers",
          marker: { enabled: false },
          visible: false,
          tooltip,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "peakBroadcasters",
          yName: "Peak Broadcasters",
          marker: { enabled: false },
          tooltip
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "averageBroadcasters",
          yName: "Avg Broadcasters",
          marker: { enabled: false },
          visible: false,
          tooltip
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "maxFollowers",
          yName: "Max Followers",
          marker: { enabled: false },
          tooltip
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "newFollowers",
          yName: "New Followers",
          marker: { enabled: false },
          visible: false,
          tooltip
        },
      ],

      sync: {
        enabled: true,
        axes: "x",
        nodeInteraction: true,
      },
      zoom: {
        enabled: true,
        enableSelecting: true,
      },  
      axes: [
        {
          type: "ordinal-time",
          position: "bottom",
          label: {
            format: "%b %-d, %Y",
            fontSize: 10,
          },
          crosshair: {
            label: {
              format: "%b %-d, %Y",
            },
          },
        },
        {
          // type: axisTypeValue || "number",
          type: "log",
          position: "left",
          nice: true,
          keys: ["averageBroadcasters", "peakBroadcasters", "averageViewers", "peakViewers", "maxFollowers", "newFollowers"],
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
          title: {
            enabled: false,
            text: "Viewers,Broadcasters & Followers",
            fontSize: 10,
            color: "rgb(100 116 139)",
            spacing: 4,
          },
        },
      ],
    }),
    [theme],
  );

  return { getLineChartOptions };
};
