import { CHART_LABELS } from "../hooks/useGridConfig";

export const CATEGORIES = {
  MOVIES: "movies",
  SERIES: "series",
  GAMING: "gaming",
  KIDSMOVIES: "kidsmovies",
  KIDSTV: "kidstv",
};

export const categoryOptions = [
  { label: "Movies", value: CATEGORIES.MOVIES },
  { label: "Series", value: CATEGORIES.SERIES },
  { label: "Gaming", value: CATEGORIES.GAMING },
  { label: "Kids Movies", value: CATEGORIES.KIDSMOVIES },
  { label: "Kids TV", value: CATEGORIES.KIDSTV },
];

const categoryMap = {
  [CHART_LABELS.FRANCHISE]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.PLATFORM]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.GENRE]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.THEME]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.CUSTOM_TAGS]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.DEVELOPER]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.PUBLISHER]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.AGE_RATINGS]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.COUNTRIES]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.PLAYER_PERSPECTIVE]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.GAME_MODE]: [
    CATEGORIES.GAMING
  ],
  [CHART_LABELS.DISTRIBUTORS]: [
    CATEGORIES.MOVIES,
    CATEGORIES.KIDSMOVIES,
  ],
  [CHART_LABELS.PRODUCTION]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.OTT]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.VOTE_TREND]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.PAGEVIEW_TREND]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.VIEWS_TREND]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.LIKES_TREND]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.COMMENTS_TREND]: [
    CATEGORIES.MOVIES,
    CATEGORIES.SERIES,
    CATEGORIES.GAMING,
    CATEGORIES.KIDSMOVIES,
    CATEGORIES.KIDSTV,
  ],
  [CHART_LABELS.VIEWERS_TREND]: [
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.BROADCASTERS_TREND]: [
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.FOLLOWERS_TREND]: [
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.PLAYERS_COUNT_TRENDS]: [
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.POSITIVE_REVIEW_TRENDS]: [
    CATEGORIES.GAMING,
  ],
  [CHART_LABELS.RECOMMENDATIONS_TRENDS]: [
    CATEGORIES.GAMING,
  ],
};

export const getCategories = (label: string) => categoryMap[label];
