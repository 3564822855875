import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useGetTopSearchData } from "../../../components/CommonSearch/api/hooks/useGetTopSearchData";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const convertData = (data) => {
  // Separate the Franchise data from the rest
  const otherData = data.filter((item) => item.vertical !== "Franchise");

  const convertedOtherData = otherData.map((item) => {
    const { vertical, ip_id, ip, image_url, release_date, disabled } = item;
    const imageUrl = getImageUrl(vertical, ip_id, image_url);
    const labelTag = release_date
      ? `${new Date(release_date).getFullYear()}`
      : "";
    return {
      value: ip_id,
      label: ip,
      iconUrl: imageUrl,
      iconAlt: `${ip} cover`,
      tags: [vertical, labelTag],
      disabled: disabled,
    };
  });

  const result = [...convertedOtherData];

  return result;
};

export function AddTitlesToFranchiseModal({ visible, onDiscard, onSubmit, excludeIpIds=[] }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useGetTopSearchData({
    keyword: query,
    fields: ["ip", "ip_id", "vertical", "image_url", "release_date"],
    verticals: ["Movies", "Television", "Gaming"],
  });

  const searchItems = useMemo(() => {
    return data ? data.map(item => ({ ...item, disabled: excludeIpIds.includes(item.ip_id) })) : [];
  }, [data]);

  return (
    <Modal 
      size="medium"
      visible={visible}
      onDismiss={onDiscard}
      header={"Add titles to franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setSelectedItems([]);
                  setQuery("");
                  onDiscard();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-testid="submit"
                onClick={() => {
                  onSubmit(selectedItems?.map((i) => i.value));
                  setLoading(true);
                }}
                disabled={selectedItems.length === 0}
                loading={loading}
              >
                Add
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
    >
      <Multiselect
        onChange={({ detail }) => setSelectedItems(detail.selectedOptions)}
        onLoadItems={({ detail }) => setQuery(detail?.filteringText)}
        selectedOptions={selectedItems}
        options={(searchItems && searchItems?.length > 0) ? convertData(searchItems) : []}
        placeholder="Search for titles"
        filteringType="manual"
        statusType={isLoading ? "loading" : "finished"}
      />
    </Modal>
  );
}
