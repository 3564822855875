import {
  ContentLayout,
  Header,
  Multiselect,
  Select,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import Icon from "@cloudscape-design/components/icon";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FaSliders } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import { TabActions } from "../../../../components/TabActions";
import { SERVICES, TYPES, VERTICALS } from "../../../../config-global";
import { navItems } from "../../../../layouts/common/menu/side-menu";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { CHART_LABELS } from "../../../platforms/hooks/useGridConfig";
import {
  CATEGORIES,
  getCategories,
} from "../../../platforms/utils/categoryUtils";
import { PlatformWeightsDrawerV2 } from "../../components/PlatformWeightsDrawerV2";
import {
  DEFAULT_FILTER_OBJECTS,
  DEFAULT_WEIGHTS_OBJECTS,
} from "../../constants";
import { Layout } from "../../Layout";
import { BasicParameters } from "./components/BasicParameters";
import { CompareContainer } from "./components/CompareContainer";
import { DataTable } from "./components/DataTable/DataTable";
import { GRPantheonRanking } from "./components/GRPantheonRanking";
// import { GlobalTrendsCharts } from "../../../platforms/global/components/GlobalTrendsCharts";
import _ from "lodash";
import { getFilterConditionsFromFilters } from "../../../../utils/filters/filterUtils";
import { FiltersDrawer } from "../../components/FiltersDrawer";
import { TrendingTable } from "./components/DataTable/TrendingTable";
import { weightsObjectToWeightageValues } from "./utils";

const additionalColumns = {
  [VERTICALS.MOVIES]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.MOVIES),
    ),
  ],
  [VERTICALS.SERIES]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.SERIES),
    ),
  ],
  [VERTICALS.GAMES]: [
    CHART_LABELS.SCORE_CHANGE,
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        !label.toLowerCase().includes("trend") &&
        getCategories(label)?.includes(CATEGORIES.GAMING),
    ),
  ],
};

const sortOptions = [
  { label: "Top", value: "top", field: "score", order: "desc" },
  { label: "Rising", value: "rising", field: "score_change", order: "desc" },
  { label: "Falling", value: "falling", field: "score_change", order: "asc" },
  { label: "Wikipedia Z-Score", value: "wikipedia", field: "wiki", order: "desc", verticals: [VERTICALS.MOVIES, VERTICALS.SERIES, VERTICALS.GAMES] },
  { label: "YouTube Z-Score", value: "youtube", field: "youtube", order: "desc", verticals: [VERTICALS.MOVIES, VERTICALS.SERIES, VERTICALS.GAMES] },
  { label: "IMDb Z-Score", value: "imdb", field: "imdb", order: "desc", verticals: [VERTICALS.MOVIES, VERTICALS.SERIES] },
  { label: "Rotten Tomatoes Z-Score", value: "rotten_tomatoes", field: "rt", order: "desc", verticals: [VERTICALS.MOVIES, VERTICALS.SERIES] },
  { label: "Piracy Z-Score", value: "piracy", field: "piracy", order: "desc", verticals: [VERTICALS.MOVIES, VERTICALS.SERIES] },
  { label: "Steam Z-Score", value: "steam", field: "steam", order: "desc", verticals: [VERTICALS.GAMES] },
  { label: "Twitch Z-Score", value: "twitch", field: "twitch", order: "desc", verticals: [VERTICALS.GAMES] },
];

export const GRPantheonRankingIndex = ({
  vertical,
  DrawerComponent,
  title,
}) => {
  const apiParams = {
    type: TYPES.PERSONAL,
    service: SERVICES.RANKING,
    module: "global",
  };
  const breadcrumbs = [
    { text: "Ranking" },
    { text: "Global", href: "/ranking/global" },
    { text: title },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });

  const [selectedIpIds, setSelectedIpIds] = useState([]);
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [activeTabId, setActiveTabId] = useState(
    searchParams.get("tab") ??
      rankingPreferenceData?.[0]?.data?.primaryTab ??
      "tile",
  );
  const [weightsObject, setWeightsObject] = useState(
    DEFAULT_WEIGHTS_OBJECTS[vertical],
  );
  const [filterObject, setFilterObject] = useState(
    DEFAULT_FILTER_OBJECTS[vertical],
  );
  const [dateRange, setDateRange] = useState({
    type: "absolute",
    startDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState(
    [],
  );
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;
    if (pref) {
      if (pref.weights) {
        if (pref.lastLoadedWeights && pref.lastLoadedWeights[vertical]) {
          const lastLoadedWeightsName = pref.lastLoadedWeights[vertical];
          const newWeightsObject =
            pref.weights.find(
              (w) =>
                w.name === lastLoadedWeightsName && w.category === vertical,
            ) ?? weightsObject;
          setWeightsObject(newWeightsObject);
        }
      }
      if (pref.primaryTab && !searchParams.get("tab")) {
        setActiveTabId(pref.primaryTab);
      }
    }
  }, [rankingPreferenceData]);

  const filterQuery = useMemo(() => {
    const mustConditions = [];

    // Quick title search
    if (
      filterObject.titleSearchQuery &&
      filterObject.titleSearchQuery.length > 0
    ) {
      mustConditions.push({
        filterType: "text",
        colId: "ip",
        type: "contains",
        filter: filterObject.titleSearchQuery,
      });
    }

    // Table advanced filters
    if (filterObject.advancedFilter) {
      mustConditions.push(filterObject.advancedFilter);
    }

    // Filters drawer filters
    const filterConditions = getFilterConditionsFromFilters(
      filterObject.filters,
    );
    if (filterConditions.length > 0) {
      filterConditions.forEach((condition) => mustConditions.push(condition));
    }

    if (mustConditions.length === 0) {
      return {};
    } else if (mustConditions.length === 1) {
      return mustConditions[0];
    } else {
      return {
        filterType: "join",
        type: "AND",
        conditions: mustConditions,
      };
    }
  }, [filterObject]);

  const additionalColumnsOptions = useMemo(() => {
    return additionalColumns[vertical].map((column) => ({
      label: column,
      value: column,
    }));
  }, [vertical]);

  const weightageValues = useMemo(() => {
    return weightsObjectToWeightageValues(weightsObject);
  }, [weightsObject]);

  const filterDrawerConfig = {
    id: "filterDrawer",
    content: (
      <FiltersDrawer
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        vertical={vertical}
      />
    ),
    trigger: {
      iconName: "filter",
      iconSvg: <Icon name="filter" />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
    badge: !_.isEqual(filterObject, DEFAULT_FILTER_OBJECTS[vertical]),
  };

  const weightageDrawer = {
    id: "weightageDrawer",
    content: (
      <PlatformWeightsDrawerV2
        weightsObject={weightsObject}
        setWeightsObject={(value) => {
          setWeightsObject(value);
        }}
        vertical={vertical}
      />
    ),
    trigger: {
      iconName: "Weightage",
      iconSvg: <FaSliders style={{ fill: "white" }} />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
    badge: !_.isEqual(weightsObject, DEFAULT_WEIGHTS_OBJECTS[vertical]),
  };

  const getTab = ({ id, label }) => {
    return {
      label: label,
      id: id,
      action: <TabActions tabId={id} preferencesApiParams={apiParams} />,
    };
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      drawers={[filterDrawerConfig, weightageDrawer]}
      navItems={navItems}
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              description="Curated ranking based on defined platform weights."
            >
              {title}
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <Tabs
              disableContentPaddings
              onChange={({ detail }) => {
                setActiveTabId(detail.activeTabId);
                setSearchParams({ tab: detail.activeTabId });
              }}
              activeTabId={activeTabId}
              tabs={[
                getTab({ id: "tile", label: "Tile View" }),
                getTab({ id: "table", label: "Table View" }),
                //getTab({ id: "trending", label: "Trending" }),
              ]}
            />
            <BasicParameters
              dateRange={dateRange}
              setDateRange={setDateRange}
              titleSearchQuery={filterObject.titleSearchQuery}
              setTitleSearchQuery={(value) =>
                setFilterObject({ ...filterObject, titleSearchQuery: value })
              }
              additionalFilters={
                <>
                  <Select
                    options={sortOptions.filter(o => o.verticals != null ? o.verticals.includes(vertical) : true)}
                    selectedOption={selectedSortOption}
                    onChange={({ detail }) =>
                      setSelectedSortOption(detail.selectedOption)
                    }
                  />
                  {activeTabId === "table" ? (
                    <Multiselect
                      options={additionalColumnsOptions}
                      selectedOptions={selectedAdditionalColumns}
                      onChange={({ detail }) =>
                        setSelectedAdditionalColumns([
                          ...detail.selectedOptions,
                        ])
                      }
                      placeholder="Additional columns"
                      hideTokens
                    />
                  ) : null}
                </>
              }
              loading={false}
            />
            {activeTabId === "tile" ? (
              <GRPantheonRanking
                vertical={vertical}
                selectedIpIds={selectedIpIds}
                setSelectedIpIds={setSelectedIpIds}
                setSplitPanelStatus={setSplitPanelStatus}
                filterQuery={filterQuery}
                weightageValues={weightageValues}
                dateRange={dateRange}
                sortField={selectedSortOption.field}
                sortOrder={selectedSortOption.order}
              />
            ) : activeTabId === "table" ? (
              <DataTable
                vertical={vertical}
                selectedIpIds={selectedIpIds}
                setSelectedIpIds={setSelectedIpIds}
                setSplitPanelStatus={setSplitPanelStatus}
                filterQuery={filterQuery}
                weightageValues={weightageValues}
                dateRange={dateRange}
                advancedFilter={filterObject.advancedFilter}
                setAdvancedFilter={(value) =>
                  setFilterObject({ ...filterObject, advancedFilter: value })
                }
                additionalColumns={additionalColumns[vertical]}
                selectedAdditionalColumns={selectedAdditionalColumns}
                sortField={selectedSortOption.field}
                sortOrder={selectedSortOption.order}
              />
            ) : activeTabId === "trending" ? (
              <TrendingTable
                vertical={vertical}
                selectedIpIds={selectedIpIds}
                setSelectedIpIds={setSelectedIpIds}
                setSplitPanelStatus={setSplitPanelStatus}
                filterQuery={filterQuery}
                weightageValues={weightageValues}
                dateRange={dateRange}
                advancedFilter={filterObject.advancedFilter}
                setAdvancedFilter={(value) =>
                  setFilterObject({ ...filterObject, advancedFilter: value })
                }
              />
            ) : null}
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="table"
      splitPanel={
        <CompareContainer
          vertical={vertical}
          ipIds={selectedIpIds}
          dateRange={dateRange}
          weightsObject={weightsObject}
        />
      }
      splitPanelStatus={splitPanelStatus}
      setSplitPanelStatus={setSplitPanelStatus}
    />
  );
};
