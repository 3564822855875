import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import { useItemChartConfig } from "../../../chartConfig";

const groupDataByKey = (data, key) => {
  return data?.reduce((acc, item) => {
    const groupKey = item[key];
    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(item);
    return acc;
  }, {});
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return new Intl.DateTimeFormat("en-US", {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric",
  }).format(date);
};

export const useChartConfig = (data) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);
  const { trackedWikiTrendsChartOption } = useItemChartConfig();
  const groupedData = groupDataByKey(data, "country");

  const newSeries = Object.keys(groupedData ?? {}).map((country) => {
    return {
      type: "line",
      xKey: "timestamp",
      yKey: "downloads",
      yName: country,
      visible:
        country === "United States" ||
        country === "Canada" ||
        country === "United Kingdom",
      data: groupedData[country],
      marker: { enabled: false },
      tooltip: {
        renderer: (params) => {
          return {
            title: params.datum.country.toLocaleString(),
            content: `
                <b>Rank:</b> ${params.datum.rank}<br/>
                <b>Downloads:</b> ${params.datum.downloads.toLocaleString()}<br/>
                <b>Date:</b> ${formatDate(params.datum.timestamp / 1000)}
                `,
          };
        },
      },
    };
  });

  return {
    ...trackedWikiTrendsChartOption,
    series: newSeries,
    theme: theme,
    legend: {
      position: "bottom",
      spacing: 8,
      item: {
        paddingY: 14,
        line: {
          strokeWidth: 12,
        },
      },
    },
    axes: [
      {
        type: "time",
        position: "bottom",
        label: {
          format: "%b %-d, %Y",
          fontSize: 10,
        },
      },
      {
        type: "log",
        position: "left",
        label: {
          formatter: (params) =>
            Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(params.value),
        },
        min: 0,
        keys: ["country", "downloads"],
      },
    ],
  };
};
