import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Modal, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { SERVICES, TYPES, VERTICALS } from "../../../../config-global";
import { useGetGenericPreference_V2 } from "../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../../../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { invalidatePreferenceData } from "../../../../services/generic_v2/utils/serviceUtils";
import _ from "lodash";

const verticalTabMap = {
  [VERTICALS.MOVIES]: { label: "Movie Weights", id: VERTICALS.MOVIES },
  [VERTICALS.SERIES]: { label: "Series Weights", id: VERTICALS.SERIES },
  [VERTICALS.GAMES]: { label: "Video Game Weights", id: VERTICALS.GAMES },
};

export const WeightsModal = ({
  visible,
  setVisible,
  weightsObjects,
  setWeightsObjects,
}) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.RANKING, module: "global" };

  const [currentWeightsObjects, setCurrentWeightsObjects] = useState(weightsObjects);
  const [loadedWeights, setLoadedWeights] = useState([]);
  const [selectedTabId, setSelectedTabId] = useState(VERTICALS.MOVIES);

  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });

  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;

    if (pref) {
      if (pref.weights) {
        setLoadedWeights(pref.weights);
      }
    }
  }, [rankingPreferenceData]);

  const savePreferences = (weightObjects) => {
    const prefValue = rankingPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      data.weights = weightObjects;

      updatePreference({ id: prefValue.id, payload: data });
      return;
    }

    const data = { weights: weightObjects };
    postGenericPreference(data);
  };

  const currentWeightsAreSaved = useMemo(() => {
    return loadedWeights?.map(w => _.isEqual(w, currentWeightsObjects[selectedTabId])).includes(true);
  }, [loadedWeights, currentWeightsObjects, selectedTabId]);

  return (
    <Modal
      header="Manage Z-Score Weights"
      visible={visible}
      onDismiss={() => setVisible(false)}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
            <Button variant="primary">Ok</Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Tabs 
        activeTabId={selectedTabId}
        onChange={({ detail }) => setSelectedTabId(detail.activeTabId)}
        tabs={Object.values(verticalTabMap)}
      />
    </Modal>
  );
};